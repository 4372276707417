<template>
    <div class="card invoices-tabs-card">
      <div class="invoice-template-tab invoices-main-tabs">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <div class="invoices-tabs">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    id="draft-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#draft_tab"
                    type="button"
                    role="tab"
                    aria-controls="draft_tab"
                    aria-selected="true"
                    href="javascript:void(0);"
                    class="active"
                    >Draft</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    id="assigned-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#assigned_tab"
                    type="button"
                    role="tab"
                    aria-controls="assigned_tab"
                    aria-selected="true"
                    href="javascript:void(0);"
                    >Assigned
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    id="processed-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#processed_tab"
                    type="button"
                    role="tab"
                    aria-controls="processed_tab"
                    aria-selected="true"
                    href="javascript:void(0);"
                    >Processed
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    id="completed-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#completed_tab"
                    type="button"
                    role="tab"
                    aria-controls="completed_tab"
                    aria-selected="true"
                    href="javascript:void(0);"
                    >Completed
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>