<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">

      <div class="row">
        <!-- Chart -->
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Feather Icon</h5>
            </div>
            <div class="card-body">
              <div class="icons-items">
                <ul class="icons-list">
                  <li>
                    <vue-feather type="activity" data-bs-toggle="tooltip" title="feather feather-activity"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="airplay" data-bs-toggle="tooltip" title="feather feather-airplay"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="alert-circle" data-bs-toggle="tooltip"
                      title="feather feather-alert-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="alert-octagon" data-bs-toggle="tooltip"
                      title="feather feather-alert-octagon"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="alert-triangle" data-bs-toggle="tooltip"
                      title="feather feather-alert-triangle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="align-center" data-bs-toggle="tooltip"
                      title="feather feather-align-center"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="align-justify" data-bs-toggle="tooltip"
                      title="feather feather-align-justify"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="align-left" data-bs-toggle="tooltip"
                      title="feather feather-align-left"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="align-right" data-bs-toggle="tooltip"
                      title="feather feather-align-right"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="anchor" data-bs-toggle="tooltip" title="feather feather-anchor"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="aperture" data-bs-toggle="tooltip" title="feather feather-aperture"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="arrow-down" data-bs-toggle="tooltip"
                      title="feather feather-arrow-down"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="arrow-down-circle" data-bs-toggle="tooltip"
                      title="feather feather-arrow-down-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="arrow-down-left" data-bs-toggle="tooltip"
                      title="feather feather-arrow-down-left"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="arrow-down-right" data-bs-toggle="tooltip"
                      title="feather feather-arrow-down-right"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="arrow-left" data-bs-toggle="tooltip"
                      title="feather feather-arrow-left"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="arrow-left-circle" data-bs-toggle="tooltip"
                      title="feather feather-arrow-left-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="arrow-right" data-bs-toggle="tooltip"
                      title="feather feather-arrow-right"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="arrow-right-circle" data-bs-toggle="tooltip"
                      title="feather feather-arrow-right-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="arrow-up" data-bs-toggle="tooltip" title="feather feather-arrow-up"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="arrow-up-circle" data-bs-toggle="tooltip"
                      title="feather feather-arrow-up-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="arrow-up-left" data-bs-toggle="tooltip"
                      title="feather feather-arrow-up-left"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="arrow-up-right" data-bs-toggle="tooltip"
                      title="feather feather-arrow-up-right"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="at-sign" data-bs-toggle="tooltip" title="feather feather-at-sign"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="award" data-bs-toggle="tooltip" title="feather feather-award"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="bar-chart" data-bs-toggle="tooltip"
                      title="feather feather-bar-chart"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="bar-chart-2" data-bs-toggle="tooltip"
                      title="feather feather-bar-chart-2"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="battery" data-bs-toggle="tooltip" title="feather feather-battery"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="battery-charging" data-bs-toggle="tooltip"
                      title="feather feather-battery-charging"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="bell" data-bs-toggle="tooltip" title="feather feather-bell"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="bell-off" data-bs-toggle="tooltip" title="feather feather-bell-off"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="bluetooth" data-bs-toggle="tooltip"
                      title="feather feather-bluetooth"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="bold" data-bs-toggle="tooltip" title="feather feather-bold"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="book" data-bs-toggle="tooltip" title="feather feather-book"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="book-open" data-bs-toggle="tooltip"
                      title="feather feather-book-open"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="bookmark" data-bs-toggle="tooltip" title="feather feather-bookmark"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="box" data-bs-toggle="tooltip" title="feather feather-box"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="briefcase" data-bs-toggle="tooltip"
                      title="feather feather-briefcase"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="calendar" data-bs-toggle="tooltip" title="feather feather-calendar"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="camera" data-bs-toggle="tooltip" title="feather feather-camera"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="camera-off" data-bs-toggle="tooltip"
                      title="feather feather-camera-off"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="cast" data-bs-toggle="tooltip" title="feather feather-cast"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="check" data-bs-toggle="tooltip" title="feather feather-check"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="check-circle" data-bs-toggle="tooltip"
                      title="feather feather-check-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="check-square" data-bs-toggle="tooltip"
                      title="feather feather-check-square"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="chevron-down" data-bs-toggle="tooltip"
                      title="feather feather-chevron-down"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="chevron-left" data-bs-toggle="tooltip"
                      title="feather feather-chevron-left"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="chevron-right" data-bs-toggle="tooltip"
                      title="feather feather-chevron-right"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="chevron-up" data-bs-toggle="tooltip"
                      title="feather feather-chevron-up"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="chevrons-down" data-bs-toggle="tooltip"
                      title="feather feather-chevrons-down"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="chevrons-left" data-bs-toggle="tooltip"
                      title="feather feather-chevrons-left"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="chevrons-right" data-bs-toggle="tooltip"
                      title="feather feather-chevrons-right"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="chevrons-up" data-bs-toggle="tooltip"
                      title="feather feather-chevrons-up"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="chrome" data-bs-toggle="tooltip" title="feather feather-chrome"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="circle" data-bs-toggle="tooltip" title="feather feather-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="clipboard" data-bs-toggle="tooltip"
                      title="feather feather-clipboard"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="clock" data-bs-toggle="tooltip" title="feather feather-clock"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="cloud" data-bs-toggle="tooltip" title="feather feather-cloud"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="cloud-drizzle" data-bs-toggle="tooltip"
                      title="feather feather-cloud-drizzle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="cloud-lightning" data-bs-toggle="tooltip"
                      title="feather feather-cloud-lightning"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="cloud-off" data-bs-toggle="tooltip"
                      title="feather feather-cloud-off"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="cloud-rain" data-bs-toggle="tooltip"
                      title="feather feather-cloud-rain"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="cloud-snow" data-bs-toggle="tooltip"
                      title="feather feather-cloud-snow"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="code" data-bs-toggle="tooltip" title="feather feather-code"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="codepen" data-bs-toggle="tooltip" title="feather feather-codepen"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="command" data-bs-toggle="tooltip" title="feather feather-command"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="compass" data-bs-toggle="tooltip" title="feather feather-compass"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="copy" data-bs-toggle="tooltip" title="feather feather-copy"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="corner-down-left" data-bs-toggle="tooltip"
                      title="feather feather-corner-down-left"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="corner-down-right" data-bs-toggle="tooltip"
                      title="feather feather-corner-down-right"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="corner-left-down" data-bs-toggle="tooltip"
                      title="feather feather-corner-left-down"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="corner-left-up" data-bs-toggle="tooltip"
                      title="feather feather-corner-left-up"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="corner-right-down" data-bs-toggle="tooltip"
                      title="feather feather-corner-right-down"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="corner-right-up" data-bs-toggle="tooltip"
                      title="feather feather-corner-right-up"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="corner-up-left" data-bs-toggle="tooltip"
                      title="feather feather-corner-up-left"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="corner-up-right" data-bs-toggle="tooltip"
                      title="feather feather-corner-up-right"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="cpu" data-bs-toggle="tooltip" title="feather feather-cpu"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="credit-card" data-bs-toggle="tooltip"
                      title="feather feather-credit-card"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="crop" data-bs-toggle="tooltip" title="feather feather-crop"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="crosshair" data-bs-toggle="tooltip"
                      title="feather feather-crosshair"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="database" data-bs-toggle="tooltip" title="feather feather-database"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="delete" data-bs-toggle="tooltip" title="feather feather-delete"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="disc" data-bs-toggle="tooltip" title="feather feather-disc"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="dollar-sign" data-bs-toggle="tooltip"
                      title="feather feather-dollar-sign"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="download" data-bs-toggle="tooltip" title="feather feather-download"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="download-cloud" data-bs-toggle="tooltip"
                      title="feather feather-download-cloud"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="droplet" data-bs-toggle="tooltip" title="feather feather-droplet"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="edit" data-bs-toggle="tooltip" title="feather feather-edit"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="edit-2" data-bs-toggle="tooltip" title="feather feather-edit-2"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="edit-3" data-bs-toggle="tooltip" title="feather feather-edit-3"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="external-link" data-bs-toggle="tooltip"
                      title="feather feather-external-link"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="eye" data-bs-toggle="tooltip" title="feather feather-eye"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="eye-off" data-bs-toggle="tooltip" title="feather feather-eye-off"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="facebook" data-bs-toggle="tooltip" title="feather feather-facebook"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="fast-forward" data-bs-toggle="tooltip"
                      title="feather feather-fast-forward"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="feather" data-bs-toggle="tooltip" title="feather feather-feather"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="file" data-bs-toggle="tooltip" title="feather feather-file"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="file-minus" data-bs-toggle="tooltip"
                      title="feather feather-file-minus"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="file-plus" data-bs-toggle="tooltip"
                      title="feather feather-file-plus"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="file-text" data-bs-toggle="tooltip"
                      title="feather feather-file-text"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="film" data-bs-toggle="tooltip" title="feather feather-film"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="filter" data-bs-toggle="tooltip" title="feather feather-filter"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="flag" data-bs-toggle="tooltip" title="feather feather-flag"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="folder" data-bs-toggle="tooltip" title="feather feather-folder"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="folder-minus" data-bs-toggle="tooltip"
                      title="feather feather-folder-minus"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="folder-plus" data-bs-toggle="tooltip"
                      title="feather feather-folder-plus"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="git-branch" data-bs-toggle="tooltip"
                      title="feather feather-git-branch"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="git-commit" data-bs-toggle="tooltip"
                      title="feather feather-git-commit"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="git-merge" data-bs-toggle="tooltip"
                      title="feather feather-git-merge"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="git-pull-request" data-bs-toggle="tooltip"
                      title="feather feather-git-pull-request"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="github" data-bs-toggle="tooltip" title="feather feather-github"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="gitlab" data-bs-toggle="tooltip" title="feather feather-gitlab"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="globe" data-bs-toggle="tooltip" title="feather feather-globe"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="grid" data-bs-toggle="tooltip" title="feather feather-grid"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="hard-drive" data-bs-toggle="tooltip"
                      title="feather feather-hard-drive"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="hash" data-bs-toggle="tooltip" title="feather feather-hash"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="headphones" data-bs-toggle="tooltip"
                      title="feather feather-headphones"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="heart" data-bs-toggle="tooltip" title="feather feather-heart"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="help-circle" data-bs-toggle="tooltip"
                      title="feather feather-help-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="home" data-bs-toggle="tooltip" title="feather feather-home"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="image" data-bs-toggle="tooltip" title="feather feather-image"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="inbox" data-bs-toggle="tooltip" title="feather feather-inbox"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="info" data-bs-toggle="tooltip" title="feather feather-info"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="instagram" data-bs-toggle="tooltip"
                      title="feather feather-instagram"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="italic" data-bs-toggle="tooltip" title="feather feather-italic"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="layers" data-bs-toggle="tooltip" title="feather feather-layers"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="layout" data-bs-toggle="tooltip" title="feather feather-layout"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="life-buoy" data-bs-toggle="tooltip"
                      title="feather feather-life-buoy"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="link" data-bs-toggle="tooltip" title="feather feather-link"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="link-2" data-bs-toggle="tooltip" title="feather feather-link-2"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="linkedin" data-bs-toggle="tooltip" title="feather feather-linkedin"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="list" data-bs-toggle="tooltip" title="feather feather-list"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="loader" data-bs-toggle="tooltip" title="feather feather-loader"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="lock" data-bs-toggle="tooltip" title="feather feather-lock"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="log-in" data-bs-toggle="tooltip" title="feather feather-log-in"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="log-out" data-bs-toggle="tooltip" title="feather feather-log-out"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="mail" data-bs-toggle="tooltip" title="feather feather-mail"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="map" data-bs-toggle="tooltip" title="feather feather-map"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="map-pin" data-bs-toggle="tooltip" title="feather feather-map-pin"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="maximize" data-bs-toggle="tooltip" title="feather feather-maximize"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="maximize-2" data-bs-toggle="tooltip"
                      title="feather feather-maximize-2"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="menu" data-bs-toggle="tooltip" title="feather feather-menu"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="message-circle" data-bs-toggle="tooltip"
                      title="feather feather-message-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="message-square" data-bs-toggle="tooltip"
                      title="feather feather-message-square"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="mic" data-bs-toggle="tooltip" title="feather feather-mic"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="mic-off" data-bs-toggle="tooltip" title="feather feather-mic-off"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="minimize" data-bs-toggle="tooltip" title="feather feather-minimize"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="minimize-2" data-bs-toggle="tooltip"
                      title="feather feather-minimize-2"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="minus" data-bs-toggle="tooltip" title="feather feather-minus"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="minus-circle" data-bs-toggle="tooltip"
                      title="feather feather-minus-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="minus-square" data-bs-toggle="tooltip"
                      title="feather feather-minus-square"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="monitor" data-bs-toggle="tooltip" title="feather feather-monitor"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="moon" data-bs-toggle="tooltip" title="feather feather-moon"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="more-horizontal" data-bs-toggle="tooltip"
                      title="feather feather-more-horizontal"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="more-vertical" data-bs-toggle="tooltip"
                      title="feather feather-more-vertical"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="move" data-bs-toggle="tooltip" title="feather feather-move"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="music" data-bs-toggle="tooltip" title="feather feather-music"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="navigation" data-bs-toggle="tooltip"
                      title="feather feather-navigation"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="navigation-2" data-bs-toggle="tooltip"
                      title="feather feather-navigation-2"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="octagon" data-bs-toggle="tooltip" title="feather feather-octagon"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="package" data-bs-toggle="tooltip" title="feather feather-package"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="paperclip" data-bs-toggle="tooltip"
                      title="feather feather-paperclip"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="pause" data-bs-toggle="tooltip" title="feather feather-pause"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="pause-circle" data-bs-toggle="tooltip"
                      title="feather feather-pause-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="percent" data-bs-toggle="tooltip" title="feather feather-percent"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="phone" data-bs-toggle="tooltip" title="feather feather-phone"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="phone-call" data-bs-toggle="tooltip"
                      title="feather feather-phone-call"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="phone-forwarded" data-bs-toggle="tooltip"
                      title="feather feather-phone-forwarded"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="phone-incoming" data-bs-toggle="tooltip"
                      title="feather feather-phone-incoming"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="phone-missed" data-bs-toggle="tooltip"
                      title="feather feather-phone-missed"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="phone-off" data-bs-toggle="tooltip"
                      title="feather feather-phone-off"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="phone-outgoing" data-bs-toggle="tooltip"
                      title="feather feather-phone-outgoing"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="pie-chart" data-bs-toggle="tooltip"
                      title="feather feather-pie-chart"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="play" data-bs-toggle="tooltip" title="feather feather-play"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="play-circle" data-bs-toggle="tooltip"
                      title="feather feather-play-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="plus" data-bs-toggle="tooltip" title="feather feather-plus"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="plus-circle" data-bs-toggle="tooltip"
                      title="feather feather-plus-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="plus-square" data-bs-toggle="tooltip"
                      title="feather feather-plus-square"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="pocket" data-bs-toggle="tooltip" title="feather feather-pocket"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="power" data-bs-toggle="tooltip" title="feather feather-power"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="printer" data-bs-toggle="tooltip" title="feather feather-printer"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="radio" data-bs-toggle="tooltip" title="feather feather-radio"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="refresh-ccw" data-bs-toggle="tooltip"
                      title="feather feather-refresh-ccw"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="refresh-cw" data-bs-toggle="tooltip"
                      title="feather feather-refresh-cw"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="repeat" data-bs-toggle="tooltip" title="feather feather-repeat"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="rewind" data-bs-toggle="tooltip" title="feather feather-rewind"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="rotate-ccw" data-bs-toggle="tooltip"
                      title="feather feather-rotate-ccw"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="rotate-cw" data-bs-toggle="tooltip"
                      title="feather feather-rotate-cw"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="rss" data-bs-toggle="tooltip" title="feather feather-rss"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="save" data-bs-toggle="tooltip" title="feather feather-save"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="scissors" data-bs-toggle="tooltip" title="feather feather-scissors"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="search" data-bs-toggle="tooltip" title="feather feather-search"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="send" data-bs-toggle="tooltip" title="feather feather-send"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="server" data-bs-toggle="tooltip" title="feather feather-server"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="settings" data-bs-toggle="tooltip" title="feather feather-settings"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="share" data-bs-toggle="tooltip" title="feather feather-share"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="share-2" data-bs-toggle="tooltip" title="feather feather-share-2"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="shield" data-bs-toggle="tooltip" title="feather feather-shield"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="shield-off" data-bs-toggle="tooltip"
                      title="feather feather-shield-off"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="shopping-bag" data-bs-toggle="tooltip"
                      title="feather feather-shopping-bag"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="shopping-cart" data-bs-toggle="tooltip"
                      title="feather feather-shopping-cart"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="shuffle" data-bs-toggle="tooltip" title="feather feather-shuffle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="sidebar" data-bs-toggle="tooltip" title="feather feather-sidebar"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="skip-back" data-bs-toggle="tooltip"
                      title="feather feather-skip-back"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="skip-forward" data-bs-toggle="tooltip"
                      title="feather feather-skip-forward"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="slack" data-bs-toggle="tooltip" title="feather feather-slack"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="slash" data-bs-toggle="tooltip" title="feather feather-slash"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="sliders" data-bs-toggle="tooltip" title="feather feather-sliders"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="smartphone" data-bs-toggle="tooltip"
                      title="feather feather-smartphone"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="speaker" data-bs-toggle="tooltip" title="feather feather-speaker"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="square" data-bs-toggle="tooltip" title="feather feather-square"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="star" data-bs-toggle="tooltip" title="feather feather-star"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="stop-circle" data-bs-toggle="tooltip"
                      title="feather feather-stop-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="sun" data-bs-toggle="tooltip" title="feather feather-sun"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="sunrise" data-bs-toggle="tooltip" title="feather feather-sunrise"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="sunset" data-bs-toggle="tooltip" title="feather feather-sunset"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="tablet" data-bs-toggle="tooltip" title="feather feather-tablet"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="tag" data-bs-toggle="tooltip" title="feather feather-tag"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="target" data-bs-toggle="tooltip" title="feather feather-target"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="terminal" data-bs-toggle="tooltip" title="feather feather-terminal"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="thermometer" data-bs-toggle="tooltip"
                      title="feather feather-thermometer"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="thumbs-down" data-bs-toggle="tooltip"
                      title="feather feather-thumbs-down"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="thumbs-up" data-bs-toggle="tooltip"
                      title="feather feather-thumbs-up"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="toggle-left" data-bs-toggle="tooltip"
                      title="feather feather-toggle-left"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="toggle-right" data-bs-toggle="tooltip"
                      title="feather feather-toggle-right"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="trash" data-bs-toggle="tooltip" title="feather feather-trash"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="trash-2" data-bs-toggle="tooltip" title="feather feather-trash-2"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="trending-down" data-bs-toggle="tooltip"
                      title="feather feather-trending-down"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="trending-up" data-bs-toggle="tooltip"
                      title="feather feather-trending-up"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="triangle" data-bs-toggle="tooltip" title="feather feather-triangle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="truck" data-bs-toggle="tooltip" title="feather feather-truck"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="tv" data-bs-toggle="tooltip" title="feather feather-tv"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="twitter" data-bs-toggle="tooltip" title="feather feather-twitter"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="type" data-bs-toggle="tooltip" title="feather feather-type"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="umbrella" data-bs-toggle="tooltip" title="feather feather-umbrella"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="underline" data-bs-toggle="tooltip"
                      title="feather feather-underline"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="unlock" data-bs-toggle="tooltip" title="feather feather-unlock"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="upload" data-bs-toggle="tooltip" title="feather feather-upload"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="upload-cloud" data-bs-toggle="tooltip"
                      title="feather feather-upload-cloud"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="user" data-bs-toggle="tooltip" title="feather feather-user"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="user-check" data-bs-toggle="tooltip"
                      title="feather feather-user-check"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="user-minus" data-bs-toggle="tooltip"
                      title="feather feather-user-minus"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="user-plus" data-bs-toggle="tooltip"
                      title="feather feather-user-plus"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="user-x" data-bs-toggle="tooltip" title="feather feather-user-x"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="users" data-bs-toggle="tooltip" title="feather feather-users"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="video" data-bs-toggle="tooltip" title="feather feather-video"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="video-off" data-bs-toggle="tooltip"
                      title="feather feather-video-off"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="voicemail" data-bs-toggle="tooltip"
                      title="feather feather-voicemail"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="volume" data-bs-toggle="tooltip" title="feather feather-volume"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="volume-1" data-bs-toggle="tooltip" title="feather feather-volume-1"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="volume-2" data-bs-toggle="tooltip" title="feather feather-volume-2"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="volume-x" data-bs-toggle="tooltip" title="feather feather-volume-x"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="watch" data-bs-toggle="tooltip" title="feather feather-watch"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="wifi" data-bs-toggle="tooltip" title="feather feather-wifi"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="wifi-off" data-bs-toggle="tooltip" title="feather feather-wifi-off"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="wind" data-bs-toggle="tooltip" title="feather feather-wind"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="x" data-bs-toggle="tooltip" title="feather feather-x"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="x-circle" data-bs-toggle="tooltip" title="feather feather-x-circle"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="x-square" data-bs-toggle="tooltip" title="feather feather-x-square"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="zap" data-bs-toggle="tooltip" title="feather feather-zap"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="zap-off" data-bs-toggle="tooltip" title="feather feather-zap-off"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="zoom-in" data-bs-toggle="tooltip" title="feather feather-zoom-in"></vue-feather>
                  </li>
                  <li>
                    <vue-feather type="zoom-out" data-bs-toggle="tooltip" title="feather feather-zoom-out"></vue-feather>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Chart -->
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
<script>
export default {
  data() {
    return {
      title: "Feather Icon",
    };
  },
};
</script>
