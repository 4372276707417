<script setup>
import { ref, onMounted } from "vue";
import { defineProps, defineEmits } from "vue";
import FormWrapper from "../form-validation.vue";
import memberForm from "../memberForm.vue";
import { formatToISODate } from "@/utils/";
import { Notyf } from "notyf";
import { t } from "@/utils/i18n";

const notyf = new Notyf();

// Define props and emits
const props = defineProps({
  saleData: Object,
});

const emit = defineEmits([
  "formValidated",
  "updateSaleData",
  "updateMembers",
  "updateAMember",
]);

const handleFormValidated = (status) => {
  emit("formValidated", status);
  if (status) isDisabled.value = false;
};

const addMember = () => {
  if (isDisabled.value) {
    notyf.error("Please fill in all fields");
  } else {
    // Add the new member to the members array
    membersList.value.push({ ...newMember.value });
    memberIndex.value++;
    updateMemberList(membersList.value);
    notyf.success("Added member");
    resetForm();
    isDisabled.value = false;
  }
};

const memberIndex = ref(2);
const numberMember = ref(props.saleData?.personalInfo?.familySize || 0);
const membersList = ref([]);
const isDisabled = ref(true);

const newMember = ref({
  firstName: "",
  lastName: "",
  birthDate: "",
  gender: null,
  socialSecurityNumber: null,
  relationship: null,
  inmigrationStatus: null,
});

const resetForm = () => {
  newMember.value = {
    firstName: "",
    lastName: "",
    birthDate: "",
    gender: null,
    socialSecurityNumber: null,
    relationship: null,
    inmigrationStatus: null,
  };
};

const updateMemberList = (members) => {
  emit("updateMembers", members);
};

const updateMember = (index, updatedFields) => {
  emit("updateAMember", { index: index, updatedFields: updatedFields });
};

const memberPrimary = {
  firstName: props.saleData?.personalInfo?.firstName || "",
  lastName: props.saleData?.personalInfo?.lastName || "",
  birthDate: formatToISODate(props.saleData?.personalInfo?.birthDate) || "",
  gender: props.saleData?.personalInfo?.gender || null,
  socialSecurityNumber:
    Number(props.saleData?.personalInfo?.socialSecurityNumber) || null,
  inmigrationStatus: props.saleData?.personalInfo?.inmigrationStatus || null,
};

const rules = {
  gender: [
    {
      required: true,
      message: "Please select your gender.",
      trigger: "change",
    },
  ],
};

const addMemberPrimary = () => {
  membersList.value.push(memberPrimary);
  //In store: updates the member array with the primary member
  updateMemberList(membersList.value);
  notyf.success("Primary member added to members.");
};

onMounted(() => {
  const sizeMemberList = props.saleData?.members?.length;

  if (sizeMemberList) {
    updateMember(0, memberPrimary);
    membersList.value = props.saleData.members;
    memberIndex.value = sizeMemberList + 1;
  } else {
    addMemberPrimary();
  }
});
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body container-form form-no-required">
            <div class="mb-5">
              <memberForm
                class="mb-5"
                v-for="(member, index) in membersList"
                :key="index"
                :member="member"
                :index="index + 1"
              />
            </div>
            <div class="card shadow-none" v-if="memberIndex <= numberMember">
              <div class="card-body">
                <div class="d-flex justify-content-between mb-5">
                  <h5 class="card-title m-0">
                    {{ t("sales.details.members.members") }} {{ memberIndex }}/
                    {{ numberMember }}
                  </h5>
                  <button class="btn bg-cancel" @click="addMember">
                    Save member
                  </button>
                </div>
                <FormWrapper @form-validated="handleFormValidated">
                  <!-- Form Fields -->
                  <div class="row">
                    <!-- Name Field -->
                    <div class="col-md-6 mb-3">
                      <label for="firstName" class="form-label">
                        {{ t("sales.details.members.firstName") }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="firstName"
                        v-model="newMember.firstName"
                        placeholder="Enter first name"
                        required
                      />
                      <div class="invalid-feedback">
                        Please enter the first name.
                      </div>
                    </div>

                    <!-- Last Name Field -->
                    <div class="col-md-6 mb-3">
                      <label for="lastName" class="form-label"
                        >{{ t("sales.details.members.lastName") }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="lastName"
                        v-model="newMember.lastName"
                        placeholder="Enter last name"
                        required
                      />
                      <div class="invalid-feedback">
                        Please enter the last name.
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <!-- Date of Birth Field -->
                    <div class="col-md-6 mb-3">
                      <label for="birthDate" class="form-label"
                        >{{ t("sales.details.members.birthDate") }}
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        id="birthDate"
                        v-model="newMember.birthDate"
                        placeholder="MM/DD/YYYY"
                        required
                      />
                      <div class="invalid-feedback">
                        Please enter the date of birth.
                      </div>
                    </div>
                    <!-- Relationship Field -->
                    <div class="col-md-6 mb-3">
                      <a-form-item name="relationship">
                        <label for="is" class="form-label"
                          >{{
                            t("sales.details.members.relationship.relationship")
                          }}
                        </label>
                        <a-select
                          v-model:value="newMember.relationship"
                          size="large"
                          id="relationship"
                          placeholder="select"
                        >
                          <a-select-option value="Dad">{{
                            t("sales.details.members.relationship.dad")
                          }}</a-select-option>
                          <a-select-option value="Mom">{{
                            t("sales.details.members.relationship.mom")
                          }}</a-select-option>
                          <a-select-option value="Son">{{
                            t("sales.details.members.relationship.son")
                          }}</a-select-option>
                          <a-select-option value="Daughter">{{
                            t("sales.details.members.relationship.daughter")
                          }}</a-select-option>
                          <a-select-option value="Grandpa">{{
                            t("sales.details.members.relationship.grandpa")
                          }}</a-select-option>
                          <a-select-option value="Grandma">{{
                            t("sales.details.members.relationship.grandma")
                          }}</a-select-option>
                          <a-select-option value="Others">{{
                            t("sales.details.members.relationship.other")
                          }}</a-select-option>
                        </a-select>
                      </a-form-item>
                    </div>
                    <div class="invalid-feedback">
                      Please enter the Relationship
                    </div>
                  </div>

                  <div class="row">
                    <!-- Gender Field -->
                    <div class="col-md-6 mb-3">
                      <a-form-item name="gender" :rules="rules">
                        <label for="gender" class="form-label">{{
                          t("sales.details.members.gender")
                        }}</label>
                        <a-select
                          v-model:value="newMember.gender"
                          size="large"
                          id="gender"
                          placeholder="select"
                          required
                        >
                          <a-select-option value="Women">Women</a-select-option>
                          <a-select-option value="Men">Men</a-select-option>
                        </a-select>
                      </a-form-item>
                    </div>

                    <!-- Social Security Number Field -->
                    <div class="col-md-6 mb-3">
                      <label for="ssn" class="form-label">{{
                        t("sales.details.members.socialSecurityNumber")
                      }}</label>
                      <input
                        type="number"
                        class="form-control"
                        id="socialSecurityNumber"
                        v-model="newMember.socialSecurityNumber"
                        placeholder="Social Security Number"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <!-- Immigration Status Field -->
                    <div class="col-md-6 mb-3">
                      <a-form-item name="inmigrationStatus">
                        <label for="is" class="form-label">{{
                          t("sales.details.members.inmigrationStatus")
                        }}</label>
                        <a-select
                          v-model:value="newMember.inmigrationStatus"
                          size="large"
                          id="inmigrationStatus"
                          placeholder="select"
                          required
                        >
                          <a-select-option value="Resident"
                            >Resident</a-select-option
                          >
                          <a-select-option value="Citizen"
                            >Citizen</a-select-option
                          >
                          <a-select-option value="Work Permit"
                            >Work Permit</a-select-option
                          >
                          <a-select-option value="Others"
                            >Others</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                    </div>
                    <!-- Apply for medical coverage -->
                    <div class="col-md-6 mb-3 d-flex align-items-center">
                      <a-form-item name="medicalCoverage" class="mb-0">
                        <a-checkbox class="mb-0">{{
                          t("sales.details.members.medical")
                        }}</a-checkbox>
                      </a-form-item>
                    </div>
                  </div>
                </FormWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card .card-body.container-form h5.card-title {
  color: #404c58;
  font-weight: 500;
}
</style>
