<script setup>
import { getUser, statusColors } from "@/utils/";
import { useSaleStore } from "@/store/sales";
import { ref, onMounted, computed } from "vue";
import { Notyf } from "notyf";
import { t } from "@/utils/i18n";

const roleUser = ref("");
const notyf = new Notyf();
const isLoading = ref(true);
const salesCount = ref([]);
const saleStore = useSaleStore();
const { draft, assigned, rejected, corrected, submitted, to_review } =
  statusColors;

const sumTotSales = ref({
  draft: 0,
  assigned: 0,
  rejected: 0,
  corrected: 0,
  submitted: 0,
});

const optionsChart = {
  chart: {
    id: "vuechart-example",
    type: "bar",
    fontFamily: "Inter, sans-serif",
    height: 350,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "60%",
      endingShape: "rounded",
    },
  },
  colors: [draft, assigned, rejected, corrected, submitted, to_review],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  fill: {
    opacity: 1,
  },
};

function totCount(arr) {
  return arr.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
}

function extractMonthlyDataByState(data, state) {
  if (!data || data.length === 0 || !state) {
    return [];
  }

  const yearData = data[0].data;

  if (!(state in yearData[0])) {
    console.log("--- Status sale: ", state);
    return Array(12).fill(0);
  }

  const monthlyValues = yearData.map((monthData) => monthData[state]);

  return monthlyValues;
}

const setDataChart = async () => {
  try {
    const totSalesCount = await saleStore.fetchMonthlySalesSummary();

    const draftAll = extractMonthlyDataByState(
      totSalesCount.data,
      "draft_total"
    );
    const assignedAll = extractMonthlyDataByState(
      totSalesCount.data,
      "assigned_total"
    );
    const rejectedAll = extractMonthlyDataByState(
      totSalesCount.data,
      "rejected_total"
    );
    const correctedAll = extractMonthlyDataByState(
      totSalesCount.data,
      "corrected_total"
    );
    const submittedAll = extractMonthlyDataByState(
      totSalesCount.data,
      "submitted_total"
    );
    const toreviewAll = extractMonthlyDataByState(
      totSalesCount.data,
      "toreview_total"
    );

    sumTotSales.value.draft = totCount(draftAll);
    sumTotSales.value.assigned = totCount(assignedAll);
    sumTotSales.value.rejected = totCount(rejectedAll);
    sumTotSales.value.corrected = totCount(correctedAll);
    sumTotSales.value.submitted = totCount(submittedAll);
    sumTotSales.value.to_review = totCount(toreviewAll);

    salesCount.value = [
      {
        name: "Draft",
        type: "column",
        data: draftAll,
      },
      {
        name: "Assigned",
        type: "column",
        data: assignedAll,
      },
      {
        name: "Rejected",
        type: "column",
        data: rejectedAll,
      },
      {
        name: "Corrected",
        type: "column",
        data: correctedAll,
      },
      {
        name: "Submitted",
        type: "column",
        data: submittedAll,
      },
      {
        name: "To_review",
        type: "column",
        data: toreviewAll,
      },
    ];

    isLoading.value = false;
  } catch (err) {
    const error = saleStore.error;

    let errorMessage = "Something went wrong. Please try again later.";

    if (error.response) {
      switch (error.response.status) {
        case 404:
          errorMessage = "Data not found. Please try a different query.";
          break;
        case 500:
          errorMessage = "Server error. Please try again later.";
          break;
        default:
          errorMessage = "Unexpected error occurred. Please try again.";
      }
    } else if (error.request) {
      errorMessage =
        "No response from the server. Please check your network connection.";
    }

    notyf.error(errorMessage);
  }
};

const getDynamicClass = computed(() => {
  const totalElements = roleUser.value === "SELLER" ? 6 : 5;
  return totalElements > 5 ? "compact-view" : "";
});

onMounted(() => {
  roleUser.value = getUser().role;
  setDataChart();
});
</script>

<template>
  <div class="card flex-fill">
    <div class="card-header border-0">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="card-title">{{ t("dashboard.title1") }}</h5>

        <div class="dropdown d-none">
          <button
            class="btn btn-white btn-sm dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Monthly
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <a href="javascript:void(0);" class="dropdown-item">Weekly</a>
            </li>
            <li>
              <a href="javascript:void(0);" class="dropdown-item">Monthly</a>
            </li>
            <li>
              <a href="javascript:void(0);" class="dropdown-item">Yearly</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-body pt-0 h-100">
      <div class="border-dotted" v-if="isLoading"></div>
      <div
        class="d-flex justify-content-center align-items-center h-100"
        v-if="isLoading"
      >
        <a-spin size="large" />
      </div>

      <div v-if="!isLoading">
        <hr class="m-0" />
        <div
          class="w-md-100 d-flex justify-content-center align-items-center flex-wrap flex-md-nowrap sales-count-bar-chart"
        >
          <div v-show="roleUser === 'SELLER'" :class="getDynamicClass">
            <p class="h3">{{ sumTotSales.draft }}</p>
            <span>Draft sales</span>
          </div>
          <div :class="getDynamicClass">
            <p class="h3">{{ sumTotSales.assigned }}</p>
            <span>{{ t("dashboard.cards.assigned") }}</span>
          </div>
          <div :class="getDynamicClass">
            <p class="h3">{{ sumTotSales.rejected }}</p>
            <span>{{ t("dashboard.cards.rejected") }}</span>
          </div>
          <div :class="getDynamicClass">
            <p class="h3">{{ sumTotSales.corrected }}</p>
            <span>{{ t("dashboard.cards.corrected") }}</span>
          </div>
          <div :class="getDynamicClass">
            <p class="h3">{{ sumTotSales.submitted }}</p>
            <span>{{ t("dashboard.cards.submitted") }}</span>
          </div>
          <div :class="getDynamicClass">
            <p class="h3">{{ sumTotSales.to_review }}</p>
            <span>{{ t("dashboard.cards.to_review") }}</span>
          </div>
        </div>
        <hr class="m-0 mb-3" />

        <apexchart
          type="bar"
          height="350"
          :options="optionsChart"
          :series="salesCount"
        ></apexchart>

        <div class="text-center text-muted" v-show="!isLoading">
          <div class="row d-flex justify-content-center">
            <div class="col-2" v-if="roleUser === 'SELLER'">
              <div class="mt-4">
                <p class="mb-2 text-truncate">
                  <i class="fas fa-circle dot-draft me-1"></i>
                  {{ t("dashboard.cards.draft") }}
                </p>
              </div>
            </div>
            <div class="col-2">
              <div class="mt-4">
                <p class="mb-2 text-truncate">
                  <i class="fas fa-circle dot-assigned me-1"></i>
                  {{ t("dashboard.cards.assigned") }}
                </p>
              </div>
            </div>
            <div class="col-2">
              <div class="mt-4">
                <p class="mb-2 text-truncate">
                  <i class="fas fa-circle dot-rejected me-1"></i>
                  {{ t("dashboard.cards.rejected") }}
                </p>
              </div>
            </div>
            <div class="col-2">
              <div class="mt-4">
                <p class="mb-2 text-truncate">
                  <i class="fas fa-circle dot-corrected me-1"></i>
                  {{ t("dashboard.cards.corrected") }}
                </p>
              </div>
            </div>
            <div class="col-2">
              <div class="mt-4">
                <p class="mb-2 text-truncate">
                  <i class="fas fa-circle dot-submitted me-1"></i>
                  {{ t("dashboard.cards.submitted") }}
                </p>
              </div>
            </div>
            <div class="col-2">
              <div class="mt-4">
                <p class="mb-2 text-truncate">
                  <i class="fas fa-circle dot-to_review me-1"></i>
                  {{ t("dashboard.cards.to_review") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.sales-count-bar-chart > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  border: 3px dotted #f0f1f5;
  padding: 15px 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  min-width: 16.666%; /* Cambiado de 20% a 16.666% para 6 elementos */
}

.sales-count-bar-chart > div:last-child {
  border-right: 0;
}

.h3 {
  color: #495057;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

/* Estilos para la vista compacta cuando hay más de 5 elementos */
.compact-view {
  padding: 10px 0 !important;
}

.compact-view .h3 {
  font-size: 1rem !important;
  margin-bottom: 0.3rem;
}

.compact-view span {
  font-size: 0.85rem;
}

/* Responsive */
@media (max-width: 1200px) {
  .sales-count-bar-chart > div {
    min-width: 33.333%;
    border-right: 0;
    border-bottom: 3px dotted #f0f1f5;
  }

  .sales-count-bar-chart > div:last-child,
  .sales-count-bar-chart > div:nth-last-child(2) {
    border-bottom: 0;
  }
}

@media (max-width: 768px) {
  .sales-count-bar-chart > div {
    min-width: 50%;
  }

  .h3 {
    font-size: 1.1rem;
  }

  .compact-view .h3 {
    font-size: 0.9rem !important;
  }
}

hr {
  border: none;
  border-top: 3px dotted;
  color: #b9bcc5;
  width: 100%;
}
</style>
