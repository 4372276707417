<template>
  <div class="col-lg-8">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title d-flex justify-content-between">Activity</h5>
      </div>
      <div class="card-body card-body-height line" style="max-height: 440px; overflow-y: auto; padding: 40px;">
        <a-timeline>
          <a-timeline-item 
            v-for="log in usersDataLogs" 
            :key="log.logId"
            color="#57015f"
          >
            <div class="timeline-content" v-html="formatLogMessage(log.message)"></div>
            <div class="feed-date">
              {{ formatDateFromTimestamp(log.timestamp) }}
            </div>
          </a-timeline-item>
        </a-timeline>
      </div>
    </div>
  </div>
</template>

<script setup>
import { formatDateFromTimestamp } from "@/utils";
import { useRoute } from "vue-router";
import { useUserStore } from "@/store/user";
import { ref, onMounted } from "vue";

const userStore = useUserStore();
const route = useRoute();

const usersDataLogs = ref([]);
const uid = route.params.uid;

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const formatLogMessage = (message) => {
  const states = ["justification", "declined", "accepted", "assigned", "corrected", "created", "commented", "added"];
  
  states.forEach(state => {
    const regex = new RegExp(state, 'gi');
    message = message.replace(regex, capitalizeFirstLetter(state));
  });
  
  const boldName = (name) => `<strong>${name}</strong>`;
  
  const handleNameAtStart = (msg) => {
    const parts = msg.split(' ');
    if (parts.length >= 2) {
      const possibleName = parts.slice(0, 2).join(' ');
      const restOfMessage = parts.slice(2).join(' ');
      
      if (/^[A-Z][a-z]+ [A-Z][a-z]+/.test(possibleName)) {
        return `${boldName(possibleName)} <span style="color: gray;">${restOfMessage}</span>`;
      }
    }
    return null;
  };

  if (message.toLowerCase().includes("assigned to")) {
    const parts = message.split(/assigned to/i);
    const name = parts[1].trim();
    return `<span style="color: gray;">${parts[0]}Assigned to </span>${boldName(name)}`;
  }
  
  if (message.includes("made a Justification")) {
    const parts = message.split("made a Justification");
    const name = parts[0].trim();
    return `${boldName(name)} <span style="color: gray;">made a Justification ${parts[1]}</span>`;
  }
  
  if (message.toLowerCase().includes("corrected the sale")) {
    const parts = message.split(/corrected the sale/i);
    const name = parts[0].trim();
    return `${boldName(name)} <span style="color: gray;">Corrected the sale</span>`;
  }
  
  if (message.toLowerCase().includes("created sale")) {
    const parts = message.split(/created sale/i);
    const name = parts[0].trim();
    return `${boldName(name)} <span style="color: gray;">Created sale ${parts[1].trim()}</span>`;
  }

  const nameAtStartResult = handleNameAtStart(message);
  if (nameAtStartResult) {
    return nameAtStartResult;
  }
  
  return `<span style="color: gray;">${message}</span>`;
};

const getLogs = async () => {
  try {
    const usersLogs = await userStore.getUsersLogs(uid);
    usersDataLogs.value = usersLogs;
  } catch (error) {
     message.error("Failed to load existing Logs.");
  }
};

onMounted(() => {
  getLogs();
});
</script>

<style scoped>

.timeline-content {
  margin-bottom: 4px;
}

.feed-date {
  color: #666;
  font-size: 0.85em;
}

:deep(.ant-timeline-item-head) {
  background-color: #57015f !important;
  border-color: #57015f !important;
}

:deep(.ant-timeline-item-head-custom) {
  background: none !important;
}

.expand-button {
  color: #57015f;
  font-weight: 500;
}

.expand-button:hover {
  color: #760280;
}
</style>
