<script setup>
import { defineProps } from "vue";
import { ref, onMounted } from "vue";
import { getUser } from "@/utils/";
import { t } from "@/utils/i18n";

import BarChartMonthlySales from "./components/bar-chart-monthly-sales.vue";
import DonutChartCompanies from "./components/donut-chart-companies-sales.vue";
import DonutChartStatus from "./components/donut-chart-status-sales.vue";
import BarChartPlansSales from "./components/bar-chart-plans-sales.vue";

const props = defineProps({
  dateRange: {
    type: Object,
    required: true,
  },
});

const firstName = ref("");
const userRole = ref("");

function getFirstName(fullName) {
  if (typeof fullName !== "string" || !fullName.trim()) {
    throw new Error("Invalid input: fullName must be a non-empty string.");
  }

  const [firstName] = fullName.trim().split(" ");
  return firstName;
}

onMounted(() => {
  const user = getUser();
  userRole.value = user.role;
  firstName.value = getFirstName(user.name);
});
</script>

<template>
  <div class="content-page-header">
    <div>
      <h5>
        {{ t("dashboard.hi") }},
        <span class="text-black">{{ firstName }}</span>
      </h5>
      <p class="text-secondary pt-2">{{ t("dashboard.text") }}</p>
    </div>
  </div>

  <main class="secction-dashboard">
    <sales-card :dateRange="props.dateRange" />

    <div class="row">
      <div class="col-xl-7 d-flex">
        <BarChartMonthlySales />
      </div>

      <div class="col-xl-5 d-flex">
        <DonutChartStatus
          v-if="userRole !== 'ADMIN' && userRole !== 'SUPERVISOR'"
          :dateRange="dateRange"
        />
        <DonutChartCompanies
          v-if="userRole === 'ADMIN' || userRole === 'SUPERVISOR'"
          :dateRange="dateRange"
        />
      </div>
    </div>

    <div class="row">
      <div
        class="col-xl-6 d-flex"
        v-if="userRole === 'ADMIN' || userRole === 'SUPERVISOR'"
      >
        <DonutChartStatus :dateRange="dateRange" />
      </div>

      <div class="col-xl-6 d-flex">
        <BarChartPlansSales />
      </div>
    </div>
  </main>
</template>
