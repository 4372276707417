<template>
  <!-- Search Filter -->
  <div id="filter_b-form-inputs" class="card filter-card">
    <div class="card-body pb-0">
      <div class="row">
        <div class="col-sm-6 col-md-3">
          <div class="form-group">
            <label>Name</label>
            <b-form-input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="form-group">
            <label>Email</label>
            <b-form-input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="form-group">
            <label>Phone</label>
            <b-form-input type="text" class="form-control" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Search Filter -->
</template>
