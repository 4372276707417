<script setup>
import { ref } from "vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useAuthStore } from "@/store/auth";
import { useRouter } from "vue-router";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

const authStore = useAuthStore();
const router = useRouter();
const notyf = new Notyf();

const credential = ref({ email: "", password: "" });
const showPassword = ref(false);

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email format"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long"),
});
const handleLogin = async () => {
  try {
    await schema.validate(credential.value, { abortEarly: false });

    if (!credential.value.email || !credential.value.password) {
      notyf.error("Invalid credentials");
      return;
    }

    authStore.authenticate(credential.value);

    // Intentar iniciar sesión
    await authStore.login();

    // Verificar si se ha autenticado con éxito
    if (authStore.user) {
      notyf.success("Login successful");
      router.push("/dashboard/");
    } else {
      notyf.error("Invalid credentials");
    }
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      error.errors.forEach((err) => notyf.error(err));
    } else {
      notyf.error("Invalid credentials");
    }
  }
};

function toggleShow() {
  showPassword.value = !showPassword.value;
}
</script>

<template>
  <div class="main-wrapper login-body">
    <div class="login-wrapper">
      <div class="container">
        <img
          class="img-fluid logo-dark mb-2 logo-color"
          src="@/assets/img/logo1.png"
          alt="Logo"
        />
        <img
          class="img-fluid logo-light mb-2"
          src="@/assets/img/logo1.png"
          alt="Logo"
        />
        <div class="loginbox">
          <div class="login-right">
            <div class="login-right-wrap">
              <h1>Login</h1>
              <p class="account-subtitle">Fill in the data to enter</p>

              <Form
                @submit="handleLogin"
                :validation-schema="schema"
                v-slot="{ errors }"
              >
                <div class="input-block mb-3">
                  <label class="form-control-label">Email Address</label>
                  <Field
                    name="email"
                    type="text"
                    value="example@dreamstechnologies.com"
                    class="form-control"
                    v-model="credential.email"
                  />
                  <div class="invalid-feedback">{{ errors.email }}</div>
                  <div class="emailshow text-danger" id="email"></div>
                </div>
                <div class="input-block mb-3">
                  <label class="form-control-label">Password</label>
                  <div class="pass-group">
                    <Field
                      name="password"
                      :type="showPassword ? 'text' : 'password'"
                      value="123456"
                      class="form-control pass-input mt-2"
                      v-model="credential.password"
                    />
                    <span @click="toggleShow" class="toggle-password">
                      <i
                        :class="{
                          'fas fa-eye': showPassword,
                          'fas fa-eye-slash': !showPassword,
                        }"
                      ></i>
                    </span>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                  </div>
                </div>
                <div class="input-block mb-3">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-check custom-checkbox">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="cb1"
                        />
                        <label class="custom-control-label" for="cb1"
                          >Remember me</label
                        >
                      </div>
                    </div>
                    <div class="col-6 text-end">
                      <router-link class="forgot-link" to="forgot-password"
                        >Forgot Password ?</router-link
                      >
                    </div>
                  </div>
                </div>

                <button class="btn btn-lg btn-primary w-100">Login</button>
                <div class="login-or">
                  <span class="or-line"></span>
                  <span class="span-or">or</span>
                </div>

                <div class="text-center dont-have">
                  Don't have an account yet?
                  <router-link to="register">Register</router-link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
