<script setup>
import { defineEmits, ref } from "vue";
import FormWrapper from "./form-validation.vue";
import { t } from "@/utils/i18n";

const emit = defineEmits(["formValidated"]);

const relationships = ref([
  "Dad",
  "Mom",
  "Son",
  "Daughter",
  "Grandpa",
  "Grandma",
  "Others",
]);

const immigrationStatuses = ref([
  "Resident",
  "Citizen",
  "Work Permit",
  "Others",
]);

const props = defineProps({
  member: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

const handleFormValidated = (status) => {
  emit("formValidated", status);
};

const newMember = ref({
  firstName: props.member.firstName || "",
  lastName: props.member.lastName || "",
  birthDate: props.member.birthDate || "",
  gender: props.member.gender || "",
  socialSecurityNumber: props.member.socialSecurityNumber || null,
  relationship: props.member.relationship || "",
  inmigrationStatus: props.member.inmigrationStatus || "",
});
</script>

<template>
  <div class="accordion" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button text-center text-black w-100"
          type="button"
          :data-bs-toggle="'collapse'"
          :data-bs-target="`#collapse-${index}`"
          aria-expanded="true"
          :aria-controls="`collapse-${index}`"
        >
          <span class="fs-5 fw-normal w-100 p-2"
            >{{ t("sales.details.members.memberOne") }}
            {{ index === 1 ? t("sales.details.members.primary") : index }} -
            {{ member.firstName }} {{ member.lastName }}</span
          >
        </button>
      </h2>
      <!-- show -->
      <div
        :id="`collapse-${index}`"
        class="accordion-collapse collapse"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body p-0">
          <div class="card m-0">
            <div class="card-body">
              <h5 class="card-title mb-5">
                {{ t("sales.details.members.members") }} {{ index }}
              </h5>
              <FormWrapper @form-validated="handleFormValidated">
                <!-- Form Fields -->
                <div class="row">
                  <!-- Name Field -->
                  <div class="col-md-6 mb-3">
                    <label for="firstName" class="form-label"
                      >{{ t("sales.details.members.firstName") }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="firstName"
                      v-model="newMember.firstName"
                      disabled
                      placeholder="Enter first name"
                      required
                    />
                    <div class="invalid-feedback">
                      Please enter the first name.
                    </div>
                  </div>

                  <!-- Last Name Field -->
                  <div class="col-md-6 mb-3">
                    <label for="lastName" class="form-label">{{
                      t("sales.details.members.lastName")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="lastName"
                      v-model="newMember.lastName"
                      placeholder="Enter last name"
                      disabled
                      required
                    />
                    <div class="invalid-feedback">
                      Please enter the last name.
                    </div>
                  </div>
                </div>

                <div class="row">
                  <!-- Date of Birth Field -->
                  <div class="col-md-6 mb-3">
                    <label for="birthDate" class="form-label">{{
                      t("sales.details.members.birthDate")
                    }}</label>
                    <input
                      type="date"
                      class="form-control"
                      id="birthDate"
                      v-model="newMember.birthDate"
                      placeholder="MM/DD/YYYY"
                      disabled
                      required
                    />
                    <div class="invalid-feedback">
                      Please enter the date of birth.
                    </div>
                  </div>

                  <!-- Relationship Field -->
                  <div class="col-md-6 mb-3" v-if="index > 1">
                    <label for="relationship" class="form-label">{{
                      t("sales.details.members.relationship.relationship")
                    }}</label>
                    <select
                      class="form-select"
                      disabled
                      v-model="newMember.relationship"
                      id="relationship"
                      required
                    >
                      <option value="" disabled>Select</option>
                      <option
                        v-for="relationship in relationships"
                        :key="relationship"
                        :value="relationship"
                      >
                        {{ relationship }}
                      </option>
                    </select>
                    <div class="invalid-feedback">
                      Please select the relationship.
                    </div>
                  </div>

                  <!-- Gender Field -->
                  <div class="col-md-6 mb-3" v-if="index === 1">
                    <label for="gender" class="form-label">{{
                      t("sales.details.members.gender")
                    }}</label>
                    <select
                      class="form-select"
                      disabled
                      v-model="newMember.gender"
                      id="gender"
                      required
                    >
                      <option value="" disabled>Select</option>
                      <option value="Women">Women</option>
                      <option value="Men">Men</option>
                    </select>
                    <div class="invalid-feedback">
                      Please select the gender.
                    </div>
                  </div>
                </div>

                <div class="row">
                  <!-- Gender Field -->
                  <div class="col-md-6 mb-3" v-if="index > 1">
                    <label for="gender" class="form-label">{{
                      t("sales.details.members.gender")
                    }}</label>
                    <select
                      class="form-select"
                      disabled
                      v-model="newMember.gender"
                      id="gender"
                      required
                    >
                      <option value="" disabled>Select</option>
                      <option value="Women">Women</option>
                      <option value="Men">Men</option>
                    </select>
                    <div class="invalid-feedback">
                      Please select the gender.
                    </div>
                  </div>

                  <!-- Social Security Number Field -->
                  <div class="col-md-6 mb-3">
                    <label for="ssn" class="form-label">{{
                      t("sales.details.members.socialSecurityNumber")
                    }}</label>
                    <input
                      type="number"
                      class="form-control"
                      disabled
                      id="socialSecurityNumber"
                      v-model="newMember.socialSecurityNumber"
                      placeholder="Social Security Number"
                    />
                  </div>

                  <!-- Immigration Status Field -->
                  <div class="col-md-6 mb-3" v-if="index === 1">
                    <label for="immigrationStatus" class="form-label">{{
                      t("sales.details.members.inmigrationStatus")
                    }}</label>
                    <select
                      class="form-select"
                      id="inmigrationStatus"
                      disabled
                      v-model="newMember.inmigrationStatus"
                      required
                    >
                      <option value="" disabled>Select</option>
                      <option
                        v-for="status in immigrationStatuses"
                        :key="status"
                        :value="status"
                      >
                        {{ status }}
                      </option>
                    </select>
                    <div class="invalid-feedback">
                      Please select the immigration status.
                    </div>
                  </div>
                </div>

                <div class="row">
                  <!-- Immigration Status Field -->
                  <div class="col-md-6 mb-3" v-if="index > 1">
                    <label for="immigrationStatus" class="form-label">{{
                      t("sales.details.members.inmigrationStatus")
                    }}</label>
                    <select
                      class="form-select"
                      id="inmigrationStatus"
                      disabled
                      v-model="newMember.inmigrationStatus"
                      required
                    >
                      <option value="" disabled>Select</option>
                      <option
                        v-for="status in immigrationStatuses"
                        :key="status"
                        :value="status"
                      >
                        {{ status }}
                      </option>
                    </select>
                    <div class="invalid-feedback">
                      Please select the immigration status.
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!-- Apply for medical coverage -->
                  <div
                    class="col-md-6 mb-3 d-flex align-items-center"
                    v-if="index > 1"
                  >
                    <a-form-item name="medicalCoverage" class="mb-0">
                      <a-checkbox class="mb-0">{{
                        t("sales.details.members.medical")
                      }}</a-checkbox>
                    </a-form-item>
                  </div>
                </div>
              </FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
button.accordion-button,
.card,
input {
  background-color: #f5f5f5;
}

.card .card-body.container-form h5.card-title {
  color: #404c58;
  font-weight: 500;
}
</style>
