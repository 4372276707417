<script setup>
import { useUserStore } from "@/store/user";
import { ref, onMounted, computed } from "vue";
import moment from "moment";
import { Notyf } from "notyf";
import { router } from "@/router";
import { formatDateFromTimestamp } from "@/utils";
import { t } from "@/utils/i18n";

const userStore = useUserStore();
const data = ref([]);
const isLoading = ref(true);
const notyf = new Notyf();
let counter = 1;
const editModal = ref(null);
const editUsers = ref({});
const title = computed(() => t("sales.users.users"));
const text = computed(() => t("sales.users.add"));

const columns = computed(() => [
  {
    title: "#",
    dataIndex: "Counter",
  },
  {
    title: t("sales.users.name"),
    dataIndex: "UserName",
    key: "id",
  },
  {
    title: t("sales.users.phone"),
    dataIndex: "PhoneNumber",
  },
  {
    title: t("sales.users.role"),
    dataIndex: "Role",
  },
  {
    title: t("sales.users.company"),
    dataIndex: "Company",
  },
  {
    title: t("sales.users.carrier"),
    dataIndex: "Carrier",
  },
  {
    title: t("sales.users.created"),
    dataIndex: "Createdon",
  },
  {
    title: t("sales.users.status"),
    dataIndex: "state",
    key: "state",
  },
  {
    title: t("sales.users.action"),
    key: "action",
  },
]);

const loadUser = async () => {
  try {
    const users = await userStore.fetchUsers();

    data.value = users.map((user) => ({
      Counter: counter++,
      UserName: user.firstName + " " + user.lastName,
      mail: user.email,
      PhoneNumber: user.phone,
      Role: user.role,
      Photo: user.profileUrl,
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      phone: user.phone,
      email: user.email,
      uid: user.uid,
      profileUrl: user.profileUrl,
      Createdon: formatDateFromTimestamp(user.createdAt),
      Company: user.company,
      Carrier: user.carrier,
      state:
        user.state === "ASSET" || user.state === "ACTIVE"
          ? "ACTIVE"
          : "INACTIVE",
    }));
  } catch (error) {
    notyf.error("Error loading users");
  } finally {
    isLoading.value = false;
  }
};

const userInfo = localStorage.getItem("user");
const userRole = JSON.parse(userInfo).role;

const filteredUsers = computed(() => {
  if (userRole === "SUPERVISOR") {
    return data.value.filter((user) => user.Role !== "ADMIN");
  }
  return data.value;
});

const addNewUser = (newUser) => {
  if (!newUser) {
    notyf.error("please enter the requested information");
  } else {
    // Check if supervisor is trying to add an admin
    if (newUser.value === "SUPERVISOR" && newUser.role === "ADMIN") {
      notyf.error("Supervisors cannot add admin users");
      return;
    }

    data.value.unshift({
      Counter: 1,
      UserName: newUser.firstName + " " + newUser.lastName,
      mail: newUser.email,
      PhoneNumber: newUser.phone,
      Role: newUser.role,
      Company: newUser.company,
      Photo: newUser.profileUrl,
      Createdon: moment(newUser.createdAt)
        .format("MMM/DD/YYYY HH:mm")
        .toUpperCase(),
      state: newUser.state,
    });
    for (let i = 1; i < data.value.length; i++) {
      data.value[i].Counter = i + 1;
    }
    notyf.success("New user added successfully");
  }
};

const getStatusClass = (status) => {
  const statusClasses = {
    ASSET: "badge bg-success-light text-success",
    ACTIVE: "badge bg-success-light text-success",
    INACTIVE: "badge bg-danger-light text-danger",
    PENDING: "badge bg-warning-light text-warning",
    SUSPENDED: "badge bg-info-light text-info",
  };
  return statusClasses[status] || "badge bg-secondary";
};

const updateUser = (updatedUser) => {
  if (!updatedUser) {
    notyf.error("please enter the requested information");
  } else {
    // Check if supervisor is trying to update to admin role
    if (updatedUser.value === "SUPERVISOR" || updatedUser.role === "ADMIN") {
      notyf.error("Supervisors cannot modify admin users");
      return;
    }

    const userIndex = data.value.findIndex(
      (user) =>
        user.UserName === updatedUser.firstName + " " + updatedUser.lastName
    );

    if (userIndex !== -1) {
      data.value[userIndex] = { ...data.value[userIndex], ...updatedUser };
      notyf.success("User updated successfully");
    } else {
      notyf.error("User not found");
    }
  }
};

function openEditModal(record) {
  // Prevent supervisor from editing admin users
  if (record.value === "SUPERVISOR") {
    notyf.error("Supervisors cannot edit admin users");
    return;
  }
  editUsers.value = record;
}

function navigateToProfile(record) {
  // Prevent supervisor from viewing admin profiles
  if (record.value === "SUPERVISOR" && record.Role === "ADMIN") {
    notyf.error("Supervisors cannot view admin profiles");
    return;
  }
  router.push(`/profile/${record.uid}`);
}

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

onMounted(loadUser);
</script>
<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <userheader :title="title" :text="text" />
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="card-table">
          <div class="card-body">
            <div class="table-responsive">
              <a-table
                class="stripped table-hover"
                :columns="columns"
                :loading="isLoading"
                :data-source="filteredUsers"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'id'">
                    <h2 class="table-avatar d-flex">
                      <router-link to="/profile" class="avatar avatar-sm me-2">
                        <img
                          class="avatar-img rounded-circle"
                          :src="record.Photo"
                          alt="User Image"
                        />
                      </router-link>
                      <router-link
                        to="/profile"
                        @click="navigateToProfile(record)"
                      >
                        {{ record.UserName }}
                        <span>{{ record.mail }}</span>
                      </router-link>
                    </h2>
                  </template>
                  <template v-else-if="column.key === 'state'">
                    <span :class="getStatusClass(record.state)">
                      {{ capitalize(record.state) }}
                    </span>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div class="d-flex align-items-center">
                      <div class="dropdown dropdown-action">
                        <a
                          href="javascript:;"
                          class="dropdown-item custom-dropdown-item"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <ul>
                            <li>
                              <a
                                class="dropdown-item edit-link"
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#edit_user"
                                @click="openEditModal(record)"
                              >
                                <i class="far fa-edit me-2"></i
                                >{{ t("sales.users.newUsers.edit") }}
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#delete_modal"
                              >
                                <i class="far fa-trash-alt me-2"></i
                                >{{ t("sales.users.newUsers.delete") }}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
  <usermodel
    @user-added="addNewUser"
    :data="editUsers"
    @user-edit="updateUser"
  ></usermodel>
</template>

<style>
.dropdown-item.edit-link:active,
.dropdown-item.edit-link:focus,
li a.dropdown-item:active,
li a.dropdown-item:focus {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(46, 1, 46) !important;
}

.dropdown-item.edit-link:hover,
li a.dropdown-item:hover {
  color: rgb(255, 249, 255) !important;
  background-color: rgb(79, 3, 79) !important;
}
</style>
