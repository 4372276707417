<script setup>
import { useUserStore } from "@/store/user";
import { computed, defineProps, defineEmits, onMounted, ref } from "vue";
import { t } from "@/utils/i18n";
// Define props and emits
const props = defineProps({
  saleData: Object,
});
const carriers = ref([]);
const userStore = useUserStore();

const emit = defineEmits(["updateSaleData"]);

// Computed properties to bind form inputs directly to the store
const planInformation = computed(() => props.saleData.planInformation || {});

// Emit updates directly to the parent
const updatePlanInformation = (field, value) => {
  emit("updateSaleData", { section: "planInformation", field, value });
};

const loadCarrier = async () => {
  try {
    const carrier = await userStore.fetchCarrier();
    carriers.value = carrier;
  } catch (error) {
    console.log("Error loading users", error);
  }
};

onMounted(loadCarrier);
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body container-form">
            <h5 class="card-title mb-5">{{ t("sales.details.plan.plan") }}</h5>
            <form>
              <!-- Form Fields -->
              <div class="row">
                <!-- Carrier Field -->
                <div class="col-md-6 mb-3">
                  <label for="carrier" class="form-label no-required">{{
                    t("sales.details.plan.carrier")
                  }}</label>
                  <select
                    class="form-select"
                    v-model="planInformation.carrier"
                    @change="
                      updatePlanInformation('carrier', planInformation.carrier)
                    "
                  >
                    <option value="" disabled>Select carrier</option>

                    <option
                      v-for="carrier in carriers[0]?.list"
                      :key="carrier.id"
                      :value="carrier.id"
                    >
                      {{ carrier.value }}
                      <!-- Display carrier name -->
                    </option>
                  </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="monthlyFee" class="form-label no-required">{{
                    t("sales.details.plan.plan")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="planInformation.plan"
                    @input="updatePlanInformation('plan', planInformation.plan)"
                    placeholder="Enter plan"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
