<template>
  <div class="page-header w-100">
    <div class="content-page-header d-flex justify-content-between w-100">
      <h5>{{ title }}</h5>
      <div class="list-btn">
        <ul class="filter-list">
          <li class="d-none">
            <a
              class="btn btn-filters w-auto popup-toggle"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              data-bs-original-title="Filter"
              ><span class="fa-light fa-bars-filter"
                ><img
                  src="@/assets/img/icons/filter-icon.svg"
                  alt="filter" /></span
              >Filter
            </a>
          </li>
          <li>
            <button
              type="button"
              class="btn btn-filters w-auto rounded ms-2"
              data-bs-toggle="modal"
              data-bs-target="#add_company"
            >
              <i class="feather feather-plus-circle me-2 fs-6"></i>
              {{ t("sales.users.addCompany") }}
            </button>
          </li>
          <li>
            <a
              class="btn btn-primary"
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-target="#add_user"
              ><i class="fa fa-plus-circle me-2" aria-hidden="true"></i
              >{{ text }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="toggle-sidebar">
    <div class="sidebar-layout-filter">
      <div class="sidebar-header">
        <h5>Filter</h5>
        <a href="javascript:;" class="sidebar-closes"
          ><i class="fa-regular fa-circle-xmark"></i
        ></a>
      </div>
      <div class="sidebar-body">
        <form action="javascript:;" autocomplete="off">
          <!-- Customer -->
          <div class="accordion" id="accordionMain1">
            <div class="card-header-new" id="headingOne">
              <h6 class="filter-title">
                <a
                  href="javascript:void(0);"
                  class="w-100"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Customer
                  <span class="float-end"
                    ><i class="fa-solid fa-chevron-down"></i
                  ></span>
                </a>
              </h6>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample1"
            >
              <div class="card-body-chat">
                <div class="row">
                  <div class="col-md-12">
                    <div id="checkBoxes1">
                      <div class="form-custom">
                        <input
                          type="text"
                          class="form-control"
                          id="member_search"
                          placeholder="Search here"
                        />
                        <span
                          ><img src="@/assets/img/icons/search.svg" alt="img"
                        /></span>
                      </div>
                      <div class="selectBox-cont">
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> Brian Johnson
                        </label>
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> Russell Copeland
                        </label>
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> Greg Lynch
                        </label>
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> John Blair
                        </label>
                        <!-- View All -->
                        <div class="view-content">
                          <div class="viewall-One">
                            <label class="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span class="checkmark"></span> Barbara Moore
                            </label>
                            <label class="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span class="checkmark"></span> Hendry Evan
                            </label>
                            <label class="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span class="checkmark"></span> Richard Miles
                            </label>
                          </div>
                          <div class="view-all">
                            <a
                              href="javascript:void(0);"
                              class="viewall-button-One"
                              ><span class="me-2">View All</span
                              ><span
                                ><i class="fa fa-circle-chevron-down"></i></span
                            ></a>
                          </div>
                        </div>
                        <!-- /View All -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Customer -->

          <!-- Select Date -->
          <div class="accordion" id="accordionMain2">
            <div class="card-header-new" id="headingTwo">
              <h6 class="filter-title">
                <a
                  href="javascript:void(0);"
                  class="w-100 collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Select Date
                  <span class="float-end"
                    ><i class="fa-solid fa-chevron-down"></i
                  ></span>
                </a>
              </h6>
            </div>

            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample2"
            >
              <div class="card-body-chat">
                <div class="input-block mb-3">
                  <label class="form-control-label">From</label>
                  <div class="cal-icon">
                    <date-picker
                      v-model="startdate"
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
                <div class="input-block mb-3">
                  <label class="form-control-label">To</label>
                  <div class="cal-icon">
                    <date-picker
                      v-model="startdateOne"
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Select Date -->

          <!-- By Status -->
          <div class="accordion" id="accordionMain3">
            <div class="card-header-new" id="headingThree">
              <h6 class="filter-title">
                <a
                  href="javascript:void(0);"
                  class="w-100 collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  By Status
                  <span class="float-end"
                    ><i class="fa-solid fa-chevron-down"></i
                  ></span>
                </a>
              </h6>
            </div>

            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample3"
            >
              <div class="card-body-chat">
                <div id="checkBoxes2">
                  <div class="form-custom">
                    <input
                      type="text"
                      class="form-control"
                      id="member"
                      placeholder="Search here"
                    />
                    <span
                      ><img src="@/assets/img/icons/search.svg" alt="img"
                    /></span>
                  </div>
                  <div class="selectBox-cont">
                    <label class="custom_check w-100">
                      <input type="checkbox" name="bystatus" />
                      <span class="checkmark"></span> All Invoices
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="bystatus" />
                      <span class="checkmark"></span> Paid
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="bystatus" />
                      <span class="checkmark"></span> Overdue
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="bystatus" />
                      <span class="checkmark"></span> Draft
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="bystatus" />
                      <span class="checkmark"></span> Recurring
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="bystatus" />
                      <span class="checkmark"></span> Cancelled
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /By Status -->

          <!-- Category -->
          <div class="accordion accordion-last" id="accordionMain4">
            <div class="card-header-new" id="headingFour">
              <h6 class="filter-title">
                <a
                  href="javascript:void(0);"
                  class="w-100 collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  Category
                  <span class="float-end"
                    ><i class="fa-solid fa-chevron-down"></i
                  ></span>
                </a>
              </h6>
            </div>

            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample4"
            >
              <div class="card-body-chat">
                <div id="checkBoxes3">
                  <div class="selectBox-cont">
                    <label class="custom_check w-100">
                      <input type="checkbox" name="category" />
                      <span class="checkmark"></span> Advertising
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="category" />
                      <span class="checkmark"></span> Food
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="category" />
                      <span class="checkmark"></span> Repairs
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="category" />
                      <span class="checkmark"></span> Software
                    </label>
                    <!-- View All -->
                    <div class="view-content">
                      <div class="viewall-Two">
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> Stationary
                        </label>
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> Medical
                        </label>
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> Designing
                        </label>
                      </div>
                      <div class="view-all">
                        <a href="javascript:void(0);" class="viewall-button-Two"
                          ><span class="me-2">View All</span
                          ><span><i class="fa fa-circle-chevron-down"></i></span
                        ></a>
                      </div>
                    </div>
                    <!-- /View All -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Category -->

          <button
            type="submit"
            class="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary"
          >
            <span
              ><img
                src="@/assets/img/icons/chart.svg"
                class="me-2"
                alt="Generate report" /></span
            >Generate report
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, defineProps } from "vue";
import { t } from "@/utils/i18n";

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  text: {
    type: String,
    default: "",
  },
  path: {
    type: String,
    default: "",
  },
});

const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const startdate = ref(currentDate);
const startdateOne = ref(currentDateOne);
const dateFormat = ref("dd-MM-yyyy");

// Helper functions
const getElementsByClass = (className) => {
  return Array.from(document.getElementsByClassName(className));
};

const addClickEvent = (elements, callback) => {
  elements.forEach((el) => {
    el.addEventListener("click", callback);
  });
};

// Setup sidebar toggle functionality
const setupSidebarToggle = () => {
  if (getElementsByClass("popup-toggle").length > 0) {
    const popupToggleElements = getElementsByClass("popup-toggle");
    addClickEvent(popupToggleElements, () => {
      const toggleSidebar = getElementsByClass("toggle-sidebar")[0];
      toggleSidebar.classList.add("open-filter");
    });

    const sidebarCloseElements = getElementsByClass("sidebar-closes");
    addClickEvent(sidebarCloseElements, () => {
      const toggleSidebar = getElementsByClass("toggle-sidebar")[0];
      toggleSidebar.classList.remove("open-filter");
    });
  }
};

// Setup viewall-One functionality
const setupViewallOne = () => {
  if (getElementsByClass("viewall-One").length > 0) {
    const viewallOnes = getElementsByClass("viewall-One");
    const viewallButton = getElementsByClass("viewall-button-One")[0];

    viewallOnes.forEach((element) => {
      element.style.display = "none";
    });

    viewallButton.addEventListener("click", () => {
      const buttonText = viewallButton.textContent;
      viewallOnes.forEach((element) => {
        element.style.display =
          element.style.display === "none" ? "block" : "none";
      });

      viewallButton.textContent =
        buttonText === "Close All" ? "View All" : "Close All";
    });
  }
};

// Setup viewall-Two functionality
const setupViewallTwo = () => {
  if (getElementsByClass("viewall-Two").length > 0) {
    const viewallTwos = getElementsByClass("viewall-Two");
    const viewallButton = getElementsByClass("viewall-button-Two")[0];

    viewallTwos.forEach((element) => {
      element.style.display = "none";
    });

    viewallButton.addEventListener("click", () => {
      const buttonText = viewallButton.textContent;
      viewallTwos.forEach((element) => {
        element.style.display =
          element.style.display === "none" ? "block" : "none";
      });

      viewallButton.textContent =
        buttonText === "Close All" ? "View All" : "Close All";
    });
  }
};

// Initialize all functionality on mount
onMounted(() => {
  setupSidebarToggle();
  document.addEventListener("DOMContentLoaded", () => {
    setupViewallOne();
    setupViewallTwo();
  });
});
</script>
