<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper login-body">
    <div class="login-wrapper">
      <div class="container">
        <img
          class="img-fluid logo-dark mb-2"
          src="@/assets/img/logo1.png"
          alt="Logo"
        />

        <div class="loginbox">
          <div class="login-right">
            <div class="login-right-wrap">
              <h1>Forgot Password?</h1>
              <p class="account-subtitle">
                Enter your email to get a password reset link
              </p>

              <!-- Form -->
              <b-form>
                <div class="form-group">
                  <label class="form-control-label">Email Address</label>
                  <input class="form-control" type="text" />
                </div>
                <div class="form-group mb-0 mt-3">
                  <button
                    class="btn btn-lg btn-block btn-primary w-100"
                    type="button"
                  >
                    Reset Password
                  </button>
                </div>
              </b-form>
              <!-- /Form -->

              <div class="text-center dont-have">
                Remember your password? <router-link to="/">Login</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
