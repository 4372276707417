<script setup>
import moment from "moment";
import { defineEmits } from "vue";
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { t, setLanguage, getCurrentLanguage } from "@/utils/i18n";
import { useAuthStore } from "@/store/auth";
import { Notyf } from "notyf";

import "daterangepicker/daterangepicker.js";
import "daterangepicker/daterangepicker.css";
import DateRangePicker from "daterangepicker";

const emit = defineEmits(["dates-changed", "searchSales"]);
const notyf = new Notyf();
const currentLang = ref("en");
const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();
const dateRangeInput = ref(null);
const searchQuery = ref("");
const showPassword = ref(false);

const user = JSON.parse(localStorage.getItem("user"));
const rol = ref(user.role);
const name = ref(user.name);
const uid = ref(user.uid);
const profileUrl = ref(user.profileUrl);
const credential = ref({ password: "" });

const handleSearch = () => {
  if (searchQuery.value.trim()) {
    const value = searchQuery.value.trim();
    const attribute = detectAttribute(searchQuery.value.trim());
    emit("searchSales", { value, attribute });
  }
};

const handleSearchClear = () => {
  if (!searchQuery.value.trim()) {
    const value = "";
    const attribute = "";
    emit("searchSales", { value, attribute });
  }
};

const handleLogout = async () => {
  try {
    // Llama a tu función de logout
    await authStore.logout();

    // Redirige después del logout
    router.push("/");
  } catch (error) {
    console.error("Error durante el logout:", error);
  }
};

function toggleShow() {
  showPassword.value = !showPassword.value;
}

function detectAttribute(searchValue) {
  const patterns = {
    saleId: /^[eE][iI]\d+$/,
    name: /^[A-Za-z]+(?:\s[A-Za-z]+)*$/,
    birthDate: /^[A-Za-z]{3}\/\d{2}\/\d{4}$/,
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    phoneNumber: /^\d{10}(-\d+)?$/,
  };

  if (patterns.saleId.test(searchValue)) {
    return "saleId";
  } else if (patterns.name.test(searchValue)) {
    if (searchValue.includes(" ")) {
      return "FullName";
    } else {
      return "firstName";
    }
  } else if (patterns.birthDate.test(searchValue)) {
    return "birthDate";
  } else if (patterns.email.test(searchValue)) {
    return "email";
  } else if (patterns.phoneNumber.test(searchValue)) {
    return "phone";
  } else {
    return "no found";
  }
}

onMounted(() => {
  initializeDateRangePicker(dateRangeInput.value);
  document.addEventListener("mouseover", handleMouseOver);
});

onMounted(() => {
  // Get language from localStorage or default to 'en'
  const savedLanguage = localStorage.getItem("language") || "en";
  currentLang.value = savedLanguage;
  setLanguage(savedLanguage);
});

const mainLanguageDisplay = computed(() => ({
  flag: require(`@/assets/img/flags/${
    currentLang.value === "es" ? "es.png" : "us.png"
  }`),
  text: currentLang.value === "es" ? "Español" : "English",
}));
onBeforeUnmount(() => {
  document.removeEventListener("mouseover", handleMouseOver);
});

const toggleSidebar1 = () => {
  document.body.classList.toggle("slide-nav");
};

const toggleSidebar = () => {
  document.body.classList.toggle("mini-sidebar");
};

const initFullScreen = () => {
  document.body.classList.toggle("fullscreen-enable");
  if (
    !document.fullscreenElement &&
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement
  ) {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(
        Element.ALLOW_KEYBOARD_INPUT
      );
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
};

const handleMouseOver = (event) => {
  event.stopPropagation();

  const body = document.body;
  const toggleBtn = document.getElementById("toggle_btn");
  const subdropUL = document.getElementsByClassName("subdrop");

  if (
    body.classList.contains("mini-sidebar") &&
    toggleBtn.style.display !== "none"
  ) {
    const target = event.target.closest(".sidebar");

    if (target) {
      body.classList.add("expand-menu");
      for (let i = 0; i < subdropUL.length; i++) {
        const ul = subdropUL[i].nextElementSibling;
        if (ul) {
          ul.style.display = "block";
        }
      }
    } else {
      body.classList.remove("expand-menu");
      for (let i = 0; i < subdropUL.length; i++) {
        const ul = subdropUL[i].nextElementSibling;
        if (ul) {
          ul.style.display = "none";
        }
      }
    }

    event.preventDefault();
  }
};

//date range logic
function booking_range(start, end) {
  const formattedRange = `${start.format("MMM/D/YYYY")} - ${end.format(
    "MMM/D/YYYY"
  )}`;
  const isoDates = convertToISO(formattedRange);
  emit("dates-changed", isoDates);
}

function convertToISO(dateRange) {
  const [start, end] = dateRange.split(" - ");

  const startDate = moment(start, "MMM/D/YYYY").format("YYYY-MM-DD");
  const endDate = moment(end, "MMM/D/YYYY").format("YYYY-MM-DD");

  return {
    startDate,
    endDate,
  };
}

function initializeDateRangePicker(inputElement) {
  if (!inputElement) return;

  const start = moment().subtract(1, "month"); // One month ago
  const end = moment(); // Current date

  // Setting the date range picker
  new DateRangePicker(
    inputElement,
    {
      startDate: start,
      endDate: end,
      locale: {
        format: "MMM/D/YYYY", // Custom date format
      },
    },
    booking_range
  );
}

const isSelected = (lang) => currentLang.value === lang;

const changeLanguage = (lang) => {
  currentLang.value = lang;
  setLanguage(lang);
  localStorage.setItem("language", lang);
};

const changePassword = async (uid, password) => {
  try {
    await authStore.changesPassword(uid, password);
    notyf.success("Cambio de Contraseña completada exitosamente.");

    const btnClose = document.getElementById("btnModalClose");

    setTimeout(() => {
      btnClose.click();
    }, 500);
  } catch (error) {
    notyf.error(
      "Error al completar la reasignación. Por favor intente nuevamente."
    );
  }
};
</script>

<template>
  <div class="header header-one">
    <router-link
      to="/dashboard/"
      class="d-inline-flex d-sm-inline-flex align-items-center d-md-inline-flex d-lg-none align-items-center device-logo"
    >
      <img
        src="@/assets/img/logo.png"
        class="img-fluid logo2"
        alt="Logo Erika 1"
      />
    </router-link>
    <div
      class="main-logo d-inline float-start d-lg-flex align-items-center d-none d-sm-none d-md-none"
    >
      <div class="logo-white">
        <router-link to="/dashboard/">
          <img
            src="@/assets/img/logo-full-white.png"
            class="img-fluid logo-blue"
            alt="Logo Erika 2"
          />
        </router-link>
        <router-link to="/dashboard/">
          <img
            src="@/assets/img/logo-small-white.png"
            class="img-fluid logo-small"
            alt="Logo Erika 3"
          />
        </router-link>
      </div>
      <div class="logo-color">
        <router-link to="/dashboard/">
          <img
            src="@/assets/img/logo.jpg"
            class="img-fluid logo-blue"
            alt="Logo Erika 4"
          />
        </router-link>
        <router-link to="/dashboard/">
          <img
            src="/favicon.png"
            class="img-fluid logo-small"
            alt="Logo Erika 5"
          />
        </router-link>
      </div>
    </div>

    <!-- Sidebar Toggle -->
    <a href="javascript:void(0);" id="toggle_btn" @click="toggleSidebar">
      <span class="toggle-bars">
        <span class="bar-icons"></span>
        <span class="bar-icons"></span>
        <span class="bar-icons"></span>
        <span class="bar-icons"></span>
      </span>
    </a>
    <!-- /Sidebar Toggle -->

    <div class="top-nav-search d-flex justify-content-between gap-2">
      <!-- Filter Date Range -->
      <div
        class="daterangepicker-wrap flex-fill"
        v-show="
          route.path === '/sales/sale-list' || route.path === '/dashboard/'
        "
      >
        <input
          type="text"
          class="btn-filters w-100"
          name="datetimes"
          ref="dateRangeInput"
          placeholder="From Date - To Date"
        />
        <i class="feather feather-calendar calendar-icon"></i>
      </div>

      <!-- Search -->
      <form
        class="flex-fill"
        @submit.prevent="handleSearch"
        v-show="route.path === '/sales/sale-list'"
      >
        <input
          type="text"
          class="form-control"
          @input="handleSearchClear"
          :placeholder="t('search')"
          v-model="searchQuery"
        />
        <button class="btn" type="submit">
          <i class="feather feather-search"></i>
        </button>
      </form>
    </div>
    <!-- /Search -->

    <!-- Mobile Menu Toggle -->
    <a class="mobile_btn" id="mobile_btn" @click="toggleSidebar1">
      <i class="fas fa-bars"></i>
    </a>
    <!-- /Mobile Menu Toggle -->

    <!-- Header Menu -->
    <ul class="nav nav-tabs user-menu">
      <!-- Flag -->

      <li class="nav-item dropdown has-arrow flag-nav">
        <a
          class="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          href="javascript:;"
          role="button"
        >
          <img
            :src="mainLanguageDisplay.flag"
            :alt="mainLanguageDisplay.text"
          />
          <span>{{ mainLanguageDisplay.text }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-end">
          <a
            href="javascript:void(0);"
            class="dropdown-item"
            :class="{ active: isSelected('es') }"
            @click="changeLanguage('es')"
          >
            <img src="@/assets/img/flags/es.png" alt="Español" />
            <span>Español</span>
          </a>
          <a
            href="javascript:void(0);"
            class="dropdown-item"
            :class="{ active: isSelected('en') }"
            @click="changeLanguage('en')"
          >
            <img src="@/assets/img/flags/us.png" alt="English" />
            <span>English</span>
          </a>
        </div>
      </li>
      <!-- /Flag -->

      <li class="nav-item dropdown flag-nav dropdown-heads">
        <a
          class="nav-link"
          data-bs-toggle="dropdown"
          href="javascript:;"
          role="button"
        >
          <i
            class="feather feather-bell d-flex align-items-center justify-content-center"
          ></i>
          <span class="badge rounded-pill"></span>
        </a>
        <div class="dropdown-menu notifications">
          <div class="topnav-dropdown-header">
            <div class="notification-title">
              {{ t("notifications") }}
              <router-link to="/notifications">{{ t("viewAll") }}</router-link>
            </div>
            <a
              href="javascript:void(0)"
              class="clear-noti d-flex align-items-center"
              >{{ t("markAllAsRead") }}
              <i class="feather feather-check-circle"></i
            ></a>
          </div>
          <div class="noti-content">
            <ul class="notification-list">
              <!-- Notification items -->
            </ul>
          </div>
          <div class="topnav-dropdown-footer">
            <a href="javascript:;">{{ t("clearAll") }}</a>
          </div>
        </div>
      </li>
      <li class="nav-item has-arrow dropdown-heads">
        <a
          href="javascript:void(0);"
          class="win-maximize"
          @click="initFullScreen"
        >
          <i
            class="feather feather-maximize d-flex align-items-center justify-content-center"
          ></i>
        </a>
      </li>
      <!-- User Menu -->
      <li class="nav-item dropdown">
        <a
          href="javascript:void(0)"
          class="user-link nav-link"
          data-bs-toggle="dropdown"
        >
          <span class="user-img">
            <img :src="profileUrl" alt="img" class="profilesidebar" />
            <span class="animate-circle"></span>
          </span>
          <span class="user-content">
            <span class="user-details">{{ rol }}</span>
            <span class="user-name">{{ name }}</span>
          </span>
        </a>
        <div class="dropdown-menu menu-drop-user">
          <div class="profilemenu">
            <div class="subscription-logout">
              <ul>
                <li class="pb-0">
                  <a
                    href="#"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#changePassword"
                  >
                    {{ t("chngPassword") }}
                  </a>
                </li>
              </ul>
              <ul>
                <li class="pb-0">
                  <a class="dropdown-item" @click="handleLogout" href="#">
                    {{ t("logout") }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
      <!-- /User Menu -->
    </ul>
    <!-- /Header Menu -->
  </div>

  <div
    class="modal fade"
    id="changePassword"
    tabindex="-1"
    aria-labelledby="changePasswordlLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="changePasswordLabel">
            {{ t("sales.modal.changePassword.txtTitle") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-left p-4">
          <p class="mb-0">
            {{ t("sales.modal.changePassword.txtSubtitle") }}
          </p>

          <div class="carrier-select-container me-3 mt-3 w-100">
            <div class="pass-group" id="3">
              <div class="input-block">
                <div class="pass-group" id="passwordInput2">
                  <div class="input-block">
                    <label>{{ t("sales.users.newUsers.newpassword") }}</label>
                    <input
                      :type="showPassword ? 'text' : 'password'"
                      class="form-control pass-input"
                      placeholder=""
                      required
                      v-model="credential.password"
                    />
                    <span @click="toggleShow" class="toggle-password">
                      <i
                        :class="{
                          'fas fa-eye': showPassword,
                          'fas fa-eye-slash': !showPassword,
                        }"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pass-group" id="passwordInput2">
              <div class="input-block">
                <label>{{ t("sales.users.newUsers.confirmPassword") }}</label>
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control pass-input"
                  placeholder=""
                  required
                />
                <span @click="toggleShow" class="toggle-password">
                  <i
                    :class="{
                      'fas fa-eye': showPassword,
                      'fas fa-eye-slash': !showPassword,
                    }"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer mt-4 p-4 pt-0">
          <button
            type="button"
            class="btn btn-outline-primary2 me-3"
            data-bs-dismiss="modal"
            id="btnModalClose"
          >
            {{ t("sales.modal.reasignedProcessor.btnCancel") }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="changePassword(uid, credential.password)"
          >
            {{ t("sales.modal.reasignedProcessor.btnContinue") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <side-settings></side-settings>
</template>

<style scoped>
.btn-filters {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 4px;
  color: #878a99;
  min-width: 39px;
  height: 39px;
  background: #fff;
  border: 1px solid #ddd;
  transition: all 0.4s ease;
}

.daterangepicker-wrap input {
  width: 100%;
  padding-left: 35px;
  padding-right: 10px;
}

.daterangepicker-wrap {
  padding: 0;
  width: 45%;
  margin-top: 9px;
  position: relative;
  min-width: 248px;
}

.calendar-icon {
  position: absolute;
  top: 12px;
  left: 10px;
  color: #878a99;
  width: 20px;
}

.btn-filters:hover {
  border: 1px solid #44044b;
  transition: all 0.4s ease;
}

.btn-filters:focus {
  border: 1px solid #44044b;
  transition: all 0.4s ease;
}

.btn-filters:hover + .calendar-icon {
  color: #44044b;
}

.btn-filters:focus + .calendar-icon {
  color: #44044b;
}

[data-topbar="light"] body .header .top-nav-search form .form-control:hover {
  border: 1px solid #44044b;
  transition: all 0.4s ease;
}

[data-topbar="light"] body .header .top-nav-search form .form-control:focus {
  border: 1px solid #44044b;
  transition: all 0.4s ease;
}
</style>
