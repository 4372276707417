<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <div class="row justify-content-lg-center">
        <div class="col-lg-10">
          <div class="page-header">
            <div class="content-page-header">
              <h5>Profile</h5>
            </div>
          </div>

          <profilecontent :data="usersData" />

          <div class="row">
            <profiletable :data="usersData" />

            <profiletable1 />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
<script setup>
import { useUserStore } from "@/store/user";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { Notyf } from "notyf";

const userStore = useUserStore();
const route = useRoute();
const notyf = new Notyf();
const uid = route.params.uid;
const usersData = ref({});

const isoading = ref(true);

const userById = async () => {
  try {
    const users = await userStore.usersById(uid);
    usersData.value = users;
  } catch (error) {
    notyf.error("Error loading users");
  } finally {
    isoading.value = false;
  }
};

onMounted(userById);
</script>
