// translationService.js
import { ref } from "vue";
import translations from "@/locales/translations";

const currentLanguage = ref("en");

// Función auxiliar para acceder a propiedades anidadas
const getNestedValue = (obj, path) => {
  return path.split(".").reduce((current, key) => {
    return current && current[key] !== undefined ? current[key] : undefined;
  }, obj);
};

// Función de traducción mejorada
export const t = (key) => {
  const translation = getNestedValue(translations[currentLanguage.value], key);
  return translation || key;
};

export const setLanguage = (lang) => {
  if (translations[lang]) {
    currentLanguage.value = lang;
  } else {
    console.warn(
      `Language ${lang} not found, falling back to current language`
    );
  }
};

export const getCurrentLanguage = () => currentLanguage.value;

// Opcional: Agregar validación de idiomas disponibles
export const getAvailableLanguages = () => Object.keys(translations);

// Opcional: Verificar si una clave de traducción existe
export const hasTranslation = (key) => {
  return getNestedValue(translations[currentLanguage.value], key) !== undefined;
};
