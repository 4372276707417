<template>
  <!-- Inovices card -->
  <div class="row">
    <div class="col-xl-2 col-lg-4 col-sm-6 col-12">
      <div class="card inovices-card">
        <div class="card-body">
          <div class="dash-widget-header mb-0">
            <span class="inovices-widget-icon rounded-circle bg-info-light">
              <img src="@/assets/img/icons/receipt-item.svg" alt="" />
            </span>
            <div class="dash-count">
              <div class="dash-title">Total Tickets</div>
              <div class="dash-counts">
                <p class="mb-0">$298</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-sm-6 col-12">
      <div class="card inovices-card">
        <div class="card-body">
          <div class="dash-widget-header mb-0">
            <span class="inovices-widget-icon rounded-circle bg-primary-light">
              <img src="@/assets/img/icons/transaction-minus.svg" alt="" />
            </span>
            <div class="dash-count">
              <div class="dash-title">Completed</div>
              <div class="dash-counts">
                <p class="mb-0">$325,215</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-sm-6 col-12">
      <div class="card inovices-card">
        <div class="card-body">
          <div class="dash-widget-header mb-0">
            <span class="inovices-widget-icon rounded-circle bg-warning-light">
              <img src="@/assets/img/icons/archive-book.svg" alt="" />
            </span>
            <div class="dash-count">
              <div class="dash-title">In Progress</div>
              <div class="dash-counts">
                <p class="mb-0">$7825</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-sm-6 col-12">
      <div class="card inovices-card">
        <div class="card-body">
          <div class="dash-widget-header mb-0">
            <span class="inovices-widget-icon rounded-circle bg-primary-light">
              <img src="@/assets/img/icons/clipboard-close.svg" alt="" />
            </span>
            <div class="dash-count">
              <div class="dash-title">Unassigned</div>
              <div class="dash-counts">
                <p class="mb-0">100</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-sm-6 col-12">
      <div class="card inovices-card">
        <div class="card-body">
          <div class="dash-widget-header mb-0">
            <span class="inovices-widget-icon rounded-circle bg-green-light">
              <img src="@/assets/img/icons/message-edit.svg" alt="" />
            </span>
            <div class="dash-count">
              <div class="dash-title">New</div>
              <div class="dash-counts">
                <p class="mb-0">$125,586</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-sm-6 col-12">
      <div class="card inovices-card">
        <div class="card-body">
          <div class="dash-widget-header mb-0">
            <span class="inovices-widget-icon rounded-circle bg-danger-light">
              <img src="@/assets/img/icons/3d-rotate.svg" alt="" />
            </span>
            <div class="dash-count">
              <div class="dash-title">Hold Tickets</div>
              <div class="dash-counts">
                <p class="mb-0">$86,892</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Inovices card -->
</template>
