<template>
  <!-- Inovices card -->
  <div class="row">
    <div class="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex">
      <div class="card inovices-card w-100">
        <div class="card-body">
          <div class="dash-widget-header">
            <span class="inovices-widget-icon bg-info-light">
              <img src="@/assets/img/icons/receipt-item.svg" alt="" />
            </span>
            <div class="dash-count">
              <div class="dash-title">Total Invoice</div>
              <div class="dash-counts">
                <p>$298</p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <p class="inovices-all">
              No of Invoice <span class="rounded-circle bg-light-gray">02</span>
            </p>
            <p class="inovice-trending text-success-light">
              02 <span class="ms-2"><i class="feather feather-trending-up"></i></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex">
      <div class="card inovices-card w-100">
        <div class="card-body">
          <div class="dash-widget-header">
            <span class="inovices-widget-icon bg-primary-light">
              <img src="@/assets/img/icons/transaction-minus.svg" alt="" />
            </span>
            <div class="dash-count">
              <div class="dash-title">Outstanding</div>
              <div class="dash-counts">
                <p>$325,215</p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <p class="inovices-all">
              No of Invoice <span class="rounded-circle bg-light-gray">03</span>
            </p>
            <p class="inovice-trending text-success-light">
              04 <span class="ms-2"><i class="feather feather-trending-up"></i></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex">
      <div class="card inovices-card w-100">
        <div class="card-body">
          <div class="dash-widget-header">
            <span class="inovices-widget-icon bg-warning-light">
              <img src="@/assets/img/icons/archive-book.svg" alt="" />
            </span>
            <div class="dash-count">
              <div class="dash-title">Total Overdue</div>
              <div class="dash-counts">
                <p>$7825</p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <p class="inovices-all">
              No of Invoice <span class="rounded-circle bg-light-gray">01</span>
            </p>
            <p class="inovice-trending text-danger-light">
              03 <span class="ms-2"><i class="feather feather-trending-down"></i></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex">
      <div class="card inovices-card w-100">
        <div class="card-body">
          <div class="dash-widget-header">
            <span class="inovices-widget-icon bg-primary-light">
              <img src="@/assets/img/icons/clipboard-close.svg" alt="" />
            </span>
            <div class="dash-count">
              <div class="dash-title">Cancelled</div>
              <div class="dash-counts">
                <p>100</p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <p class="inovices-all">
              No of Invoice <span class="rounded-circle bg-light-gray">04</span>
            </p>
            <p class="inovice-trending text-danger-light">
              05 <span class="ms-2"><i class="feather feather-trending-down"></i></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex">
      <div class="card inovices-card w-100">
        <div class="card-body">
          <div class="dash-widget-header">
            <span class="inovices-widget-icon bg-green-light">
              <img src="@/assets/img/icons/message-edit.svg" alt="" />
            </span>
            <div class="dash-count">
              <div class="dash-title">Draft</div>
              <div class="dash-counts">
                <p>$125,586</p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <p class="inovices-all">
              No of Invoice <span class="rounded-circle bg-light-gray">06</span>
            </p>
            <p class="inovice-trending text-danger-light">
              02 <span class="ms-2"><i class="feather feather-trending-down"></i></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex">
      <div class="card inovices-card w-100">
        <div class="card-body">
          <div class="dash-widget-header">
            <span class="inovices-widget-icon bg-danger-light">
              <img src="@/assets/img/icons/3d-rotate.svg" alt="" />
            </span>
            <div class="dash-count">
              <div class="dash-title">Recurring</div>
              <div class="dash-counts">
                <p>$86,892</p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <p class="inovices-all">
              No of Invoice <span class="rounded-circle bg-light-gray">03</span>
            </p>
            <p class="inovice-trending text-success-light">
              02 <span class="ms-2"><i class="feather feather-trending-up"></i></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Inovices card -->
</template>
