// Function to sort sales by creation date in descending order
export function sortSalesByDate(sales) {
  return sales.sort((a, b) => {
    const dateA = a.createdAt._seconds * 1000 + a.createdAt._nanoseconds / 1e6;
    const dateB = b.createdAt._seconds * 1000 + b.createdAt._nanoseconds / 1e6;
    return dateB - dateA;
  });
}

// Function to classify sales by status
export function classifySalesByStatus(sortedSales) {
  return sortedSales.reduce((acc, sale) => {
    if (!acc[sale.status]) {
      acc[sale.status] = [];
    }
    acc[sale.status].push(sale);
    return acc;
  }, {});
}