<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <div class="row">
        <!-- Chart -->
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Ionic Icon</h5>
            </div>
            <div class="card-body">
              <div class="icons-items">
                <ul class="icons-list">
                  <li>
                    <i class="ion-ionic" data-bs-toggle="tooltip" title="ion-ionic"></i>
                  </li>
                  <!-- <li>
                      <i
                        ionicons="ion-arrow-up-a"
                        data-bs-toggle="tooltip"
                        title="ion-arrow-up-a"
                      ></i>
                    </li> -->
                  <li>
                    <i class="ion-arrow-right-a" data-bs-toggle="tooltip" title="ion-arrow-right-a"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-down-a" data-bs-toggle="tooltip" title="ion-arrow-down-a"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-left-a" data-bs-toggle="tooltip" title="ion-arrow-left-a"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-up-b" data-bs-toggle="tooltip" title="ion-arrow-up-b"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-right-b" data-bs-toggle="tooltip" title="ion-arrow-right-b"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-down-b" data-bs-toggle="tooltip" title="ion-arrow-down-b"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-left-b" data-bs-toggle="tooltip" title="ion-arrow-left-b"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-up-c" data-bs-toggle="tooltip" title="ion-arrow-up-c"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-right-c" data-bs-toggle="tooltip" title="ion-arrow-right-c"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-down-c" data-bs-toggle="tooltip" title="ion-arrow-down-c"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-left-c" data-bs-toggle="tooltip" title="ion-arrow-left-c"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-return-right" data-bs-toggle="tooltip" title="ion-arrow-return-right"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-return-left" data-bs-toggle="tooltip" title="ion-arrow-return-left"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-swap" data-bs-toggle="tooltip" title="ion-arrow-swap"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-shrink" data-bs-toggle="tooltip" title="ion-arrow-shrink"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-expand" data-bs-toggle="tooltip" title="ion-arrow-expand"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-move" data-bs-toggle="tooltip" title="ion-arrow-move"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-resize" data-bs-toggle="tooltip" title="ion-arrow-resize"></i>
                  </li>
                  <li>
                    <i class="ion-chevron-up" data-bs-toggle="tooltip" title="ion-chevron-up"></i>
                  </li>
                  <li>
                    <i class="ion-chevron-right" data-bs-toggle="tooltip" title="ion-chevron-right"></i>
                  </li>
                  <li>
                    <i class="ion-chevron-down" data-bs-toggle="tooltip" title="ion-chevron-down"></i>
                  </li>
                  <li>
                    <i class="ion-chevron-left" data-bs-toggle="tooltip" title="ion-chevron-left"></i>
                  </li>
                  <li>
                    <i class="ion-navicon-round" data-bs-toggle="tooltip" title="ion-navicon-round"></i>
                  </li>
                  <li>
                    <i class="ion-navicon" data-bs-toggle="tooltip" title="ion-navicon"></i>
                  </li>
                  <li>
                    <i class="ion-drag" data-bs-toggle="tooltip" title="ion-drag"></i>
                  </li>
                  <li>
                    <i class="ion-log-in" data-bs-toggle="tooltip" title="ion-log-in"></i>
                  </li>
                  <li>
                    <i class="ion-log-out" data-bs-toggle="tooltip" title="ion-log-out"></i>
                  </li>
                  <li>
                    <i class="ion-checkmark-round" data-bs-toggle="tooltip" title="ion-checkmark-round"></i>
                  </li>
                  <li>
                    <i class="ion-checkmark" data-bs-toggle="tooltip" title="ion-checkmark"></i>
                  </li>
                  <li>
                    <i class="ion-checkmark-circled" data-bs-toggle="tooltip" title="ion-checkmark-circled"></i>
                  </li>
                  <li>
                    <i class="ion-close-round" data-bs-toggle="tooltip" title="ion-close-round"></i>
                  </li>
                  <li>
                    <i class="ion-close" data-bs-toggle="tooltip" title="ion-close"></i>
                  </li>
                  <li>
                    <i class="ion-close-circled" data-bs-toggle="tooltip" title="ion-close-circled"></i>
                  </li>
                  <li>
                    <i class="ion-plus-round" data-bs-toggle="tooltip" title="ion-plus-round"></i>
                  </li>
                  <li>
                    <i class="ion-plus" data-bs-toggle="tooltip" title="ion-plus"></i>
                  </li>
                  <li>
                    <i class="ion-plus-circled" data-bs-toggle="tooltip" title="ion-plus-circled"></i>
                  </li>
                  <li>
                    <i class="ion-minus-round" data-bs-toggle="tooltip" title="ion-minus-round"></i>
                  </li>
                  <li>
                    <i class="ion-minus" data-bs-toggle="tooltip" title="ion-minus"></i>
                  </li>
                  <li>
                    <i class="ion-minus-circled" data-bs-toggle="tooltip" title="ion-minus-circled"></i>
                  </li>
                  <li>
                    <i class="ion-information" data-bs-toggle="tooltip" title="ion-information"></i>
                  </li>
                  <li>
                    <i class="ion-information-circled" data-bs-toggle="tooltip" title="ion-information-circled"></i>
                  </li>
                  <li>
                    <i class="ion-help" data-bs-toggle="tooltip" title="ion-help"></i>
                  </li>
                  <li>
                    <i class="ion-help-circled" data-bs-toggle="tooltip" title="ion-help-circled"></i>
                  </li>
                  <li>
                    <i class="ion-help-buoy" data-bs-toggle="tooltip" title="ion-help-buoy"></i>
                  </li>
                  <li>
                    <i class="ion-asterisk" data-bs-toggle="tooltip" title="ion-asterisk"></i>
                  </li>
                  <li>
                    <i class="ion-alert" data-bs-toggle="tooltip" title="ion-alert"></i>
                  </li>
                  <li>
                    <i class="ion-alert-circled" data-bs-toggle="tooltip" title="ion-alert-circled"></i>
                  </li>
                  <li>
                    <i class="ion-refresh" data-bs-toggle="tooltip" title="ion-refresh"></i>
                  </li>
                  <li>
                    <i class="ion-loop" data-bs-toggle="tooltip" title="ion-loop"></i>
                  </li>
                  <li>
                    <i class="ion-shuffle" data-bs-toggle="tooltip" title="ion-shuffle"></i>
                  </li>
                  <li>
                    <i class="ion-home" data-bs-toggle="tooltip" title="ion-home"></i>
                  </li>
                  <li>
                    <i class="ion-search" data-bs-toggle="tooltip" title="ion-search"></i>
                  </li>
                  <li>
                    <i class="ion-flag" data-bs-toggle="tooltip" title="ion-flag"></i>
                  </li>
                  <li>
                    <i class="ion-star" data-bs-toggle="tooltip" title="ion-star"></i>
                  </li>
                  <li>
                    <i class="ion-heart" data-bs-toggle="tooltip" title="ion-heart"></i>
                  </li>
                  <li>
                    <i class="ion-heart-broken" data-bs-toggle="tooltip" title="ion-heart-broken"></i>
                  </li>
                  <li>
                    <i class="ion-gear-a" data-bs-toggle="tooltip" title="ion-gear-a"></i>
                  </li>
                  <li>
                    <i class="ion-gear-b" data-bs-toggle="tooltip" title="ion-gear-b"></i>
                  </li>
                  <li>
                    <i class="ion-toggle-filled" data-bs-toggle="tooltip" title="ion-toggle-filled"></i>
                  </li>
                  <li>
                    <i class="ion-toggle" data-bs-toggle="tooltip" title="ion-toggle"></i>
                  </li>
                  <li>
                    <i class="ion-settings" data-bs-toggle="tooltip" title="ion-settings"></i>
                  </li>
                  <li>
                    <i class="ion-wrench" data-bs-toggle="tooltip" title="ion-wrench"></i>
                  </li>
                  <li>
                    <i class="ion-folder" data-bs-toggle="tooltip" title="ion-folder"></i>
                  </li>
                  <li>
                    <i class="ion-hammer" data-bs-toggle="tooltip" title="ion-hammer"></i>
                  </li>
                  <li>
                    <i class="ion-edit" data-bs-toggle="tooltip" title="ion-edit"></i>
                  </li>
                  <li>
                    <i class="ion-trash-a" data-bs-toggle="tooltip" title="ion-trash-a"></i>
                  </li>
                  <li>
                    <i class="ion-trash-b" data-bs-toggle="tooltip" title="ion-trash-b"></i>
                  </li>
                  <li>
                    <i class="ion-document" data-bs-toggle="tooltip" title="ion-document"></i>
                  </li>
                  <li>
                    <i class="ion-document-text" data-bs-toggle="tooltip" title="ion-document-text"></i>
                  </li>
                  <li>
                    <i class="ion-clipboard" data-bs-toggle="tooltip" title="ion-clipboard"></i>
                  </li>
                  <li>
                    <i class="ion-scissors" data-bs-toggle="tooltip" title="ion-scissors"></i>
                  </li>
                  <li>
                    <i class="ion-funnel" data-bs-toggle="tooltip" title="ion-funnel"></i>
                  </li>
                  <li>
                    <i class="ion-bookmark" data-bs-toggle="tooltip" title="ion-bookmark"></i>
                  </li>
                  <li>
                    <i class="ion-email" data-bs-toggle="tooltip" title="ion-email"></i>
                  </li>
                  <li>
                    <i class="ion-folder" data-bs-toggle="tooltip" title="ion-folder"></i>
                  </li>
                  <li>
                    <i class="ion-filing" data-bs-toggle="tooltip" title="ion-filing"></i>
                  </li>
                  <li>
                    <i class="ion-archive" data-bs-toggle="tooltip" title="ion-archive"></i>
                  </li>
                  <li>
                    <i class="ion-reply" data-bs-toggle="tooltip" title="ion-reply"></i>
                  </li>
                  <li>
                    <i class="ion-reply-all" data-bs-toggle="tooltip" title="ion-reply-all"></i>
                  </li>
                  <li>
                    <i class="ion-forward" data-bs-toggle="tooltip" title="ion-forward"></i>
                  </li>
                  <li>
                    <i class="ion-share" data-bs-toggle="tooltip" title="ion-share"></i>
                  </li>
                  <li>
                    <i class="ion-paper-airplane" data-bs-toggle="tooltip" title="ion-paper-airplane"></i>
                  </li>
                  <li>
                    <i class="ion-link" data-bs-toggle="tooltip" title="ion-link"></i>
                  </li>
                  <li>
                    <i class="ion-paperclip" data-bs-toggle="tooltip" title="ion-paperclip"></i>
                  </li>
                  <li>
                    <i class="ion-compose" data-bs-toggle="tooltip" title="ion-compose"></i>
                  </li>
                  <li>
                    <i class="ion-briefcase" data-bs-toggle="tooltip" title="ion-briefcase"></i>
                  </li>
                  <li>
                    <i class="ion-medkit" data-bs-toggle="tooltip" title="ion-medkit"></i>
                  </li>
                  <li>
                    <i class="ion-at" data-bs-toggle="tooltip" title="ion-at"></i>
                  </li>
                  <li>
                    <i class="ion-pound" data-bs-toggle="tooltip" title="ion-pound"></i>
                  </li>
                  <li>
                    <i class="ion-quote" data-bs-toggle="tooltip" title="ion-quote"></i>
                  </li>
                  <li>
                    <i class="ion-cloud" data-bs-toggle="tooltip" title="ion-cloud"></i>
                  </li>
                  <li>
                    <i class="ion-upload" data-bs-toggle="tooltip" title="ion-upload"></i>
                  </li>
                  <li>
                    <i class="ion-more" data-bs-toggle="tooltip" title="ion-more"></i>
                  </li>
                  <li>
                    <i class="ion-grid" data-bs-toggle="tooltip" title="ion-grid"></i>
                  </li>
                  <li>
                    <i class="ion-calendar" data-bs-toggle="tooltip" title="ion-calendar"></i>
                  </li>
                  <li>
                    <i class="ion-clock" data-bs-toggle="tooltip" title="ion-clock"></i>
                  </li>
                  <li>
                    <i class="ion-compass" data-bs-toggle="tooltip" title="ion-compass"></i>
                  </li>
                  <li>
                    <i class="ion-pinpoint" data-bs-toggle="tooltip" title="ion-pinpoint"></i>
                  </li>
                  <li>
                    <i class="ion-pin" data-bs-toggle="tooltip" title="ion-pin"></i>
                  </li>
                  <li>
                    <i class="ion-navigate" data-bs-toggle="tooltip" title="ion-navigate"></i>
                  </li>
                  <li>
                    <i class="ion-location" data-bs-toggle="tooltip" title="ion-location"></i>
                  </li>
                  <li>
                    <i class="ion-map" data-bs-toggle="tooltip" title="ion-map"></i>
                  </li>
                  <li>
                    <i class="ion-model-s" data-bs-toggle="tooltip" title="ion-model-s"></i>
                  </li>
                  <li>
                    <i class="ion-locked" data-bs-toggle="tooltip" title="ion-locked"></i>
                  </li>
                  <li>
                    <i class="ion-unlocked" data-bs-toggle="tooltip" title="ion-unlocked"></i>
                  </li>
                  <li>
                    <i class="ion-key" data-bs-toggle="tooltip" title="ion-key"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-graph-up-right" data-bs-toggle="tooltip" title="ion-arrow-graph-up-right"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-graph-down-right" data-bs-toggle="tooltip" title="ion-arrow-graph-down-right"></i>
                  </li>
                  <li>
                    <i class="ion-arrow-graph-down-left" data-bs-toggle="tooltip" title="ion-arrow-graph-down-left"></i>
                  </li>
                  <li>
                    <i class="ion-stats-bars" data-bs-toggle="tooltip" title="ion-stats-bars"></i>
                  </li>
                  <li>
                    <i class="ion-connection-bars" data-bs-toggle="tooltip" title="ion-connection-bars"></i>
                  </li>
                  <li>
                    <i class="ion-pie-graph" data-bs-toggle="tooltip" title="ion-pie-graph"></i>
                  </li>
                  <li>
                    <i class="ion-chatbubble" data-bs-toggle="tooltip" title="ion-chatbubble"></i>
                  </li>
                  <li>
                    <i class="ion-chatbubble-working" data-bs-toggle="tooltip" title="ion-chatbubble-working"></i>
                  </li>
                  <li>
                    <i class="ion-chatbubbles" data-bs-toggle="tooltip" title="ion-chatbubbles"></i>
                  </li>
                  <li>
                    <i class="ion-chatbox" data-bs-toggle="tooltip" title="ion-chatbox"></i>
                  </li>
                  <li>
                    <i class="ion-chatbox-working" data-bs-toggle="tooltip" title="ion-chatbox-working"></i>
                  </li>
                  <li>
                    <i class="ion-chatboxes" data-bs-toggle="tooltip" title="ion-chatboxes"></i>
                  </li>
                  <li>
                    <i class="ion-person" data-bs-toggle="tooltip" title="ion-person"></i>
                  </li>
                  <li>
                    <i class="ion-person-add" data-bs-toggle="tooltip" title="ion-person-add"></i>
                  </li>
                  <li>
                    <i class="ion-person-stalker" data-bs-toggle="tooltip" title="ion-person-stalker"></i>
                  </li>
                  <li>
                    <i class="ion-woman" data-bs-toggle="tooltip" title="ion-woman"></i>
                  </li>
                  <li>
                    <i class="ion-man" data-bs-toggle="tooltip" title="ion-man"></i>
                  </li>
                  <li>
                    <i class="ion-female" data-bs-toggle="tooltip" title="ion-female"></i>
                  </li>
                  <li>
                    <i class="ion-male" data-bs-toggle="tooltip" title="ion-male"></i>
                  </li>
                  <li>
                    <i class="ion-fork" data-bs-toggle="tooltip" title="ion-fork"></i>
                  </li>
                  <li>
                    <i class="ion-knife" data-bs-toggle="tooltip" title="ion-knife"></i>
                  </li>
                  <li>
                    <i class="ion-spoon" data-bs-toggle="tooltip" title="ion-spoon"></i>
                  </li>
                  <li>
                    <i class="ion-beer" data-bs-toggle="tooltip" title="ion-beer"></i>
                  </li>
                  <li>
                    <i class="ion-wineglass" data-bs-toggle="tooltip" title="ion-wineglass"></i>
                  </li>
                  <li>
                    <i class="ion-coffee" data-bs-toggle="tooltip" title="ion-coffee"></i>
                  </li>
                  <li>
                    <i class="ion-icecream" data-bs-toggle="tooltip" title="ion-icecream"></i>
                  </li>
                  <li>
                    <i class="ion-pizza" data-bs-toggle="tooltip" title="ion-pizza"></i>
                  </li>
                  <li>
                    <i class="ion-power" data-bs-toggle="tooltip" title="ion-power"></i>
                  </li>
                  <li>
                    <i class="ion-mouse" data-bs-toggle="tooltip" title="ion-mouse"></i>
                  </li>
                  <li>
                    <i class="ion-battery-full" data-bs-toggle="tooltip" title="ion-battery-full"></i>
                  </li>
                  <li>
                    <i class="ion-battery-half" data-bs-toggle="tooltip" title="ion-battery-half"></i>
                  </li>
                  <li>
                    <i class="ion-battery-low" data-bs-toggle="tooltip" title="ion-battery-low"></i>
                  </li>
                  <li>
                    <i class="ion-battery-empty" data-bs-toggle="tooltip" title="ion-battery-empty"></i>
                  </li>
                  <li>
                    <i class="ion-battery-charging" data-bs-toggle="tooltip" title="ion-battery-charging"></i>
                  </li>
                  <li>
                    <i class="ion-bluetooth" data-bs-toggle="tooltip" title="ion-bluetooth"></i>
                  </li>
                  <li>
                    <i class="ion-calculator" data-bs-toggle="tooltip" title="ion-calculator"></i>
                  </li>
                  <li>
                    <i class="ion-camera" data-bs-toggle="tooltip" title="ion-camera"></i>
                  </li>
                  <li>
                    <i class="ion-eye" data-bs-toggle="tooltip" title="ion-eye"></i>
                  </li>
                  <li>
                    <i class="ion-eye-disabled" data-bs-toggle="tooltip" title="ion-eye-disabled"></i>
                  </li>
                  <li>
                    <i class="ion-flash" data-bs-toggle="tooltip" title="ion-flash"></i>
                  </li>
                  <li>
                    <i class="ion-flash-off" data-bs-toggle="tooltip" title="ion-flash-off"></i>
                  </li>
                  <li>
                    <i class="ion-qr-scanner" data-bs-toggle="tooltip" title="ion-qr-scanner"></i>
                  </li>
                  <li>
                    <i class="ion-image" data-bs-toggle="tooltip" title="ion-image"></i>
                  </li>
                  <li>
                    <i class="ion-images" data-bs-toggle="tooltip" title="ion-images"></i>
                  </li>
                  <li>
                    <i class="ion-contrast" data-bs-toggle="tooltip" title="ion-contrast"></i>
                  </li>
                  <li>
                    <i class="ion-wand" data-bs-toggle="tooltip" title="ion-wand"></i>
                  </li>
                  <li>
                    <i class="ion-aperture" data-bs-toggle="tooltip" title="ion-aperture"></i>
                  </li>
                  <li>
                    <i class="ion-monitor" data-bs-toggle="tooltip" title="ion-monitor"></i>
                  </li>
                  <li>
                    <i class="ion-laptop" data-bs-toggle="tooltip" title="ion-laptop"></i>
                  </li>
                  <li>
                    <i class="ion-ipad" data-bs-toggle="tooltip" title="ion-ipad"></i>
                  </li>
                  <li>
                    <i class="ion-iphone" data-bs-toggle="tooltip" title="ion-iphone"></i>
                  </li>
                  <li>
                    <i class="ion-ipod" data-bs-toggle="tooltip" title="ion-ipod"></i>
                  </li>
                  <li>
                    <i class="ion-printer" data-bs-toggle="tooltip" title="ion-printer"></i>
                  </li>
                  <li>
                    <i class="ion-usb" data-bs-toggle="tooltip" title="ion-usb"></i>
                  </li>
                  <li>
                    <i class="ion-outlet" data-bs-toggle="tooltip" title="ion-outlet"></i>
                  </li>
                  <li>
                    <i class="ion-bug" data-bs-toggle="tooltip" title="ion-bug"></i>
                  </li>
                  <li>
                    <i class="ion-code" data-bs-toggle="tooltip" title="ion-code"></i>
                  </li>
                  <li>
                    <i class="ion-code-working" data-bs-toggle="tooltip" title="ion-code-working"></i>
                  </li>
                  <li>
                    <i class="ion-code-download" data-bs-toggle="tooltip" title="ion-code-download"></i>
                  </li>
                  <li>
                    <i class="ion-fork-repo" data-bs-toggle="tooltip" title="ion-fork-repo"></i>
                  </li>
                  <li>
                    <i class="ion-network" data-bs-toggle="tooltip" title="ion-network"></i>
                  </li>
                  <li>
                    <i class="ion-pull-request" data-bs-toggle="tooltip" title="ion-pull-request"></i>
                  </li>
                  <li>
                    <i class="ion-merge" data-bs-toggle="tooltip" title="ion-merge"></i>
                  </li>
                  <!-- <li>
                      <i
                        class="ion-game-controller-a"
                        data-bs-toggle="tooltip"
                        title="ion-game-controller-a"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-game-controller-b"
                        data-bs-toggle="tooltip"
                        title="ion-game-controller-b"
                      ></i>
                    </li> -->
                  <li>
                    <i class="ion-xbox" data-bs-toggle="tooltip" title="ion-xbox"></i>
                  </li>
                  <li>
                    <i class="ion-playstation" data-bs-toggle="tooltip" title="ion-playstation"></i>
                  </li>
                  <li>
                    <i class="ion-steam" data-bs-toggle="tooltip" title="ion-steam"></i>
                  </li>
                  <li>
                    <i class="ion-closed-captioning" data-bs-toggle="tooltip" title="ion-closed-captioning"></i>
                  </li>
                  <li>
                    <i class="ion-videocamera" data-bs-toggle="tooltip" title="ion-videocamera"></i>
                  </li>
                  <li>
                    <i class="ion-film-marker" data-bs-toggle="tooltip" title="ion-film-marker"></i>
                  </li>
                  <li>
                    <i class="ion-disc" data-bs-toggle="tooltip" title="ion-disc"></i>
                  </li>
                  <li>
                    <i class="ion-headphone" data-bs-toggle="tooltip" title="ion-headphone"></i>
                  </li>
                  <li>
                    <i class="ion-music-note" data-bs-toggle="tooltip" title="ion-music-note"></i>
                  </li>
                  <li>
                    <i class="ion-radio-waves" data-bs-toggle="tooltip" title="ion-radio-waves"></i>
                  </li>
                  <li>
                    <i class="ion-speakerphone" data-bs-toggle="tooltip" title="ion-speakerphone"></i>
                  </li>
                  <li>
                    <i class="ion-mic-a" data-bs-toggle="tooltip" title="ion-mic-a"></i>
                  </li>
                  <li>
                    <i class="ion-mic-b" data-bs-toggle="tooltip" title="ion-mic-b"></i>
                  </li>
                  <li>
                    <i class="ion-mic-c" data-bs-toggle="tooltip" title="ion-mic-c"></i>
                  </li>
                  <li>
                    <i class="ion-volume-high" data-bs-toggle="tooltip" title="ion-volume-high"></i>
                  </li>
                  <li>
                    <i class="ion-volume-medium" data-bs-toggle="tooltip" title="ion-volume-medium"></i>
                  </li>
                  <li>
                    <i class="ion-volume-low" data-bs-toggle="tooltip" title="ion-volume-low"></i>
                  </li>
                  <li>
                    <i class="ion-volume-mute" data-bs-toggle="tooltip" title="ion-volume-mute"></i>
                  </li>
                  <li>
                    <i class="ion-levels" data-bs-toggle="tooltip" title="ion-levels"></i>
                  </li>
                  <li>
                    <i class="ion-play" data-bs-toggle="tooltip" title="ion-play"></i>
                  </li>
                  <li>
                    <i class="ion-pause" data-bs-toggle="tooltip" title="ion-pause"></i>
                  </li>
                  <li>
                    <i class="ion-stop" data-bs-toggle="tooltip" title="ion-stop"></i>
                  </li>
                  <li>
                    <i class="ion-record" data-bs-toggle="tooltip" title="ion-record"></i>
                  </li>
                  <li>
                    <i class="ion-skip-forward" data-bs-toggle="tooltip" title="ion-skip-forward"></i>
                  </li>
                  <li>
                    <i class="ion-skip-backward" data-bs-toggle="tooltip" title="ion-skip-backward"></i>
                  </li>
                  <li>
                    <i class="ion-eject" data-bs-toggle="tooltip" title="ion-eject"></i>
                  </li>
                  <li>
                    <i class="ion-bag" data-bs-toggle="tooltip" title="ion-bag"></i>
                  </li>
                  <li>
                    <i class="ion-card" data-bs-toggle="tooltip" title="ion-card"></i>
                  </li>
                  <li>
                    <i class="ion-cash" data-bs-toggle="tooltip" title="ion-cash"></i>
                  </li>
                  <li>
                    <i class="ion-pricetag" data-bs-toggle="tooltip" title="ion-pricetag"></i>
                  </li>
                  <li>
                    <i class="ion-pricetags" data-bs-toggle="tooltip" title="ion-pricetags"></i>
                  </li>
                  <li>
                    <i class="ion-thumbsup" data-bs-toggle="tooltip" title="ion-thumbsup"></i>
                  </li>
                  <li>
                    <i class="ion-thumbsdown" data-bs-toggle="tooltip" title="ion-thumbsdown"></i>
                  </li>
                  <li>
                    <i class="ion-happy" data-bs-toggle="tooltip" title="ion-happy"></i>
                  </li>
                  <li>
                    <i class="ion-sad" data-bs-toggle="tooltip" title="ion-sad"></i>
                  </li>
                  <li>
                    <i class="ion-trophy" data-bs-toggle="tooltip" title="ion-trophy"></i>
                  </li>
                  <li>
                    <i class="ion-podium" data-bs-toggle="tooltip" title="ion-podium"></i>
                  </li>
                  <li>
                    <i class="ion-ribbon-a" data-bs-toggle="tooltip" title="ion-ribbon-a"></i>
                  </li>
                  <li>
                    <i class="ion-ribbon-b" data-bs-toggle="tooltip" title="ion-ribbon-b"></i>
                  </li>
                  <li>
                    <i class="ion-university" data-bs-toggle="tooltip" title="ion-university"></i>
                  </li>
                  <li>
                    <i class="ion-magnet" data-bs-toggle="tooltip" title="ion-magnet"></i>
                  </li>
                  <li>
                    <i class="ion-beaker" data-bs-toggle="tooltip" title="ion-beaker"></i>
                  </li>
                  <!-- <li>
                      <i class="ion-flask" data-bs-toggle="tooltip" title="ion-flask"></i>
                    </li> -->
                  <li>
                    <i class="ion-egg" data-bs-toggle="tooltip" title="ion-egg"></i>
                  </li>
                  <li>
                    <i class="ion-earth" data-bs-toggle="tooltip" title="ion-earth"></i>
                  </li>
                  <li>
                    <i class="ion-planet" data-bs-toggle="tooltip" title="ion-planet"></i>
                  </li>
                  <li>
                    <i class="ion-lightbulb" data-bs-toggle="tooltip" title="ion-lightbulb"></i>
                  </li>
                  <li>
                    <i class="ion-cube" data-bs-toggle="tooltip" title="ion-cube"></i>
                  </li>
                  <li>
                    <i class="ion-leaf" data-bs-toggle="tooltip" title="ion-leaf"></i>
                  </li>
                  <li>
                    <i class="ion-waterdrop" data-bs-toggle="tooltip" title="ion-waterdrop"></i>
                  </li>
                  <li>
                    <i class="ion-flame" data-bs-toggle="tooltip" title="ion-flame"></i>
                  </li>
                  <li>
                    <i class="ion-fireball" data-bs-toggle="tooltip" title="ion-fireball"></i>
                  </li>
                  <li>
                    <i class="ion-bonfire" data-bs-toggle="tooltip" title="ion-bonfire"></i>
                  </li>
                  <li>
                    <i class="ion-umbrella" data-bs-toggle="tooltip" title="ion-umbrella"></i>
                  </li>
                  <li>
                    <i class="ion-nuclear" data-bs-toggle="tooltip" title="ion-nuclear"></i>
                  </li>
                  <li>
                    <i class="ion-no-smoking" data-bs-toggle="tooltip" title="ion-no-smoking"></i>
                  </li>
                  <li>
                    <i class="ion-thermometer" data-bs-toggle="tooltip" title="ion-thermometer"></i>
                  </li>
                  <li>
                    <i class="ion-speedometer" data-bs-toggle="tooltip" title="ion-speedometer"></i>
                  </li>
                  <li>
                    <i class="ion-plane" data-bs-toggle="tooltip" title="ion-plane"></i>
                  </li>
                  <li>
                    <i class="ion-jet" data-bs-toggle="tooltip" title="ion-jet"></i>
                  </li>
                  <li>
                    <i class="ion-load-a" data-bs-toggle="tooltip" title="ion-load-a"></i>
                  </li>
                  <li>
                    <i class="ion-load-b" data-bs-toggle="tooltip" title="ion-load-b"></i>
                  </li>
                  <li>
                    <i class="ion-load-c" data-bs-toggle="tooltip" title="ion-load-c"></i>
                  </li>
                  <li>
                    <i class="ion-load-d" data-bs-toggle="tooltip" title="ion-load-d"></i>
                  </li>
                  <!--<li>
                      <i
                        class="ion-ios7-ionic-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-ionic-outline"
                      ></i>
                    </li>
                     <li>
                      <i
                        class="ion-ios7-arrow-back"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-arrow-back"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-arrow-forward"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-arrow-forward"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-arrow-up"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-arrow-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-arrow-right"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-arrow-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-arrow-down"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-arrow-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-arrow-left"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-arrow-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-arrow-thin-up"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-arrow-thin-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-arrow-thin-right"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-arrow-thin-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-arrow-thin-down"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-arrow-thin-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-arrow-thin-left"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-arrow-thin-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-circle-filled"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-circle-filled"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-circle-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-circle-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-checkmark-empty"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-checkmark-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-checkmark-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-checkmark-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-checkmark"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-checkmark"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-plus-empty"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-plus-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-plus-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-plus-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-plus"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-plus"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-close-empty"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-close-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-close-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-close-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-close"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-close"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-minus-empty"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-minus-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-minus-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-minus-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-minus"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-minus"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-information-empty"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-information-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-information-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-information-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-information"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-information"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-help-empty"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-help-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-help-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-help-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-help"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-help"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-search"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-search"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-search-strong"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-search-strong"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-star"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-star"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-star-half"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-star-half"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-star-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-star-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-heart"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-heart"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-heart-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-heart-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-more"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-more"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-more-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-more-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-home"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-home"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-home-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-home-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-cloud"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-cloud"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-cloud-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-cloud-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-cloud-upload"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-cloud-upload"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-cloud-upload-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-cloud-upload-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-cloud-download"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-cloud-download"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-cloud-download-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-cloud-download-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-upload"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-upload"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-upload-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-upload-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-download"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-download"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-refresh"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-refresh"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-refresh-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-refresh-outline"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-refresh-empty"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-refresh-empty"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-reload"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-reload"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-loop-strong"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-loop-strong"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-loop"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-loop"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-bookmarks"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-bookmarks"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="ion-ios7-bookmarks-outline"
                        data-bs-toggle="tooltip"
                        title="ion-ios7-bookmarks-outline"
                      ></i>
                    </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Chart -->
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
<script>
export default {
  data() {
    return {
      title: "Ionic Icon",
    };
  },
};
</script>
