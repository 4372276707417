<script setup>
import { ref, computed, defineProps, defineEmits } from "vue";
import { maskCardNumber } from "@/utils/";
import { t } from "@/utils/i18n";

const emit = defineEmits(["updateSaleData"]);

const props = defineProps({
  saleData: {
    type: Object,
    default: () => ({}),
  },
});

const payment = computed(() => {
  if (props.saleData && props.saleData.payment) {
    return props.saleData.payment;
  }
  return {};
});

const cardNumber = ref(payment.value.cardNumber || "");
const expirationDate = ref(payment.value.expirationDate || "");
const cvv = ref(payment.value.cvv || "");
const cardholderName = ref(payment.value.cardholderName || "");

const isFocused = ref(false);
const isCvvFocused = ref(false);

const displayValue = computed(() => {
  if (isFocused.value) {
    return cardNumber.value;
  }
  return maskCardNumber(cardNumber.value);
});

function onInput(event) {
  cardNumber.value = event.target.value
    .replace(/\D/g, "")
    .replace(/(.{4})/g, "$1 ")
    .trim();
  updatePayment("cardNumber", cardNumber.value);
}

const displayCvv = computed(() => {
  if (isCvvFocused.value) {
    return cvv.value;
  }
  return cvv.value ? "*".repeat(cvv.value.length) : "";
});

const displayExpDate = computed(() => {
  if (
    expirationDate.value.length === 2 &&
    !expirationDate.value.includes("/")
  ) {
    return expirationDate.value + "/";
  }

  return expirationDate.value;
});

function onCvvInput(event) {
  cvv.value = event.target.value;
  updatePayment("cvv", cvv.value);
}

function onFocus() {
  isFocused.value = true;
}

function onBlur() {
  isFocused.value = false;
}

function onCvvFocus() {
  isCvvFocused.value = true;
}

function onCvvBlur() {
  isCvvFocused.value = false;
}

function onInputExpDate(event) {
  expirationDate.value = event.target.value;
  updatePayment("expirationDate", expirationDate.value);
}

const updatePayment = (field, value) => {
  const payment = {
    cardNumber: cardNumber.value,
    expirationDate: expirationDate.value,
    cvv: cvv.value,
    cardholderName: cardholderName.value,
  };
  const updatedPayment = { ...payment, [field]: value };
  emit("updateSaleData", { payment: updatedPayment });
};
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body container-form">
            <h5 class="card-title mb-5">
              {{ t("sales.details.payment.paymentMethod") }}
            </h5>
            <form>
              <!-- Form Fields -->
              <div class="row">
                <!-- Card Number Field -->
                <div class="col-md-6 mb-3">
                  <label for="cardNumber">{{
                    t("sales.details.payment.cardNumber")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="displayValue"
                    @input="onInput"
                    @focus="onFocus"
                    @blur="onBlur"
                    @copy.prevent
                    placeholder="Enter card number"
                    maxlength="23"
                  />
                </div>

                <!-- Card Expiration Date Field -->
                <div class="col-md-3 mb-3">
                  <label for="cardExpirationDate">{{
                    t("sales.details.payment.cardDate")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="displayExpDate"
                    @input="onInputExpDate"
                    placeholder="MM/YY"
                    maxlength="5"
                    aria-label="Expiration Date"
                  />
                </div>

                <!-- CVV Field -->
                <div class="col-md-3 mb-3">
                  <label for="cvv">CVV</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="displayCvv"
                    @input="onCvvInput"
                    @focus="onCvvFocus"
                    @blur="onCvvBlur"
                    @copy.prevent
                    placeholder="CVV (3 or 4 digits)"
                    inputmode="numeric"
                    pattern="[0-9]{3,4}"
                    maxlength="4"
                  />
                </div>
              </div>

              <div class="row">
                <!-- Full Name on Card Field -->
                <div class="col-md-6 mb-3">
                  <label for="fullNameOnCard">{{
                    t("sales.details.payment.fullcard")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="cardholderName"
                    @input="updatePayment('cardholderName', cardholderName)"
                    placeholder="Write the name as it appears on the card"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
