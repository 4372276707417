<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <div class="row">
        <!-- Chart -->
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Typicon Icon</h5>
            </div>
            <div class="card-body">
              <div class="icons-items">
                <ul class="icons-list">
                  <li class="icons-list-item">
                    <i class="typcn typcn-chart-pie-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-chart-pie-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-chart-pie" data-bs-toggle="tooltip" title="typcn typcn-chart-pie"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-chevron-left-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-chevron-left-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-chevron-left" data-bs-toggle="tooltip" title="typcn typcn-chevron-left"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-chevron-right-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-chevron-right-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-chevron-right" data-bs-toggle="tooltip" title="typcn typcn-chevron-right"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-clipboard" data-bs-toggle="tooltip" title="typcn typcn-clipboard"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-cloud-storage" data-bs-toggle="tooltip" title="typcn typcn-cloud-storage"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-cloud-storage-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-cloud-storage-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-code-outline" data-bs-toggle="tooltip" title="typcn typcn-code-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-code" data-bs-toggle="tooltip" title="typcn typcn-code"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-coffee" data-bs-toggle="tooltip" title="typcn typcn-coffee"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-cog-outline" data-bs-toggle="tooltip" title="typcn typcn-cog-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-cog" data-bs-toggle="tooltip" title="typcn typcn-cog"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-compass" data-bs-toggle="tooltip" title="typcn typcn-compass"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-contacts" data-bs-toggle="tooltip" title="typcn typcn-contacts"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-credit-card" data-bs-toggle="tooltip" title="typcn typcn-credit-card"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-css3" data-bs-toggle="tooltip" title="typcn typcn-css3"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-database" data-bs-toggle="tooltip" title="typcn typcn-database"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-delete-outline" data-bs-toggle="tooltip" title="typcn typcn-delete-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-delete" data-bs-toggle="tooltip" title="typcn typcn-delete"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-device-desktop" data-bs-toggle="tooltip" title="typcn typcn-device-desktop"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-device-laptop" data-bs-toggle="tooltip" title="typcn typcn-device-laptop"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-device-phone" data-bs-toggle="tooltip" title="typcn typcn-device-phone"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-device-tablet" data-bs-toggle="tooltip" title="typcn typcn-device-tablet"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-directions" data-bs-toggle="tooltip" title="typcn typcn-directions"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-divide-outline" data-bs-toggle="tooltip" title="typcn typcn-divide-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-divide" data-bs-toggle="tooltip" title="typcn typcn-divide"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-document-add" data-bs-toggle="tooltip" title="typcn typcn-document-add"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-document-delete" data-bs-toggle="tooltip"
                      title="typcn typcn-document-delete"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-document-text" data-bs-toggle="tooltip" title="typcn typcn-document-text"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-document" data-bs-toggle="tooltip" title="typcn typcn-document"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-download-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-download-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-download" data-bs-toggle="tooltip" title="typcn typcn-download"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-dropbox" data-bs-toggle="tooltip" title="typcn typcn-dropbox"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-edit" data-bs-toggle="tooltip" title="typcn typcn-edit"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-eject-outline" data-bs-toggle="tooltip" title="typcn typcn-eject-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-eject" data-bs-toggle="tooltip" title="typcn typcn-eject"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-equals-outline" data-bs-toggle="tooltip" title="typcn typcn-equals-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-equals" data-bs-toggle="tooltip" title="typcn typcn-equals"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-export-outline" data-bs-toggle="tooltip" title="typcn typcn-export-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-export" data-bs-toggle="tooltip" title="typcn typcn-export"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-eye-outline" data-bs-toggle="tooltip" title="typcn typcn-eye-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-eye" data-bs-toggle="tooltip" title="typcn typcn-eye"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-feather" data-bs-toggle="tooltip" title="typcn typcn-feather"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-film" data-bs-toggle="tooltip" title="typcn typcn-film"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-filter" data-bs-toggle="tooltip" title="typcn typcn-filter"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-flag-outline" data-bs-toggle="tooltip" title="typcn typcn-flag-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-flag" data-bs-toggle="tooltip" title="typcn typcn-flag"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-flash-outline" data-bs-toggle="tooltip" title="typcn typcn-flash-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-flash" data-bs-toggle="tooltip" title="typcn typcn-flash"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-flow-children" data-bs-toggle="tooltip" title="typcn typcn-flow-children"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-flow-merge" data-bs-toggle="tooltip" title="typcn typcn-flow-merge"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-flow-parallel" data-bs-toggle="tooltip" title="typcn typcn-flow-parallel"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-flow-switch" data-bs-toggle="tooltip" title="typcn typcn-flow-switch"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-folder-add" data-bs-toggle="tooltip" title="typcn typcn-folder-add"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-folder-delete" data-bs-toggle="tooltip" title="typcn typcn-folder-delete"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-folder-open" data-bs-toggle="tooltip" title="typcn typcn-folder-open"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-folder" data-bs-toggle="tooltip" title="typcn typcn-folder"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-gift" data-bs-toggle="tooltip" title="typcn typcn-gift"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-globe-outline" data-bs-toggle="tooltip" title="typcn typcn-globe-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-globe" data-bs-toggle="tooltip" title="typcn typcn-globe"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-group-outline" data-bs-toggle="tooltip" title="typcn typcn-group-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-group" data-bs-toggle="tooltip" title="typcn typcn-group"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-headphones" data-bs-toggle="tooltip" title="typcn typcn-headphones"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-heart-full-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-heart-full-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-heart-half-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-heart-half-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-heart-outline" data-bs-toggle="tooltip" title="typcn typcn-heart-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-heart" data-bs-toggle="tooltip" title="typcn typcn-heart"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-home-outline" data-bs-toggle="tooltip" title="typcn typcn-home-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-home" data-bs-toggle="tooltip" title="typcn typcn-home"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-html5" data-bs-toggle="tooltip" title="typcn typcn-html5"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-image-outline" data-bs-toggle="tooltip" title="typcn typcn-image-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-image" data-bs-toggle="tooltip" title="typcn typcn-image"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-infinity-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-infinity-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-info-large-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-info-large-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-info-large" data-bs-toggle="tooltip" title="typcn typcn-info-large"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-info-outline" data-bs-toggle="tooltip" title="typcn typcn-info-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-info" data-bs-toggle="tooltip" title="typcn typcn-info"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-input-checked-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-input-checked-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-input-checked" data-bs-toggle="tooltip" title="typcn typcn-input-checked"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-key-outline" data-bs-toggle="tooltip" title="typcn typcn-key-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-key" data-bs-toggle="tooltip" title="typcn typcn-key"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-keyboard" data-bs-toggle="tooltip" title="typcn typcn-keyboard"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-leaf" data-bs-toggle="tooltip" title="typcn typcn-leaf"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-lightbulb" data-bs-toggle="tooltip" title="typcn typcn-lightbulb"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-link-outline" data-bs-toggle="tooltip" title="typcn typcn-link-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-link" data-bs-toggle="tooltip" title="typcn typcn-link"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-location-arrow-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-location-arrow-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-location-arrow" data-bs-toggle="tooltip" title="typcn typcn-location-arrow"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-location-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-location-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-location" data-bs-toggle="tooltip" title="typcn typcn-location"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-lock-closed-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-lock-closed-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-lock-closed" data-bs-toggle="tooltip" title="typcn typcn-lock-closed"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-lock-open-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-lock-open-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-lock-open" data-bs-toggle="tooltip" title="typcn typcn-lock-open"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-mail" data-bs-toggle="tooltip" title="typcn typcn-mail"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-map" data-bs-toggle="tooltip" title="typcn typcn-map"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-eject-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-media-eject-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-eject" data-bs-toggle="tooltip" title="typcn typcn-media-eject"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-fast-forward-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-media-fast-forward-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-fast-forward" data-bs-toggle="tooltip"
                      title="typcn typcn-media-fast-forward"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-pause-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-media-pause-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-pause" data-bs-toggle="tooltip" title="typcn typcn-media-pause"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-play-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-media-play-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-play-reverse-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-media-play-reverse-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-play-reverse" data-bs-toggle="tooltip"
                      title="typcn typcn-media-play-reverse"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-play" data-bs-toggle="tooltip" title="typcn typcn-media-play"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-record-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-media-record-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-record" data-bs-toggle="tooltip" title="typcn typcn-media-record"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-rewind-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-media-rewind-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-rewind" data-bs-toggle="tooltip" title="typcn typcn-media-rewind"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-stop-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-media-stop-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-media-stop" data-bs-toggle="tooltip" title="typcn typcn-media-stop"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-message-typing" data-bs-toggle="tooltip" title="typcn typcn-message-typing"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-message" data-bs-toggle="tooltip" title="typcn typcn-message"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-messages" data-bs-toggle="tooltip" title="typcn typcn-messages"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-microphone-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-microphone-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-microphone" data-bs-toggle="tooltip" title="typcn typcn-microphone"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-minus-outline" data-bs-toggle="tooltip" title="typcn typcn-minus-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-minus" data-bs-toggle="tooltip" title="typcn typcn-minus"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-mortar-board" data-bs-toggle="tooltip" title="typcn typcn-mortar-board"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-news" data-bs-toggle="tooltip" title="typcn typcn-news"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-notes-outline" data-bs-toggle="tooltip" title="typcn typcn-notes-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-notes" data-bs-toggle="tooltip" title="typcn typcn-notes"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-pen" data-bs-toggle="tooltip" title="typcn typcn-pen"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-pencil" data-bs-toggle="tooltip" title="typcn typcn-pencil"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-phone-outline" data-bs-toggle="tooltip" title="typcn typcn-phone-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-phone" data-bs-toggle="tooltip" title="typcn typcn-phone"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-pi-outline" data-bs-toggle="tooltip" title="typcn typcn-pi-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-pi" data-bs-toggle="tooltip" title="typcn typcn-pi"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-pin-outline" data-bs-toggle="tooltip" title="typcn typcn-pin-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-pin" data-bs-toggle="tooltip" title="typcn typcn-pin"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-pipette" data-bs-toggle="tooltip" title="typcn typcn-pipette"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-plane-outline" data-bs-toggle="tooltip" title="typcn typcn-plane-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-plane" data-bs-toggle="tooltip" title="typcn typcn-plane"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-plug" data-bs-toggle="tooltip" title="typcn typcn-plug"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-plus-outline" data-bs-toggle="tooltip" title="typcn typcn-plus-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-plus" data-bs-toggle="tooltip" title="typcn typcn-plus"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-point-of-interest-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-point-of-interest-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-point-of-interest" data-bs-toggle="tooltip"
                      title="typcn typcn-point-of-interest"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-power-outline" data-bs-toggle="tooltip" title="typcn typcn-power-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-power" data-bs-toggle="tooltip" title="typcn typcn-power"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-printer" data-bs-toggle="tooltip" title="typcn typcn-printer"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-puzzle-outline" data-bs-toggle="tooltip" title="typcn typcn-puzzle-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-puzzle" data-bs-toggle="tooltip" title="typcn typcn-puzzle"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-radar-outline" data-bs-toggle="tooltip" title="typcn typcn-radar-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-radar" data-bs-toggle="tooltip" title="typcn typcn-radar"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-refresh-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-refresh-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-refresh" data-bs-toggle="tooltip" title="typcn typcn-refresh"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-rss-outline" data-bs-toggle="tooltip" title="typcn typcn-rss-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-rss" data-bs-toggle="tooltip" title="typcn typcn-rss"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-scissors-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-scissors-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-scissors" data-bs-toggle="tooltip" title="typcn typcn-scissors"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-shopping-bag" data-bs-toggle="tooltip" title="typcn typcn-shopping-bag"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-shopping-cart" data-bs-toggle="tooltip" title="typcn typcn-shopping-cart"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-at-circular" data-bs-toggle="tooltip"
                      title="typcn typcn-social-at-circular"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-dribbble-circular" data-bs-toggle="tooltip"
                      title="typcn typcn-social-dribbble-circular"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-dribbble" data-bs-toggle="tooltip"
                      title="typcn typcn-social-dribbble"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-facebook-circular" data-bs-toggle="tooltip"
                      title="typcn typcn-social-facebook-circular"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-facebook" data-bs-toggle="tooltip"
                      title="typcn typcn-social-facebook"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-flickr-circular" data-bs-toggle="tooltip"
                      title="typcn typcn-social-flickr-circular"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-flickr" data-bs-toggle="tooltip" title="typcn typcn-social-flickr"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-github-circular" data-bs-toggle="tooltip"
                      title="typcn typcn-social-github-circular"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-github" data-bs-toggle="tooltip" title="typcn typcn-social-github"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-google-plus-circular" data-bs-toggle="tooltip"
                      title="typcn typcn-social-google-plus-circular"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-google-plus" data-bs-toggle="tooltip"
                      title="typcn typcn-social-google-plus"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-instagram-circular" data-bs-toggle="tooltip"
                      title="typcn typcn-social-instagram-circular"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-instagram" data-bs-toggle="tooltip"
                      title="typcn typcn-social-instagram"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-last-fm-circular" data-bs-toggle="tooltip"
                      title="typcn typcn-social-last-fm-circular"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-last-fm" data-bs-toggle="tooltip" title="typcn typcn-social-last-fm"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-linkedin-circular" data-bs-toggle="tooltip"
                      title="typcn typcn-social-linkedin-circular"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-linkedin" data-bs-toggle="tooltip"
                      title="typcn typcn-social-linkedin"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-pinterest-circular" data-bs-toggle="tooltip"
                      title="typcn typcn-social-pinterest-circular"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-pinterest" data-bs-toggle="tooltip"
                      title="typcn typcn-social-pinterest"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-skype-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-social-skype-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-skype" data-bs-toggle="tooltip" title="typcn typcn-social-skype"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-tumbler-circular" data-bs-toggle="tooltip"
                      title="typcn typcn-social-tumbler-circular"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-tumbler" data-bs-toggle="tooltip" title="typcn typcn-social-tumbler"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-twitter-circular" data-bs-toggle="tooltip"
                      title="typcn typcn-social-twitter-circular"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-twitter" data-bs-toggle="tooltip" title="typcn typcn-social-twitter"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-vimeo-circular" data-bs-toggle="tooltip"
                      title="typcn typcn-social-vimeo-circular"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-vimeo" data-bs-toggle="tooltip" title="typcn typcn-social-vimeo"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-youtube-circular" data-bs-toggle="tooltip"
                      title="typcn typcn-social-youtube-circular"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-social-youtube" data-bs-toggle="tooltip" title="typcn typcn-social-youtube"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-sort-alphabetically-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-sort-alphabetically-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-sort-alphabetically" data-bs-toggle="tooltip"
                      title="typcn typcn-sort-alphabetically"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-sort-numerically-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-sort-numerically-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-sort-numerically" data-bs-toggle="tooltip"
                      title="typcn typcn-sort-numerically"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-spanner-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-spanner-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-spanner" data-bs-toggle="tooltip" title="typcn typcn-spanner"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-spiral" data-bs-toggle="tooltip" title="typcn typcn-spiral"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-star-full-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-star-full-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-star-half-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-star-half-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-star-half" data-bs-toggle="tooltip" title="typcn typcn-star-half"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-star-outline" data-bs-toggle="tooltip" title="typcn typcn-star-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-star" data-bs-toggle="tooltip" title="typcn typcn-star"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-starburst-outline" data-bs-toggle="tooltip"
                      title="typcn typcn-starburst-outline"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-starburst" data-bs-toggle="tooltip" title="typcn typcn-starburst"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-stopwatch" data-bs-toggle="tooltip" title="typcn typcn-stopwatch"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-support" data-bs-toggle="tooltip" title="typcn typcn-support"></i>
                  </li>
                  <li class="icons-list-item">
                    <i class="typcn typcn-tabs-outline" data-bs-toggle="tooltip" title="typcn typcn-tabs-outline"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Chart -->
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
<script>
export default {
  data() {
    return {
      title: "Typicon Icon",
    };
  },
};
</script>
