import axios from "axios";

const api = axios.create({
  baseURL: "https://element-crm-bakend-v1-598624036744.us-central1.run.app",
  // baseURL: "http://localhost:3000/",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(({ resolve, reject }) => {
    if (error) {
      reject(error);
    } else {
      resolve(token);
    }
  });
  failedQueue = [];
};

// Interceptor para añadir el token a las peticiones
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor para manejar las respuestas
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (!originalRequest) {
      return Promise.reject(error);
    }

    // Verificar si el error es de token inválido
    const isTokenInvalid =
      error.response?.status === 401 &&
      error.response?.data?.message === "Token inválido";

    if (isTokenInvalid && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            console.log("Si ingresa", isRefreshing);
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return api(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const oldRefreshToken = localStorage.getItem("refreshToken");
        console.log("Si ingresa", oldRefreshToken);

        if (!oldRefreshToken) {
          throw new Error("No refresh token available");
        }

        const response = await api.post("/auth/refreshToken", {
          refreshToken: localStorage.getItem("refreshToken"),
        });

        const { idToken, refreshToken } = response.data;
        console.log("responseee", response.data);

        // Guardar nuevos tokens
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("token", idToken);

        // Actualizar header de la petición original
        originalRequest.headers.Authorization = `Bearer ${idToken}`;

        // Procesar peticiones en cola
        processQueue(null, idToken);

        return api(originalRequest);
      } catch (refreshError) {
        // Limpiar tokens y cola
        processQueue(refreshError, null);
        // localStorage.removeItem("token");
        // localStorage.removeItem("refreshToken");

        // Aquí puedes añadir tu lógica de redirección o logout
        // window.location.href = "/";
        // o si tienes una función de logout:
        // handleLogout();

        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default api;
