<template>
  <div class="profile-cover">
    <div class="profile-cover-wrap">
      <!-- Purple Bar -->
      <div class="cover-bar"></div>
    </div>
  </div>

  <div class="text-center mb-5">
    <label class="avatar avatar-xxl profile-cover-avatar" for="avatar_upload">
      <img class="avatar-img" :src="userInfo.profileUrl" alt="Profile Image" />
      <input type="file" id="avatar_upload" />
      <span class="avatar-edit">
        <i class="feather feather-edit avatar-uploader-icon shadow-soft"></i>
      </span>
    </label>
    <h2>{{ userInfo.firstName }} {{ userInfo.lastName }}</h2>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  data: Object,
});
const userInfo = computed(() => props["data"]);
</script>

<style scoped>
.profile-cover-wrap {
  background-color: #895e8b;
  opacity: 10%;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cover-bar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  border: 1px solid #000000; 
  border-radius: 15px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.85);
}

.cover-title {
  font-size: 24px;
  color: white;
  font-weight: bold;
}

.custom-file-btn,
.cover-content {
  display: none;
}
</style>
