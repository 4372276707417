<template>
  <div class="container">
    <div class="invoice-wrapper download_section signature-preview-page">
      <div class="inv-content">
        <div class="invoice-header">
          <div class="inv-header-left">
            <a href="javascript:;">
              <img src="@/assets/img/logo2.png" alt="Logo" />
            </a>
            <span>Orginal For Receipient</span>
          </div>
          <div class="inv-header-right">
            <div class="invoice-title">TAX INVOICE</div>
            <div class="inv-details">
              <div class="inv-date">Date: <span>5/12/2023</span></div>
              <div class="inv-date">Invoice No: <span>00001</span></div>
            </div>
          </div>
        </div>
        <div class="invoice-address">
          <div class="invoice-to">
            <span>Invoice To:</span>
            <div class="inv-to-address">
              Walter Roberson<br />
              299 Star Trek Drive, Panama City, <br />
              Florida, 32405, USA.<br />
              walter@example.com <br />
              +45 5421 4523
            </div>
          </div>
          <div class="invoice-to">
            <span>Pay To:</span>
            <div class="inv-to-address">
              Lowell H. Dominguez<br />
              84 Spilman Street, London<br />
              United King<br />
              domlowell@example.com<br />
              +45 5421 2154
            </div>
          </div>
          <div class="company-details">
            <span class="company-name">Dreams</span>
            <div class="gst-details">GST IN: <span>22AABCU9603R1ZX</span></div>
            <div class="gst-details">
              Address: <span>5 Hodges Mews, High Wycombe HP12 3JL, United Kingdom</span>
            </div>
            <div class="gst-details mb-0">Mobile: <span>+ 91 98765 43210</span></div>
          </div>
        </div>
        <div class="invoice-table">
          <div class="table-responsive">
            <table>
              <thead>
                <tr>
                  <th class="table_width_1">#</th>
                  <th class="table_width_2">Item</th>
                  <th class="table_width_3">Tax Value</th>
                  <th class="table_width_1 text-center">Qty</th>
                  <th class="table_width_4 text-end">Price</th>
                  <th class="table_width_4 text-end">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td >Website Design</td>
                  <td class="table-description">
                    Four plus web pages design and two rivision
                  </td>
                  <td class="text-center">1</td>
                  <td class="text-end">$350</td>
                  <td class="text-end">$350</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td class="table-description">Web Development</td>
                  <td >Dynamic frontend design</td>
                  <td class="text-center">1</td>
                  <td class="text-end">$600</td>
                  <td class="text-end">$600</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td >App Development</td>
                  <td class="table-description">Android and Ios App design</td>
                  <td class="text-center">2</td>
                  <td class="text-end">$200</td>
                  <td class="text-end">$400</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td >Digital Marketing</td>
                  <td class="table-description">Facebook and instagram marketing</td>
                  <td class="text-center">3</td>
                  <td class="text-end">$100</td>
                  <td class="text-end">$300</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="invoice-table-footer">
          <div class="table-footer-left"></div>
          <div class="text-end table-footer-right">
            <table>
              <tbody>
                <tr>
                  <td>Taxable Amount</td>
                  <td>$1650</td>
                </tr>
                <tr>
                  <td>GST 18.0%</td>
                  <td>$165</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="invoice-table-footer">
          <div class="table-footer-left">
            <p class="total-info">Total Items / Qty : 4 / 4.00</p>
          </div>
          <div class="table-footer-right">
            <table class="totalamt-table">
              <tbody>
                <tr>
                  <td>Total</td>
                  <td>$1650</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="total-amountdetails">
          <p>
            Total amount ( in words):
            <span>$ One Thousand Six Hundred Fifteen Only.</span>
          </p>
        </div>
        <div class="bank-details">
          <div class="account-info">
            <span class="bank-title">Bank Details</span>
            <div class="account-details">Bank : <span>YES Bank</span></div>
            <div class="account-details">Account # :<span> 6677889944551 </span></div>
            <div class="account-details">IFSC : <span>YESBBIN4567</span></div>
            <div class="account-details">BRANCH : <span>Newyork</span></div>
          </div>
          <div class="company-sign">
            <span>For Dreams</span>
            <img src="@/assets/img/signature.png" alt="signature-img" />
          </div>
        </div>
        <div class="terms-condition">
          <span>Terms and Conditions:</span>
          <ol>
            <li>Goods Once sold cannot be taken back or exchanged</li>
            <li>
              We are not the manufactures, company will stand for warrenty as per their
              terms and conditions.
            </li>
          </ol>
        </div>
        <div class="thanks-msg text-center">Thanks for your Business</div>
        <div class="add-customer-btns text-end">
          <a href="javascript:;" class="btn btn-primary cancel me-2">Save</a>
          <router-link to="mail-pay-invoice" class="btn btn-primary">Save & Send</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
