<template>
  <div class="col-lg-4">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title d-flex justify-content-between">
          <span>Profile</span>
        </h5>
      </div>
      <div class="card-body">
        <ul class="list-unstyled mb-0">
          <li class="pt-2 pb-0">
            <h9 class="text-muted">First Name</h9>
          </li>
          <li class="text-dark">{{ userInfo.firstName }}</li>

          <li class="pt-2 pb-0">
            <h9 class="text-muted">Middle Name</h9>
          </li>
          <li class="text-dark">{{ userInfo.middleName }}</li>

          <li class="pt-2 pb-0">
            <h9 class="text-muted">Last Name</h9>
          </li>
          <li class="text-dark">{{ userInfo.lastName }}</li>

          <li class="pt-2 pb-0">
            <h9 class="text-muted">Phone Number</h9>
          </li>
          <li class="text-dark">{{ userInfo.phone }}</li>

          <li class="pt-2 pb-0">
            <h9 class="text-muted">Email</h9>
          </li>
          <li class="text-dark">{{ userInfo.email }}</li>

          <li class="pt-2 pb-0">
            <h9 class="text-muted">Role</h9>
          </li>

          <li class="text-dark">{{ formatText(userInfo.role) }}</li>


          <li v-if="userInfo.role === 'PROCESSOR' && carrierValue" class="pt-2 pb-0">
            <h9 class="text-muted">Carrier</h9>
          </li>

          <li v-if="userInfo.role === 'PROCESSOR' && carrierValue" class="text-dark">
            {{ formatText(carrierValue) }}

          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, ref, onMounted } from "vue";
import { useUserStore } from "@/store/user";

const props = defineProps({
  data: Object,
});


const userInfo = computed(() => props["data"]);
const carriers = ref([]);
const userStore = useUserStore();


const formatText = (text) => {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};


const loadCarrier = async () => {
  try {
    const response = await userStore.fetchCarrier();
    carriers.value = response[0]?.list || [];
  } catch (error) {
    console.error("Error loading carriers", error);
  }
};

const carrierValue = computed(() => {
  if (userInfo.value.role === 'PROCESSOR' && carriers.value.length > 0) {
    const carrier = carriers.value.find(c => c.id === userInfo.value.carrier);
    return carrier ? carrier.value : null;
  }
  return null;
});

onMounted(loadCarrier);

</script>