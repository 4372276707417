<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <div class="row">
        <!-- Chart -->
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Simpleline Icons</h5>
            </div>
            <div class="card-body">
              <div class="icons-items">
                <ul class="icons-list">
                  <li>
                    <i class="icon-user" data-bs-toggle="tooltip" title="si-user"></i>
                  </li>
                  <li>
                    <i class="icon-people" data-bs-toggle="tooltip" title="si-people"></i>
                  </li>
                  <li>
                    <i class="icon-user-female" data-bs-toggle="tooltip" title="si-user-female"></i>
                  </li>
                  <li>
                    <i class="icon-user-follow" data-bs-toggle="tooltip" title="si-user-follow"></i>
                  </li>
                  <li>
                    <i class="icon-user-following" data-bs-toggle="tooltip" title="si-user-following"></i>
                  </li>
                  <li>
                    <i class="icon-user-unfollow" data-bs-toggle="tooltip" title="si-user-unfollow"></i>
                  </li>
                  <li>
                    <i class="icon-login" data-bs-toggle="tooltip" title="si-login"></i>
                  </li>
                  <li>
                    <i class="icon-logout" data-bs-toggle="tooltip" title="si-logout"></i>
                  </li>
                  <li>
                    <i class="icon-emotsmile" data-bs-toggle="tooltip" title="si-emotsmile"></i>
                  </li>
                  <li>
                    <i class="icon-phone" data-bs-toggle="tooltip" title="si-phone"></i>
                  </li>
                  <li>
                    <i class="icon-call-end" data-bs-toggle="tooltip" title="si-call-end"></i>
                  </li>
                  <li>
                    <i class="icon-call-in" data-bs-toggle="tooltip" title="si-call-in"></i>
                  </li>
                  <li>
                    <i class="icon-call-out" data-bs-toggle="tooltip" title="si-call-out"></i>
                  </li>
                  <li>
                    <i class="icon-map" data-bs-toggle="tooltip" title="si-map"></i>
                  </li>
                  <li>
                    <i class="icon-location-pin" data-bs-toggle="tooltip" title="si-location-pin"></i>
                  </li>
                  <li>
                    <i class="icon-direction" data-bs-toggle="tooltip" title="si-direction"></i>
                  </li>
                  <li>
                    <i class="icon-directions" data-bs-toggle="tooltip" title="si-directions"></i>
                  </li>
                  <li>
                    <i class="icon-compass" data-bs-toggle="tooltip" title="si-compass"></i>
                  </li>
                  <li>
                    <i class="icon-layers" data-bs-toggle="tooltip" title="si-layers"></i>
                  </li>
                  <li>
                    <i class="icon-menu" data-bs-toggle="tooltip" title="si-menu"></i>
                  </li>
                  <li>
                    <i class="icon-list" data-bs-toggle="tooltip" title="si-list"></i>
                  </li>
                  <li>
                    <i class="icon-options-vertical" data-bs-toggle="tooltip" title="si-options-vertical"></i>
                  </li>
                  <li>
                    <i class="icon-options" data-bs-toggle="tooltip" title="si-options"></i>
                  </li>
                  <li>
                    <i class="icon-arrow-down" data-bs-toggle="tooltip" title="si-arrow-down"></i>
                  </li>
                  <li>
                    <i class="icon-arrow-left" data-bs-toggle="tooltip" title="si-arrow-left"></i>
                  </li>
                  <li>
                    <i class="icon-arrow-right" data-bs-toggle="tooltip" title="si-arrow-right"></i>
                  </li>
                  <li>
                    <i class="icon-arrow-up" data-bs-toggle="tooltip" title="si-arrow-up"></i>
                  </li>
                  <li>
                    <i class="icon-arrow-up-circle" data-bs-toggle="tooltip" title="si-arrow-up-circle"></i>
                  </li>
                  <li>
                    <i class="icon-arrow-left-circle" data-bs-toggle="tooltip" title="si-arrow-left-circle"></i>
                  </li>
                  <li>
                    <i class="icon-arrow-right-circle" data-bs-toggle="tooltip" title="si-arrow-right-circle"></i>
                  </li>
                  <li>
                    <i class="icon-arrow-down-circle" data-bs-toggle="tooltip" title="si-arrow-down-circle"></i>
                  </li>
                  <li>
                    <i class="icon-check" data-bs-toggle="tooltip" title="si-check"></i>
                  </li>
                  <li>
                    <i class="icon-clock" data-bs-toggle="tooltip" title="si-clock"></i>
                  </li>
                  <li>
                    <i class="icon-plus" data-bs-toggle="tooltip" title="si-plus"></i>
                  </li>
                  <li>
                    <i class="icon-minus" data-bs-toggle="tooltip" title="si-minus"></i>
                  </li>
                  <li>
                    <i class="icon-close" data-bs-toggle="tooltip" title="si-close"></i>
                  </li>
                  <li>
                    <i class="icon-event" data-bs-toggle="tooltip" title="si-event"></i>
                  </li>
                  <li>
                    <i class="icon-exclamation" data-bs-toggle="tooltip" title="si-exclamation"></i>
                  </li>
                  <li>
                    <i class="icon-organization" data-bs-toggle="tooltip" title="si-organization"></i>
                  </li>
                  <li>
                    <i class="icon-trophy" data-bs-toggle="tooltip" title="si-trophy"></i>
                  </li>
                  <li>
                    <i class="icon-screen-smartphone" data-bs-toggle="tooltip" title="si-screen-smartphone"></i>
                  </li>
                  <li>
                    <i class="icon-screen-desktop" data-bs-toggle="tooltip" title="si-screen-desktop"></i>
                  </li>
                  <li>
                    <i class="icon-plane" data-bs-toggle="tooltip" title="si-plane"></i>
                  </li>
                  <li>
                    <i class="icon-notebook" data-bs-toggle="tooltip" title="si-notebook"></i>
                  </li>
                  <li>
                    <i class="icon-mustache" data-bs-toggle="tooltip" title="si-mustache"></i>
                  </li>
                  <li>
                    <i class="icon-mouse" data-bs-toggle="tooltip" title="si-mouse"></i>
                  </li>
                  <li>
                    <i class="icon-magnet" data-bs-toggle="tooltip" title="si-magnet"></i>
                  </li>
                  <li>
                    <i class="icon-energy" data-bs-toggle="tooltip" title="si-energy"></i>
                  </li>
                  <li>
                    <i class="icon-disc" data-bs-toggle="tooltip" title="si-disc"></i>
                  </li>
                  <li>
                    <i class="icon-cursor" data-bs-toggle="tooltip" title="si-cursor"></i>
                  </li>
                  <li>
                    <i class="icon-cursor-move" data-bs-toggle="tooltip" title="si-cursor-move"></i>
                  </li>
                  <li>
                    <i class="icon-crop" data-bs-toggle="tooltip" title="si-crop"></i>
                  </li>
                  <li>
                    <i class="icon-chemistry" data-bs-toggle="tooltip" title="si-chemistry"></i>
                  </li>
                  <li>
                    <i class="icon-speedometer" data-bs-toggle="tooltip" title="si-speedometer"></i>
                  </li>
                  <li>
                    <i class="icon-shield" data-bs-toggle="tooltip" title="si-shield"></i>
                  </li>
                  <li>
                    <i class="icon-screen-tablet" data-bs-toggle="tooltip" title="si-screen-tablet"></i>
                  </li>
                  <li>
                    <i class="icon-magic-wand" data-bs-toggle="tooltip" title="si-magic-wand"></i>
                  </li>
                  <li>
                    <i class="icon-hourglass" data-bs-toggle="tooltip" title="si-hourglass"></i>
                  </li>
                  <li>
                    <i class="icon-graduation" data-bs-toggle="tooltip" title="si-graduation"></i>
                  </li>
                  <li>
                    <i class="icon-ghost" data-bs-toggle="tooltip" title="si-ghost"></i>
                  </li>
                  <li>
                    <i class="icon-game-controller" data-bs-toggle="tooltip" title="si-game-controller"></i>
                  </li>
                  <li>
                    <i class="icon-fire" data-bs-toggle="tooltip" title="si-fire"></i>
                  </li>
                  <li>
                    <i class="icon-eyeglass" data-bs-toggle="tooltip" title="si-eyeglass"></i>
                  </li>
                  <li>
                    <i class="icon-envelope-open" data-bs-toggle="tooltip" title="si-envelope-open"></i>
                  </li>
                  <li>
                    <i class="icon-envelope-letter" data-bs-toggle="tooltip" title="si-envelope-letter"></i>
                  </li>
                  <li>
                    <i class="icon-bell" data-bs-toggle="tooltip" title="si-bell"></i>
                  </li>
                  <li>
                    <i class="icon-badge" data-bs-toggle="tooltip" title="si-badge"></i>
                  </li>
                  <li>
                    <i class="icon-anchor" data-bs-toggle="tooltip" title="si-anchor"></i>
                  </li>
                  <li>
                    <i class="icon-wallet" data-bs-toggle="tooltip" title="si-wallet"></i>
                  </li>
                  <li>
                    <i class="icon-vector" data-bs-toggle="tooltip" title="si-vector"></i>
                  </li>
                  <li>
                    <i class="icon-speech" data-bs-toggle="tooltip" title="si-speech"></i>
                  </li>
                  <li>
                    <i class="icon-puzzle" data-bs-toggle="tooltip" title="si-puzzle"></i>
                  </li>
                  <li>
                    <i class="icon-printer" data-bs-toggle="tooltip" title="si-printer"></i>
                  </li>
                  <li>
                    <i class="icon-present" data-bs-toggle="tooltip" title="si-present"></i>
                  </li>
                  <li>
                    <i class="icon-playlist" data-bs-toggle="tooltip" title="si-playlist"></i>
                  </li>
                  <li>
                    <i class="icon-pin" data-bs-toggle="tooltip" title="si-pin"></i>
                  </li>
                  <li>
                    <i class="icon-picture" data-bs-toggle="tooltip" title="si-picture"></i>
                  </li>
                  <li>
                    <i class="icon-handbag" data-bs-toggle="tooltip" title="si-handbag"></i>
                  </li>
                  <li>
                    <i class="icon-globe-alt" data-bs-toggle="tooltip" title="si-globe-alt"></i>
                  </li>
                  <li>
                    <i class="icon-globe" data-bs-toggle="tooltip" title="si-globe"></i>
                  </li>
                  <li>
                    <i class="icon-folder-alt" data-bs-toggle="tooltip" title="si-folder-alt"></i>
                  </li>
                  <li>
                    <i class="icon-folder" data-bs-toggle="tooltip" title="si-folder"></i>
                  </li>
                  <li>
                    <i class="icon-film" data-bs-toggle="tooltip" title="si-film"></i>
                  </li>
                  <li>
                    <i class="icon-feed" data-bs-toggle="tooltip" title="si-feed"></i>
                  </li>
                  <li>
                    <i class="icon-drop" data-bs-toggle="tooltip" title="si-drop"></i>
                  </li>
                  <li>
                    <i class="icon-drawer" data-bs-toggle="tooltip" title="si-drawer"></i>
                  </li>
                  <li>
                    <i class="icon-docs" data-bs-toggle="tooltip" title="si-docs"></i>
                  </li>
                  <li>
                    <i class="icon-doc" data-bs-toggle="tooltip" title="si-doc"></i>
                  </li>
                  <li>
                    <i class="icon-diamond" data-bs-toggle="tooltip" title="si-diamond"></i>
                  </li>
                  <li>
                    <i class="icon-cup" data-bs-toggle="tooltip" title="si-cup"></i>
                  </li>
                  <li>
                    <i class="icon-calculator" data-bs-toggle="tooltip" title="si-calculator"></i>
                  </li>
                  <li>
                    <i class="icon-bubbles" data-bs-toggle="tooltip" title="si-bubbles"></i>
                  </li>
                  <li>
                    <i class="icon-briefcase" data-bs-toggle="tooltip" title="si-briefcase"></i>
                  </li>
                  <li>
                    <i class="icon-book-open" data-bs-toggle="tooltip" title="si-book-open"></i>
                  </li>
                  <li>
                    <i class="icon-basket-loaded" data-bs-toggle="tooltip" title="si-basket-loaded"></i>
                  </li>
                  <li>
                    <i class="icon-basket" data-bs-toggle="tooltip" title="si-basket"></i>
                  </li>
                  <li>
                    <i class="icon-bag" data-bs-toggle="tooltip" title="si-bag"></i>
                  </li>
                  <li>
                    <i class="icon-action-undo" data-bs-toggle="tooltip" title="si-action-undo"></i>
                  </li>
                  <li>
                    <i class="icon-action-redo" data-bs-toggle="tooltip" title="si-action-redo"></i>
                  </li>
                  <li>
                    <i class="icon-wrench" data-bs-toggle="tooltip" title="si-wrench"></i>
                  </li>
                  <li>
                    <i class="icon-umbrella" data-bs-toggle="tooltip" title="si-umbrella"></i>
                  </li>
                  <li>
                    <i class="icon-trash" data-bs-toggle="tooltip" title="si-trash"></i>
                  </li>
                  <li>
                    <i class="icon-tag" data-bs-toggle="tooltip" title="si-tag"></i>
                  </li>
                  <li>
                    <i class="icon-support" data-bs-toggle="tooltip" title="si-support"></i>
                  </li>
                  <li>
                    <i class="icon-frame" data-bs-toggle="tooltip" title="si-frame"></i>
                  </li>
                  <li>
                    <i class="icon-size-fullscreen" data-bs-toggle="tooltip" title="si-size-fullscreen"></i>
                  </li>
                  <li>
                    <i class="icon-size-actual" data-bs-toggle="tooltip" title="si-size-actual"></i>
                  </li>
                  <li>
                    <i class="icon-shuffle" data-bs-toggle="tooltip" title="si-shuffle"></i>
                  </li>
                  <li>
                    <i class="icon-share-alt" data-bs-toggle="tooltip" title="si-share-alt"></i>
                  </li>
                  <li>
                    <i class="icon-share" data-bs-toggle="tooltip" title="si-share"></i>
                  </li>
                  <li>
                    <i class="icon-rocket" data-bs-toggle="tooltip" title="si-rocket"></i>
                  </li>
                  <li>
                    <i class="icon-question" data-bs-toggle="tooltip" title="si-question"></i>
                  </li>
                  <li>
                    <i class="icon-pie-chart" data-bs-toggle="tooltip" title="si-pie-chart"></i>
                  </li>
                  <li>
                    <i class="icon-pencil" data-bs-toggle="tooltip" title="si-pencil"></i>
                  </li>
                  <li>
                    <i class="icon-note" data-bs-toggle="tooltip" title="si-note"></i>
                  </li>
                  <li>
                    <i class="icon-loop" data-bs-toggle="tooltip" title="si-loop"></i>
                  </li>
                  <li>
                    <i class="icon-home" data-bs-toggle="tooltip" title="si-home"></i>
                  </li>
                  <li>
                    <i class="icon-grid" data-bs-toggle="tooltip" title="si-grid"></i>
                  </li>
                  <li>
                    <i class="icon-graph" data-bs-toggle="tooltip" title="si-graph"></i>
                  </li>
                  <li>
                    <i class="icon-microphone" data-bs-toggle="tooltip" title="si-microphone"></i>
                  </li>
                  <li>
                    <i class="icon-music-tone-alt" data-bs-toggle="tooltip" title="si-music-tone-alt"></i>
                  </li>
                  <li>
                    <i class="icon-music-tone" data-bs-toggle="tooltip" title="si-music-tone"></i>
                  </li>
                  <li>
                    <i class="icon-earphones-alt" data-bs-toggle="tooltip" title="si-earphones-alt"></i>
                  </li>
                  <li>
                    <i class="icon-earphones" data-bs-toggle="tooltip" title="si-earphones"></i>
                  </li>
                  <li>
                    <i class="icon-equalizer" data-bs-toggle="tooltip" title="si-equalizer"></i>
                  </li>
                  <li>
                    <i class="icon-like" data-bs-toggle="tooltip" title="si-like"></i>
                  </li>
                  <li>
                    <i class="icon-dislike" data-bs-toggle="tooltip" title="si-dislike"></i>
                  </li>
                  <li>
                    <i class="icon-control-start" data-bs-toggle="tooltip" title="si-control-start"></i>
                  </li>
                  <li>
                    <i class="icon-control-rewind" data-bs-toggle="tooltip" title="si-control-rewind"></i>
                  </li>
                  <li>
                    <i class="icon-control-play" data-bs-toggle="tooltip" title="si-control-play"></i>
                  </li>
                  <li>
                    <i class="icon-control-pause" data-bs-toggle="tooltip" title="si-control-pause"></i>
                  </li>
                  <li>
                    <i class="icon-control-forward" data-bs-toggle="tooltip" title="si-control-forward"></i>
                  </li>
                  <li>
                    <i class="icon-control-end" data-bs-toggle="tooltip" title="si-control-end"></i>
                  </li>
                  <li>
                    <i class="icon-volume-1" data-bs-toggle="tooltip" title="si-volume-1"></i>
                  </li>
                  <li>
                    <i class="icon-volume-2" data-bs-toggle="tooltip" title="si-volume-2"></i>
                  </li>
                  <li>
                    <i class="icon-volume-off" data-bs-toggle="tooltip" title="si-volume-off"></i>
                  </li>
                  <li>
                    <i class="icon-calendar" data-bs-toggle="tooltip" title="si-calendar"></i>
                  </li>
                  <li>
                    <i class="icon-bulb" data-bs-toggle="tooltip" title="si-bulb"></i>
                  </li>
                  <li>
                    <i class="icon-chart" data-bs-toggle="tooltip" title="si-chart"></i>
                  </li>
                  <li>
                    <i class="icon-ban" data-bs-toggle="tooltip" title="si-ban"></i>
                  </li>
                  <li>
                    <i class="icon-bubble" data-bs-toggle="tooltip" title="si-bubble"></i>
                  </li>
                  <li>
                    <i class="icon-camrecorder" data-bs-toggle="tooltip" title="si-camrecorder"></i>
                  </li>
                  <li>
                    <i class="icon-camera" data-bs-toggle="tooltip" title="si-camera"></i>
                  </li>
                  <li>
                    <i class="icon-cloud-download" data-bs-toggle="tooltip" title="si-cloud-download"></i>
                  </li>
                  <li>
                    <i class="icon-cloud-upload" data-bs-toggle="tooltip" title="si-cloud-upload"></i>
                  </li>
                  <li>
                    <i class="icon-envelope" data-bs-toggle="tooltip" title="si-envelope"></i>
                  </li>
                  <li>
                    <i class="icon-eye" data-bs-toggle="tooltip" title="si-eye"></i>
                  </li>
                  <li>
                    <i class="icon-flag" data-bs-toggle="tooltip" title="si-flag"></i>
                  </li>
                  <li>
                    <i class="icon-heart" data-bs-toggle="tooltip" title="si-heart"></i>
                  </li>
                  <li>
                    <i class="icon-info" data-bs-toggle="tooltip" title="si-info"></i>
                  </li>
                  <li>
                    <i class="icon-key" data-bs-toggle="tooltip" title="si-key"></i>
                  </li>
                  <li>
                    <i class="icon-link" data-bs-toggle="tooltip" title="si-link"></i>
                  </li>
                  <li>
                    <i class="icon-lock" data-bs-toggle="tooltip" title="si-lock"></i>
                  </li>
                  <li>
                    <i class="icon-lock-open" data-bs-toggle="tooltip" title="si-lock-open"></i>
                  </li>
                  <li>
                    <i class="icon-magnifier" data-bs-toggle="tooltip" title="si-magnifier"></i>
                  </li>
                  <li>
                    <i class="icon-magnifier-add" data-bs-toggle="tooltip" title="si-magnifier-add"></i>
                  </li>
                  <li>
                    <i class="icon-magnifier-remove" data-bs-toggle="tooltip" title="si-magnifier-remove"></i>
                  </li>
                  <li>
                    <i class="icon-paper-clip" data-bs-toggle="tooltip" title="si-paper-clip"></i>
                  </li>
                  <li>
                    <i class="icon-paper-plane" data-bs-toggle="tooltip" title="si-paper-plane"></i>
                  </li>
                  <li>
                    <i class="icon-power" data-bs-toggle="tooltip" title="si-power"></i>
                  </li>
                  <li>
                    <i class="icon-refresh" data-bs-toggle="tooltip" title="si-refresh"></i>
                  </li>
                  <li>
                    <i class="icon-reload" data-bs-toggle="tooltip" title="si-reload"></i>
                  </li>
                  <li>
                    <i class="icon-settings" data-bs-toggle="tooltip" title="si-settings"></i>
                  </li>
                  <li>
                    <i class="icon-star" data-bs-toggle="tooltip" title="si-star"></i>
                  </li>
                  <li>
                    <i class="icon-symbol-female" data-bs-toggle="tooltip" title="si-symbol-female"></i>
                  </li>
                  <li>
                    <i class="icon-symbol-male" data-bs-toggle="tooltip" title="si-symbol-male"></i>
                  </li>
                  <li>
                    <i class="icon-target" data-bs-toggle="tooltip" title="si-target"></i>
                  </li>
                  <li>
                    <i class="icon-credit-card" data-bs-toggle="tooltip" title="si-credit-card"></i>
                  </li>
                  <li>
                    <i class="icon-paypal" data-bs-toggle="tooltip" title="si-paypal"></i>
                  </li>
                  <li>
                    <i class="icon-social-tumblr" data-bs-toggle="tooltip" title="si-social-tumblr"></i>
                  </li>
                  <li>
                    <i class="icon-social-twitter" data-bs-toggle="tooltip" title="si-social-twitter"></i>
                  </li>
                  <li>
                    <i class="icon-social-facebook" data-bs-toggle="tooltip" title="si-social-facebook"></i>
                  </li>
                  <li>
                    <i class="icon-social-instagram" data-bs-toggle="tooltip" title="si-social-instagram"></i>
                  </li>
                  <li>
                    <i class="icon-social-linkedin" data-bs-toggle="tooltip" title="si-social-linkedin"></i>
                  </li>
                  <li>
                    <i class="icon-social-pinterest" data-bs-toggle="tooltip" title="si-social-pinterest"></i>
                  </li>
                  <li>
                    <i class="icon-social-github" data-bs-toggle="tooltip" title="si-social-github"></i>
                  </li>
                  <li>
                    <i class="icon-social-google" data-bs-toggle="tooltip" title="si-social-google"></i>
                  </li>
                  <li>
                    <i class="icon-social-reddit" data-bs-toggle="tooltip" title="si-social-reddit"></i>
                  </li>
                  <li>
                    <i class="icon-social-skype" data-bs-toggle="tooltip" title="si-social-skype"></i>
                  </li>
                  <li>
                    <i class="icon-social-dribbble" data-bs-toggle="tooltip" title="si-social-dribbble"></i>
                  </li>
                  <li>
                    <i class="icon-social-behance" data-bs-toggle="tooltip" title="si-social-behance"></i>
                  </li>
                  <li>
                    <i class="icon-social-foursqare" data-bs-toggle="tooltip" title="si-social-foursqare"></i>
                  </li>
                  <li>
                    <i class="icon-social-soundcloud" data-bs-toggle="tooltip" title="si-social-soundcloud"></i>
                  </li>
                  <li>
                    <i class="icon-social-spotify" data-bs-toggle="tooltip" title="si-social-spotify"></i>
                  </li>
                  <li>
                    <i class="icon-social-stumbleupon" data-bs-toggle="tooltip" title="si-social-stumbleupon"></i>
                  </li>
                  <li>
                    <i class="icon-social-youtube" data-bs-toggle="tooltip" title="si-social-youtube"></i>
                  </li>
                  <li>
                    <i class="icon-social-dropbox" data-bs-toggle="tooltip" title="si-social-dropbox"></i>
                  </li>
                  <li>
                    <i class="icon-social-vkontakte" data-bs-toggle="tooltip" title="si-social-vkontakte"></i>
                  </li>
                  <li>
                    <i class="icon-social-steam" data-bs-toggle="tooltip" title="si-social-steam"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Chart -->
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
<script>

export default {
  data() {
    return {
      title: "Simpleline Icons",
    };
  },
  mounted() { },
};
</script>
