<template>
  <div id="app">
    <div class="main-wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
};
</script>
