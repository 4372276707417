<template>
  <div class="no-stickybar">
    <div class="container">
      <div class="invoice-wrapper download_section invoice-five">
        <div class="inv-content">
          <div class="invoice-header">
            <div class="inv-header-left">
              <a href="javascript:;">
                <img src="@/assets/img/logo2.png" alt="Logo" />
              </a>
            </div>
            <div class="inv-header-right">
              <h2>INVOICE</h2>
            </div>
          </div>
          <div class="invoice-five-details">
            <div class="gradient-block"></div>
            <div class="invoice-date">
              <div class="invoice-date-details">Invoice No :<span>#005</span></div>
              <div class="invoice-date-details">
                Invoice Date :<span> 07-10-2023</span>
              </div>
            </div>
          </div>
          <div class="two-invoice-details">
            <div class="company-booking-address company-five-address">
              <div class="company-add">
                <span>Customer Details :</span>
                <h4>John Williams</h4>
                <h5>GSTIN : ACWR000054321</h5>
                <div class="paid-status-add">
                  <span>Payment Status</span>
                  <h6>PAID</h6>
                </div>
              </div>
              <div class="invoice-date">
                <h4>Dreams</h4>
                <p>
                  <span>Address:</span> 15 Hodges Mews,<br />High Wycomb HP123JL,United
                  Kingdom
                </p>
                <p><span>Mobile :</span> +91 98765 43210</p>
              </div>
            </div>
            <div class="invoice-address-details invoice-five-group">
              <div class="invoice-to">
                <span>Billing Address :</span>
                <div class="inv-to-address">
                  Walter Roberson<br />
                  299 Star Trek Drive, Panama City, <br />
                  Florida, 32405, USA.<br />
                </div>
              </div>
              <div class="invoice-to">
                <span>Shipping Address :</span>
                <div class="inv-to-address">
                  Walter Roberson<br />
                  299 Star Trek Drive, Panama City, <br />
                  Florida, 32405, USA.<br />
                </div>
              </div>
            </div>
            <div class="invoice-table-details">
              <div class="table-responsive">
                <table>
                  <thead>
                    <tr class="ecommercetable">
                      <th class="table_width_1">#</th>
                      <th class="table_width_1">Item</th>
                      <th class="table_width_1">Quantity</th>
                      <th >Unit Price</th>
                      <th >Discount</th>
                      <th class="table_width_4 text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td class="table-description">
                        Accounting Software Maintainence
                      </td>
                      <td >3</td>
                      <td >
                        $500
                        <h6>after disc. $450.00</h6>
                      </td>
                      <td >10%</td>
                      <td class="text-end">$1350</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td class="table-description">Man Power Support</td>
                      <td >1</td>
                      <td >$100</td>
                      <td >0%</td>
                      <td class="text-end">$100</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td class="table-description">Transportation Fee</td>
                      <td >2</td>
                      <td >
                        $200
                        <h6>after disc. $450.00</h6>
                      </td>
                      <td >5%</td>
                      <td class="text-end">$380</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td class="table-description">Spars Replacement Charges</td>
                      <td >5</td>
                      <td >$500</td>
                      <td >0%</td>
                      <td class="text-end">$2500</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td class="table-description">Materials Handling</td>
                      <td >1</td>
                      <td >
                        $500
                        <h6>after disc. $450.00</h6>
                      </td>
                      <td >25%</td>
                      <td class="text-end">$375</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="invoice-table-footer border border-0 py-0">
              <div class="table-footer-left notes"></div>
              <div class="text-center table-footer-right">
                <table>
                  <tbody>
                    <tr class="table-data">
                      <td class="tax">Taxable Amount</td>
                      <td class="payment">$4705.00</td>
                    </tr>
                    <tr class="table-data">
                      <td class="tax">IGST 18.0%</td>
                      <td class="payment">$846.90</td>
                    </tr>
                    <tr class="table-data">
                      <td class="tax">Extra Discount (Promo - 5%)</td>
                      <td class="payment">-$235.25</td>
                    </tr>
                    <tr class="table-data">
                      <td class="tax">Round Off</td>
                      <td class="payment">-$.65</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="invoice-table-footer totalamount-footer pe-0">
              <div class="table-footer-left">
                <table class="totalamt-table total-amt-word">
                  <tbody>
                    <tr>
                      <td>
                        Total Amount (in words):
                        <span>Five thousand three hundred and sixteen dollars only.</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-footer-right">
                <table class="totalamt-table">
                  <tbody>
                    <tr>
                      <td>Amount Payable</td>
                      <td>$5316.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="bank-details">
            <div class="bank-qr-detail">
              <div class="account-info">
                <span class="bank-title">Bank Details</span>
                <div class="account-details">Bank : <span>YES Bank</span></div>
                <div class="account-details">
                  Account # :<span> 6677889944551 </span>
                </div>
                <div class="account-details">IFSC : <span>YESBBIN4567</span></div>
                <div class="account-details">BRANCH : <span>RS Puram</span></div>
              </div>
              <div class="qr-code ms-3">
                <img src="@/assets/img/qr-code.svg" alt="qr" />
              </div>
            </div>
            <div class="company-sign">
              <span>For Dreams</span>
              <img src="@/assets/img/signature.png" alt="signature-img" />
            </div>
          </div>
        </div>
        <div class="terms-condition">
          <span>Terms and Conditions:</span>
          <ol>
            <li>1. Goods Once sold cannot be taken back or exchanged</li>
            <li>
              2. We are not the manufactures, company will stand for warrenty as per
              their terms and conditions.
            </li>
          </ol>
        </div>
        <div class="thanks-msg">Thanks for your Business</div>
      </div>
      <div class="file-link">
        <a class="download_btn download-link">
          <i class="feather-download-cloud me-1"></i> <span>Download</span>
        </a>
        <a href="javascript:window.print()" class="print-link">
          <i class="feather-printer"></i> <span >Print</span>
        </a>
      </div>
    </div>
  </div>
</template>
