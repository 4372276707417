<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { t } from "@/utils/i18n";

const props = defineProps({
  saleData: {
    type: Object,
    required: true,
    default: () => ({
      income: {},
    }),
  },
});

const emit = defineEmits(["updateSaleData"]);

const updateIncome = (field, value) => {
  emit("updateSaleData", { section: "income", field, value });
};

const income = computed(() => props.saleData?.income || {});
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body container-form">
            <h5 class="card-title mb-5">
              {{ t("sales.details.income.income") }}
            </h5>
            <form @submit.prevent>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <a-form-item name="incomeType">
                    <label for="incomeType" class="form-label no-required">{{
                      t("sales.details.income.incomeType")
                    }}</label>
                    <a-select
                      v-model:value="income.incomeType"
                      size="large"
                      id="incomeType"
                      placeholder="Select"
                      @change="updateIncome('incomeType', income.incomeType)"
                    >
                      <a-select-option value="Employed">{{
                        t("sales.details.income.employed")
                      }}</a-select-option>
                      <a-select-option value="Self-employed">{{
                        t("sales.details.income.selfemployed")
                      }}</a-select-option>
                      <a-select-option value="Unemployed">{{
                        t("sales.details.income.unemployed")
                      }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="annualIncome" class="form-label no-required">{{
                    t("sales.details.income.annualIncome")
                  }}</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    id="annualIncome"
                    v-model="income.annualIncome"
                    @input="updateIncome('annualIncome', income.annualIncome)"
                    placeholder="Annual income"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label for="employerName" class="form-label no-required">{{
                    t("sales.details.income.employer")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="employerName"
                    v-model="income.employerName"
                    @input="updateIncome('employerName', income.employerName)"
                    placeholder="Name of employer"
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <label
                    for="employerPhoneNumber"
                    class="form-label no-required"
                    >{{ t("sales.details.income.phoneEmployer") }}</label
                  >
                  <input
                    type="tel"
                    class="form-control"
                    id="employerPhoneNumber"
                    v-model="income.employersPhone"
                    @input="
                      updateIncome('employersPhone', income.employersPhone)
                    "
                    placeholder="Enter telephone number"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
