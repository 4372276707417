<script setup>
import { ref, watch, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Notyf } from "notyf";
import { useSaleStore } from "@/store/sales";
import { capitalize } from "@/utils/";

// Importing component steps
import PersonalInfo from "./components/steps/PersonalInfo.vue";
import Income from "./components/steps/Income.vue";
import Members from "./components/steps/Members.vue";
import PlanInfo from "./components/steps/PlanInfo.vue";
import PaymentInfo from "./components/steps/PaymentInfo.vue";
import Attachments from "./components/steps/Attachments.vue";
import { t } from "@/utils/i18n";

// Reactive states
const current = ref(0);
const route = useRoute();
const router = useRouter();
const notyf = new Notyf();
const isFormValid = ref(false);
const saleStore = useSaleStore();
const saleId = ref("");
const saleUid = ref("");
const loading = ref(true);
const isValidToContinue = ref(false);

const steps = [
  {
    title: "Personal information",
    content: PersonalInfo,
    props: {
      personalInfo: computed(() => saleStore.saleData.personalInfo || {}),
    },
  },
  {
    title: "Income",
    content: Income,
    props: { income: computed(() => saleStore.saleData.income || {}) },
  },
  {
    title: "Members",
    content: Members,
    props: { members: computed(() => saleStore.saleData.members || {}) },
  },
  {
    title: "Plan information",
    content: PlanInfo,
    props: {
      planInformation: computed(() => saleStore.saleData.planInformation || {}),
    },
  },
  {
    title: "Payment information",
    content: PaymentInfo,
    props: { payment: computed(() => saleStore.saleData.payment || {}) },
  },
  {
    title: "Attachments",
    content: Attachments,
    props: { attachments: computed(() => saleStore.saleData.uid || {}) },
  },
];

// Generate items from steps for display
const items = computed(() =>
  steps.map((step) => ({ key: step.title, title: step.title }))
);

// Update the sale data in the store when the form is validated
const handleFormValidated = (status) => {
  isFormValid.value = status;
};

const handleUpdateSaleData = (data) => {
  const section = Object.keys(data)[0];
  saleStore.updateSection(section, data[section]);
};

const handleUpdateMembers = (members) => {
  saleStore.updateMembers(members);
};

const handleUpdateAMember = (data) => {
  const { index, updatedFields } = data;
  saleStore.updateMember(index, updatedFields);
};

const registerSale = async () => {
  try {
    const { saleData } = saleStore;
    await saleStore.registerSale(saleData);
    saleId.value = saleData.saleId;
    console.log("saleId.value: ", saleId.value);
    notyf.success("Sale saved");
    router.push(`/sales/new-sale/${saleStore.saleData.uid}`);
  } catch (error) {
    notyf.error("Error register sale");
  }
};

// Move to the next step
const next = () => {
  current.value++;
  const householdIncome =
    saleStore.saleData.personalInfo?.householdIncome || null;
  saleStore.setAnnualIncome(householdIncome);
  saleStore.updateStep(current.value);
  if (!saleId.value) {
    registerSale();
  }
  if (saleId.value) {
    saleStore.updatedSale(saleStore.saleData);
  }
  updateFormValidity();
};

const prev = () => {
  isFormValid.value = true;
  current.value--;
  saleStore.updateStep(current.value);
};

const updateFormValidity = () => {
  isFormValid.value = [1, 2, 3, 4, 5].includes(current.value);
};

const fetchSaleData = async (id) => {
  if (id) {
    try {
      loading.value = true;
      await saleStore.fetchSaleById(id);
      saleId.value = saleStore.saleData.saleId;
      saleUid.value = saleStore.saleData.uid;
      current.value = saleStore.saleData.step;
      isFormValid.value = true;
    } catch (error) {
      console.error("Error fetching sale:", error);
      notyf.error("Failed to fetch sale.");
    } finally {
      loading.value = false;
    }
  }
};

const finish = async () => {
  notyf.success("Processing complete!");
  router.push(`/sales/sale-detail?${saleUid.value}&confirmation`);
};

const handleValidationChange = (isValid) => {
  isValidToContinue.value = isValid;
};

const handleContinue = () => {
  if (isValidToContinue.value == true) {
    finish();
  } else {
    notyf.error("Cannot continue, required files are missing.");
  }
};

const getStatusClass = (status) => {
  const statusClasses = {
    DRAFT: "bg-warning-light text-warning",
    ASSIGNED: "bg-info-light text-info",
    CORRECTED: "bg-light text-secondary",
    SUBMITTED: "bg-success-light text-success",
    REJECTED: "bg-danger-light text-danger",
  };
  return `badge ${
    statusClasses[status] || "bg-secondary-light text-secondary"
  } fw-normal`;
};

onMounted(() => {
  if (route.params.uid) {
    fetchSaleData(route.params.uid);
  }
});

watch(
  () => route.params.uid,
  (newSaleId) => fetchSaleData(newSaleId)
);

watch(isFormValid, (newValue) => {
  console.log("isFormValid changed:", newValue);
});
</script>

<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <div class="content-page-header">
        <h5>
          {{ t("sales.newSale.btnNewSale") }} {{ saleId }}
          <a-spin
            v-if="loading && route.params.uid"
            :indicator="indicator"
          /><span
            v-if="saleId && saleStore.saleData.status"
            :class="getStatusClass(saleStore.saleData.status)"
          >
            {{ capitalize(saleStore.saleData.status || "") }}
          </span>
        </h5>
      </div>
      <!-- Sales Registration Form -->

      <div class="sales-registration">
        <a-steps :current="current" label-placement="vertical" :items="items" />
        <div class="step-content mt-5 g-2">
          <component
            :is="steps[current].content"
            @form-validated="handleFormValidated"
            :saleData="saleStore.saleData"
            @updateSaleData="handleUpdateSaleData"
            @updateMembers="handleUpdateMembers"
            @updateAMember="handleUpdateAMember"
            @validationChange="handleValidationChange"
            :key="current"
          />
        </div>
        <div class="container step-actions mt-2 d-flex justify-content-end p-0">
          <button
            v-if="current > 0"
            @click="prev"
            class="btn bg-cancel me-4 px-4"
          >
            {{ t("sales.details.buttons.back") }}
          </button>
          <button
            v-if="current < steps.length - 1"
            @click="next"
            :disabled="!isFormValid"
            class="btn btn-primary rounded-3 px-4"
          >
            {{ t("sales.details.buttons.next") }}
          </button>
          <button
            v-if="current === steps.length - 1"
            @click="handleContinue"
            :disabled="!isValidToContinue"
            class="btn btn-primary rounded-3 px-4"
          >
            {{ t("sales.details.buttons.continue") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
