<script setup>
import { Notyf } from "notyf";
import { ref, onMounted, defineEmits, watch } from "vue";
import { getUser } from "@/utils/";
import { useUserStore } from "@/store/user";
import { useSaleStore } from "@/store/sales";

const notyf = new Notyf();
const roleUser = getUser().role;
const userStore = useUserStore();
const carrierList = ref([]);
const processorsList = ref([]);
const sellersList = ref([]);
const selectedCarrier = ref("");
const selectedProcessor = ref("");
const selectedSeller = ref("");
const showInfoAlert = ref(false);
const saleStore = useSaleStore();

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  text: {
    type: String,
    default: "",
  },
  path: {
    type: String,
    default: "",
  },
  error: {
    type: String,
    default: "",
  },
});

const errorMessage = ref(props.error);

const emit = defineEmits(["filter-sales"]);

watch(
  () => props.error,
  (newValue) => {
    if (newValue) {
      errorMessage.value = newValue;
    } else {
      errorMessage.value = "";
    }
  }
);

watch([selectedCarrier, selectedProcessor, selectedSeller], () => {
  if (
    selectedCarrier.value ||
    selectedProcessor.value ||
    selectedSeller.value
  ) {
    showInfoAlert.value = false;
  }
});

const submitForm = () => {
  if (
    selectedCarrier.value ||
    selectedProcessor.value ||
    selectedSeller.value
  ) {
    showInfoAlert.value = false;
    emit("filter-sales", {
      carrier: selectedCarrier.value,
      processor: selectedProcessor.value,
      seller: selectedSeller.value,
    });
  } else {
    showInfoAlert.value = true;
  }
};

const resetSaleData = () => {
  saleStore.resetSaleData();
};

const fullName = (firstName, lastName) => `${firstName} ${lastName}`;

const loadUsers = async () => {
  try {
    const users = await userStore.fetchUsers();

    processorsList.value = users
      .filter((user) => user.role === "PROCESSOR")
      .map((user) => ({
        value: user.uid,
        label: fullName(user.firstName, user.lastName),
      }));

    sellersList.value = users
      .filter((user) => user.role === "SELLER")
      .map((user) => ({
        value: user.uid,
        label: fullName(user.firstName, user.lastName),
      }));
  } catch (error) {
    notyf.error("Error loading users");
  }
};

const loadCarriers = async () => {
  try {
    const [data] = await userStore.fetchCarrier();
    carrierList.value = data.list;
  } catch (error) {
    notyf.error("Error loading carriers");
  }
};

function resetFilter() {
  if (
    selectedCarrier.value ||
    selectedProcessor.value ||
    selectedSeller.value
  ) {
    selectedCarrier.value = "";
    selectedProcessor.value = "";
    selectedSeller.value = "";

    emit("filter-sales", {
      carrier: selectedCarrier.value,
      processor: selectedProcessor.value,
      seller: selectedSeller.value,
    });
  }
}

onMounted(() => {
  loadCarriers();
  loadUsers();
  const popupToggleElements = document.getElementsByClassName("popup-toggle");
  if (popupToggleElements.length > 0) {
    Array.from(popupToggleElements).forEach((el) => {
      el.addEventListener("click", () => {
        document
          .getElementsByClassName("toggle-sidebar")[0]
          .classList.add("open-filter");
      });
    });

    const sidebarCloseElements =
      document.getElementsByClassName("sidebar-closes");
    Array.from(sidebarCloseElements).forEach((el) => {
      el.addEventListener("click", () => {
        document
          .getElementsByClassName("toggle-sidebar")[0]
          .classList.remove("open-filter");
      });
    });
  }

  const viewallOneElements = document.getElementsByClassName("viewall-One");
  if (viewallOneElements.length > 0) {
    document.addEventListener("DOMContentLoaded", () => {
      Array.from(viewallOneElements).forEach((element) => {
        element.style.display = "none";
      });

      const viewallButton =
        document.getElementsByClassName("viewall-button-One")[0];
      viewallButton.addEventListener("click", () => {
        const buttonText = viewallButton.textContent;
        Array.from(viewallOneElements).forEach((element) => {
          element.style.display =
            element.style.display === "none" ? "block" : "none";
        });

        viewallButton.textContent =
          buttonText === "Close All" ? "View All" : "Close All";
      });
    });
  }
});
</script>

<template>
  <div class="page-header">
    <div class="content-page-header">
      <h5>{{ title }}</h5>
      <div class="list-btn">
        <ul class="filter-list">
          <li>
            <a
              class="btn btn-filters w-auto popup-toggle"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Filter"
              ><i class="feather feather-filter me-2 fs-5"></i>Filter
            </a>
          </li>

          <!-- <li>
            <a
              class="btn-filters"
              href="javascript:void(0);"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Settings"
              ><span><i class="feather feather-settings"></i></span>
            </a>
          </li> -->
          <li v-if="roleUser === 'SELLER'">

            <router-link class="btn btn-primary" :to="props.path" @click="resetSaleData">

              <i class="fa fa-plus-circle me-2" aria-hidden="true"></i>
              {{ text }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Add Asset -->
  <div class="toggle-sidebar">
    <div class="sidebar-layout-filter">
      <div class="sidebar-header">
        <h5>Filter</h5>
        <a href="javascript:;" class="sidebar-closes"
          ><i class="fa-regular fa-circle-xmark"></i
        ></a>
      </div>
      <div class="sidebar-body">
        <form action="javascript:;" autocomplete="off">
          <!-- Customer -->
          <div class="accordion d-none" id="accordionMain1">
            <div class="card-header-new" id="headingOne">
              <h6 class="filter-title">
                <a
                  href="javascript:void(0);"
                  class="w-100"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Customer
                  <span class="float-end"
                    ><i class="fa-solid fa-chevron-down"></i
                  ></span>
                </a>
              </h6>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample1"
            >
              <div class="card-body-chat">
                <div class="row">
                  <div class="col-md-12">
                    <div id="checkBoxes1">
                      <div class="form-custom">
                        <input
                          type="text"
                          class="form-control"
                          id="member_search1"
                          placeholder="Search here"
                        />
                        <span
                          ><img src="@/assets/img/icons/search.svg" alt="img"
                        /></span>
                      </div>
                      <div class="selectBox-cont">
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> John Smith
                        </label>
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> Johnny
                        </label>
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> Robert
                        </label>
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> Sharonda
                        </label>
                        <!-- View All -->
                        <div class="view-content">
                          <div class="viewall-One">
                            <label class="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span class="checkmark"></span> Pricilla
                            </label>
                            <label class="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span class="checkmark"></span> Randall
                            </label>
                          </div>
                          <div class="view-all">
                            <a
                              href="javascript:void(0);"
                              class="viewall-button-One"
                              ><span class="me-2">View All</span
                              ><span
                                ><i class="fa fa-circle-chevron-down"></i></span
                            ></a>
                          </div>
                        </div>
                        <!-- /View All -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Customer -->

          <!-- Product Code -->
          <div class="accordion d-none" id="accordionMain2">
            <div class="card-header-new" id="headingTwo">
              <h6 class="filter-title">
                <a
                  href="javascript:void(0);"
                  class="w-100 collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Credit Notes ID
                  <span class="float-end"
                    ><i class="fa-solid fa-chevron-down"></i
                  ></span>
                </a>
              </h6>
            </div>

            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample2"
            >
              <div class="card-body-chat">
                <div id="checkBoxes3">
                  <div class="selectBox-cont">
                    <label class="custom_check w-100">
                      <input type="checkbox" name="ref-no" />
                      <span class="checkmark"></span> 4905681
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="ref-no" />
                      <span class="checkmark"></span> 4905682
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="ref-no" />
                      <span class="checkmark"></span> 4905683
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="ref-no" />
                      <span class="checkmark"></span> 4905684
                    </label>
                    <!-- View All -->
                    <div class="view-content">
                      <div class="viewall-Two">
                        <label class="custom_check w-100">
                          <input type="checkbox" name="ref-no" />
                          <span class="checkmark"></span> 4905685
                        </label>
                        <label class="custom_check w-100">
                          <input type="checkbox" name="ref-no" />
                          <span class="checkmark"></span> 4905686
                        </label>
                      </div>
                      <div class="view-all">
                        <a href="javascript:void(0);" class="viewall-button-Two"
                          ><span class="me-2">View All</span
                          ><span><i class="fa fa-circle-chevron-down"></i></span
                        ></a>
                      </div>
                    </div>
                    <!-- /View All -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Product Code -->

          <!-- By Status -->
          <div class="accordion d-none" id="accordionMain3">
            <div class="card-header-new" id="headingThree">
              <h6 class="filter-title">
                <a
                  href="javascript:void(0);"
                  class="w-100 collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  By Status
                  <span class="float-end"
                    ><i class="fa-solid fa-chevron-down"></i
                  ></span>
                </a>
              </h6>
            </div>

            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample3"
            >
              <div class="card-body-chat">
                <div id="checkBoxes2">
                  <div class="selectBox-cont">
                    <label class="custom_check w-100">
                      <input type="checkbox" name="bystatus" />
                      <span class="checkmark"></span> Paid
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="bystatus" />
                      <span class="checkmark"></span> Pending
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="bystatus" />
                      <span class="checkmark"></span> Cancelled
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /By Status -->

          <!-- By Status -->
          <div class="accordion accordion-last d-none" id="accordionMain4">
            <div class="card-header-new" id="headingFour">
              <h6 class="filter-title">
                <a
                  href="javascript:void(0);"
                  class="w-100 collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  Payment Mode
                  <span class="float-end"
                    ><i class="fa-solid fa-chevron-down"></i
                  ></span>
                </a>
              </h6>
            </div>

            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample3"
            >
              <div class="card-body-chat">
                <div id="checkBoxes2">
                  <div class="selectBox-cont">
                    <label class="custom_check w-100">
                      <input type="checkbox" name="payment-mode" />
                      <span class="checkmark"></span> Cash
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="payment-mode" />
                      <span class="checkmark"></span> Cheque
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /By Status -->
          <!-- Select Filers -->
          <div class="select-filters">
            <label for="exampleFormControlInput1" class="form-label"
              >Carrier</label
            >
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="selectedCarrier"
            >
              <option disabled value="">Select</option>
              <option
                v-for="carrier in carrierList"
                :key="carrier.id"
                :value="carrier.id"
                class="text-capitalize"
              >
                {{ carrier.value }}
              </option>
            </select>
            <!-- Processors Select -->
            <div v-if="roleUser !== 'PROCESSOR'">
              <label for="processorSelect" class="form-label">Processors</label>
              <select
                id="processorSelect"
                class="form-select"
                v-model="selectedProcessor"
              >
                <option disabled value="">Select</option>
                <option
                  v-for="processor in processorsList"
                  :key="processor.value"
                  :value="processor.value"
                  class="text-capitalize"
                >
                  {{ processor.label }}
                </option>
              </select>
            </div>
            <!-- Sellers Select -->
            <div v-if="roleUser !== 'SELLER'">
              <label for="sellerSelect" class="form-label">Sellers</label>
              <select
                id="sellerSelect"
                class="form-select"
                v-model="selectedSeller"
              >
                <option disabled value="">Select</option>
                <option
                  v-for="seller in sellersList"
                  :key="seller.value"
                  :value="seller.value"
                  class="text-capitalize"
                >
                  {{ seller.label }}
                </option>
              </select>
            </div>
          </div>
          <!-- /Select Filers -->

          <div class="filter-buttons">
            <button
              type="submit"
              class="d-inline-flex align-items-center justify-content-center btn w-100 bg-cancel me-3"
              @click="resetFilter"
            >
              Undo
            </button>
            <button
              type="submit"
              @click="submitForm"
              class="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary"
            >
              Apply
            </button>
          </div>
          <div
            class="alert alert-primary alert-dismissible fade show mt-5"
            v-show="showInfoAlert"
            role="alert"
          >
            Please select at least one filter option to proceed.
          </div>
          <div
            class="alert alert-danger alert-dismissible fade show mt-5"
            v-show="errorMessage"
            role="alert"
          >
            <strong>Error!</strong> {{ errorMessage }}
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add Asset -->
</template>
