<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="chat-window">
            <div class="chat-cont-left">
              <div class="chat-header">
                <span>Chats</span>
                <a href="javascript:void(0)" class="chat-compose">
                  <span><i class="feather feather-plus-circle"></i></span>
                </a>
              </div>
              <form class="chat-search">
                <div class="input-group">
                  <div class="input-group-prefix">
                    <i class="fas fa-search"></i>
                  </div>
                  <input type="text" class="form-control" placeholder="Search" />
                </div>
              </form>
              <div class="chat-users-list">
                <div class="chat-scroll">
                  <a href="javascript:void(0);" class="chat-block d-flex">
                    <div class="media-img-wrap">
                      <div class="avatar avatar-away">
                        <img src="@/assets/img/profiles/avatar-03.jpg" alt="User Image"
                          class="avatar-img rounded-circle" />
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="user-name">Marie Canales</div>
                        <div class="user-last-chat">Hey, How are you?</div>
                      </div>
                      <div>
                        <div class="last-chat-time block">2 min</div>
                        <div class="badge badge-success">15</div>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0);" class="chat-block read-chat active d-flex">
                    <div class="media-img-wrap">
                      <div class="avatar avatar-online">
                        <img src="@/assets/img/profiles/avatar-04.jpg" alt="User Image"
                          class="avatar-img rounded-circle" />
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="user-name">Barbara Moore</div>
                        <div class="user-last-chat">I'll call you later</div>
                      </div>
                      <div>
                        <div class="last-chat-time block">8:01 PM</div>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0);" class="chat-block d-flex">
                    <div class="media-img-wrap">
                      <div class="avatar avatar-away">
                        <img src="@/assets/img/profiles/avatar-05.jpg" alt="User Image"
                          class="avatar-img rounded-circle" />
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="user-name">Greg Lynch</div>
                        <div class="user-last-chat">
                          Give me a full explanation about our project
                        </div>
                      </div>
                      <div>
                        <div class="last-chat-time block">7:30 PM</div>
                        <div class="badge badge-success">3</div>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0);" class="chat-block read-chat d-flex">
                    <div class="media-img-wrap">
                      <div class="avatar avatar-online">
                        <img src="@/assets/img/profiles/avatar-06.jpg" alt="User Image"
                          class="avatar-img rounded-circle" />
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="user-name">Karlene Chaidez</div>
                        <div class="user-last-chat">That's very good UI design</div>
                      </div>
                      <div>
                        <div class="last-chat-time block">6:59 PM</div>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0);" class="chat-block read-chat d-flex">
                    <div class="media-img-wrap">
                      <div class="avatar avatar-offline">
                        <img src="@/assets/img/profiles/avatar-07.jpg" alt="User Image"
                          class="avatar-img rounded-circle" />
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="user-name">John Blair</div>
                        <div class="user-last-chat">Yesterday i completed the task</div>
                      </div>
                      <div>
                        <div class="last-chat-time block">11:21 AM</div>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0);" class="chat-block read-chat d-flex">
                    <div class="media-img-wrap">
                      <div class="avatar avatar-online">
                        <img src="@/assets/img/profiles/avatar-08.jpg" alt="User Image"
                          class="avatar-img rounded-circle" />
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="user-name">Russell Copeland</div>
                        <div class="user-last-chat">
                          What is the major functionality?
                        </div>
                      </div>
                      <div>
                        <div class="last-chat-time block">10:05 AM</div>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0);" class="chat-block read-chat d-flex">
                    <div class="media-img-wrap">
                      <div class="avatar avatar-away">
                        <img src="@/assets/img/profiles/avatar-09.jpg" alt="User Image"
                          class="avatar-img rounded-circle" />
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="user-name">Leatha Bailey</div>
                        <div class="user-last-chat">
                          This has allowed me to showcase not only my technical skills
                        </div>
                      </div>
                      <div>
                        <div class="last-chat-time block">Yesterday</div>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0);" class="chat-block read-chat d-flex">
                    <div class="media-img-wrap">
                      <div class="avatar avatar-offline">
                        <img src="@/assets/img/profiles/avatar-10.jpg" alt="User Image"
                          class="avatar-img rounded-circle" />
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="user-name">Joseph Collins</div>
                        <div class="user-last-chat">
                          Let's talk briefly in the evening.
                        </div>
                      </div>
                      <div>
                        <div class="last-chat-time block">Sunday</div>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0);" class="chat-block read-chat d-flex">
                    <div class="media-img-wrap">
                      <div class="avatar avatar-online">
                        <img src="@/assets/img/profiles/avatar-11.jpg" alt="User Image"
                          class="avatar-img rounded-circle" />
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="user-name">Jennifer Floyd</div>
                        <div class="user-last-chat">
                          Do you have any collections? If so, what of?
                        </div>
                      </div>
                      <div>
                        <div class="last-chat-time block">Saturday</div>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0);" class="chat-block read-chat d-flex">
                    <div class="media-img-wrap">
                      <div class="avatar avatar-away">
                        <img src="@/assets/img/profiles/avatar-12.jpg" alt="User Image"
                          class="avatar-img rounded-circle" />
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="user-name">Alex Campbell</div>
                        <div class="user-last-chat">
                          Connect the two modules with in 1 day.
                        </div>
                      </div>
                      <div>
                        <div class="last-chat-time block">Friday</div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="chat-cont-right">
              <div class="chat-header">
                <a id="back_user_list" href="javascript:void(0)" class="back-user-list">
                  <i class="fa fa-chevron-left"></i>
                </a>
                <div class="chat-block d-flex">
                  <div class="media-img-wrap">
                    <div class="avatar avatar-online">
                      <img src="@/assets/img/profiles/avatar-02.jpg" alt="User Image" class="avatar-img rounded-circle" />
                    </div>
                  </div>
                  <div class="media-body">
                    <div class="user-name">Brian Johnson</div>
                    <div class="user-status">online</div>
                  </div>
                </div>
                <div class="chat-options">
                  <a href="javascript:void(0)">
                    <span><i class="feather feather-phone"></i></span>
                  </a>
                  <a href="javascript:void(0)">
                    <span><i class="feather feather-video"></i></span>
                  </a>
                  <a href="javascript:void(0)">
                    <span><i class="feather feather-more-vertical"></i></span>
                  </a>
                </div>
              </div>
              <div class="chat-body">
                <div class="chat-scroll">
                  <ul class="list-unstyled">
                    <li class="chat-block sent">
                      <div class="media-body">
                        <div class="msg-box">
                          <div>
                            <p>Hello. What can I do for you?</p>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>8:30 AM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="chat-block d-flex received">
                      <div class="avatar">
                        <img src="@/assets/img/profiles/avatar-02.jpg" alt="User Image"
                          class="avatar-img rounded-circle" />
                      </div>
                      <div class="media-body">
                        <div class="msg-box">
                          <div>
                            <p>I'm just looking around.</p>
                            <p>Will you tell me something about yourself?</p>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>8:35 AM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="msg-box">
                          <div>
                            <p>Are you there? That time!</p>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>8:40 AM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="msg-box">
                          <div>
                            <div class="chat-msg-attachments">
                              <div class="chat-attachment">
                                <img src="@/assets/img/img-01.jpg" alt="profile-img" />
                                <div class="chat-attach-caption">placeholder.jpg</div>
                                <a href="javascript:;" class="chat-attach-download">
                                  <i class="fas fa-download"></i>
                                </a>
                              </div>
                              <div class="chat-attachment">
                                <img src="@/assets/img/img-01.jpg" alt="profile-img" />
                                <div class="chat-attach-caption">placeholder.jpg</div>
                                <a href="javascript:;" class="chat-attach-download">
                                  <i class="fas fa-download"></i>
                                </a>
                              </div>
                            </div>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>8:41 AM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="chat-block sent">
                      <div class="media-body">
                        <div class="msg-box">
                          <div>
                            <p>Where?</p>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>8:42 AM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="msg-box">
                          <div>
                            <p>
                              OK, my name is Limingqiang. I like singing, playing
                              basketballand so on.
                            </p>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>8:42 AM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="msg-box">
                          <div>
                            <div class="chat-msg-attachments">
                              <div class="chat-attachment">
                                <img src="@/assets/img/img-01.jpg" alt="profile-img" />
                                <div class="chat-attach-caption">placeholder.jpg</div>
                                <a href="javascript:;" class="chat-attach-download">
                                  <i class="fas fa-download"></i>
                                </a>
                              </div>
                            </div>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>8:50 AM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="chat-block d-flex received">
                      <div class="avatar">
                        <img src="@/assets/img/profiles/avatar-02.jpg" alt="User Image"
                          class="avatar-img rounded-circle" />
                      </div>
                      <div class="media-body">
                        <div class="msg-box">
                          <div>
                            <p>You wait for notice.</p>
                            <p>Consectetuorem ipsum dolor sit?</p>
                            <p>Ok?</p>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>8:55 PM</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="chat-date">Today</li>
                    <li class="chat-block d-flex received">
                      <div class="avatar">
                        <img src="@/assets/img/profiles/avatar-02.jpg" alt="User Image"
                          class="avatar-img rounded-circle" />
                      </div>
                      <div class="media-body">
                        <div class="msg-box">
                          <div>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            </p>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>10:17 AM</span>
                                </div>
                              </li>
                              <li><a href="javascript:;">Edit</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="chat-block sent">
                      <div class="media-body">
                        <div class="msg-box">
                          <div>
                            <p>Lorem ipsum dollar sit</p>
                            <div class="chat-msg-actions dropdown">
                              <a href="javascript:;" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="feather feather-elipsis-v"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:;">Delete</a>
                              </div>
                            </div>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>10:19 AM</span>
                                </div>
                              </li>
                              <li><a href="javascript:;">Edit</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="chat-block d-flex received">
                      <div class="avatar">
                        <img src="@/assets/img/profiles/avatar-02.jpg" alt="User Image"
                          class="avatar-img rounded-circle" />
                      </div>
                      <div class="media-body">
                        <div class="msg-box msg-box-typing">
                          <div>
                            <div class="msg-typing">
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="chat-footer">
                <div class="input-group">
                  <div class="input-group-prefix">
                    <div class="btn-file btn">
                      <i class="fas fa-paperclip"></i>
                      <input type="file" />
                    </div>
                  </div>
                  <input type="text" class="input-msg-send form-control" placeholder="Type something" />
                  <div class="input-group-addon">
                    <button type="button" class="btn msg-send-btn">
                      <i class="fas fa-paper-plane"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    var chatAppTarget = document.getElementsByClassName("chat-window")[0];

    (function () {
      function getWindowWidth() {
        return (
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
        );
      }

      function removeChatSlideClass() {
        if (getWindowWidth() > 992) {
          chatAppTarget.classList.remove("chat-slide");
        }
      }

      function addChatSlideClass() {
        if (getWindowWidth() <= 992) {
          chatAppTarget.classList.add("chat-slide");
        }
      }

      function removeChatSlideOnClick() {
        chatAppTarget.classList.remove("chat-slide");
      }

      function handleChatUsersListClick(event) {
        if (getWindowWidth() <= 992) {
          addChatSlideClass();
        }
        event.preventDefault();
        return false;
      }

      function handleBackUserListClick(event) {
        if (getWindowWidth() <= 992) {
          removeChatSlideOnClick();
        }
        event.preventDefault();
        return false;
      }

      // Initial check
      removeChatSlideClass();

      // Event listeners
      var chatUsersList = document.getElementsByClassName("chat-users-list")[0];
      if (chatUsersList) {
        var chatBlockElements = chatUsersList.getElementsByClassName("chat-block");
        for (var i = 0; i < chatBlockElements.length; i++) {
          chatBlockElements[i].addEventListener("click", handleChatUsersListClick);
        }
      }

      var backUserList = document.getElementById("back_user_list");
      if (backUserList) {
        backUserList.addEventListener("click", handleBackUserListClick);
      }

      // Window resize event
      window.addEventListener("resize", function () {
        removeChatSlideClass();
      });
    })();
  },
};
</script>
