<template>
  <form ref="form" class="needs-validation" novalidate autocomplete="off">
    <slot />
  </form>
</template>

<script setup>
import { ref, onMounted } from "vue";

const emit = defineEmits(["formValidated"]);
const form = ref(null);
const isFormValid = ref(false);

const validateField = (element) => {
  if (!element.checkValidity()) {
    element.classList.add("is-invalid");
  } else {
    element.classList.remove("is-invalid");
  }
};

const handleBlur = (event) => {
  validateField(event.target);
  checkFormValidity();
};

const handleInput = (event) => {
  validateField(event.target);
  checkFormValidity();
};

const checkFormValidity = () => {
  const formElement = form.value;
  isFormValid.value = formElement.checkValidity();
  emit("formValidated", isFormValid.value);
};

onMounted(() => {
  const formElement = form.value;
  if (formElement) {
    const elements = formElement.querySelectorAll("input, select");

    elements.forEach((element) => {
      element.addEventListener("blur", handleBlur);
      element.addEventListener("input", handleInput);
    });
  }
});
</script>
