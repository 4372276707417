<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <div class="page-header">
        <div class="content-page-header">
          <h5>Blank Page</h5>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">Contents here</div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
<script>
export default {
  setup() {
    return {
      title: "Blank Page",
    };
  },
};
</script>
